<script setup lang="ts">
import { FaceResult, Result } from '@vladmandic/human';
import { PropType, ref } from 'vue';
import { cItem } from '../../domain/item';
import BoundingBox from './BoundingBox.vue';
import { ItemObjectData, ItemObjectType, PeopleSearchMenuItem, PersonData, PersonFaceData, PersonInterface, ItemPersonInterface } from 'rundown-common';
import { useHumanDetector } from '../../utility/useHumanDetector';
import { watch } from 'vue';
import { useImageScaling } from '../../utility/useImageScaling';
import { nextTick } from 'vue';
import { usePeopleStore } from '../../stores/people';
import { VCombobox } from 'vuetify/lib/components/index.mjs';
import { useDynamicToolbarSettings } from '../../stores/DynamicToolbarSettings';
import { usePersonSearch } from '../../utility/UsePersonSearch';
import { cItemPersonObject } from '../../domain/ItemPersonObject';
import { cPerson } from '../../domain/Person';
import { ImageScale } from 'rundown-common';

interface BoundingBoxInterface extends ImageScale {
    object: ItemPersonInterface;
}

const props = defineProps({
    autoDetect: {
        type: Boolean,
        default: false
    },
    autoTag: {
        type: Boolean,
        default: false
    },
    item: {
        type: cItem,
        required: true
    },
    imageElement: {
        type: HTMLElement,
        required: true
    },
    selectedPerson: {
        type: Object as PropType<ItemPersonInterface | null>,
        required: false,
    }
});

const emit = defineEmits<{
    (e: 'box-selected', pPersonObject: ItemPersonInterface | null): void;
    (e: 'box-dblclick', pEvent: MouseEvent, pPersonObject: ItemPersonInterface): void;
    (e: 'box-update', pPersonObject: ItemPersonInterface): void;
    (e: 'box-save-input', pValue: ItemPersonInterface | PersonInterface | string | null): void;
}>();

const { personIsMenuItem, personGetName, peopleSearchInput, peopleAvailable, peopleSearchFace, peopleFindHighMatch } = usePersonSearch();

const result = ref<Result|null>(null);
const boxes = ref<Array<BoundingBoxInterface>>([]);

const boxSelected = ref<BoundingBoxInterface | null>(null);
const personSelected = ref<ItemPersonInterface | PeopleSearchMenuItem | null>(null);

const imgElement = ref<HTMLImageElement | null>(null);
const imageLoaded = ref(false);

const inputBox = ref<VCombobox | null>(null)
const inputStyle = ref('');
const inputVisible = ref(false);
const inputPosition = ref<HTMLElement | null>(null);

const imageTooltip = ref(false);
const imageToolTipV = ref('');
const tooltipPosition = ref<any>({});

const human = useHumanDetector();
const imageScaler = useImageScaling(imgElement);
const people = usePeopleStore();
const dynamicToolbarSettings = useDynamicToolbarSettings();

/**
 * Detect all faces and box them
 */
const detectFaces = async () => {
    if (!imgElement.value) return;
    result.value = await human.detectFaces(imgElement.value);
    clearAll();

    await updateFaces();
};

/**
 * Link face matches to existing objects
 */
const updateFaces = async () => {
    if (!result.value || !result.value.face) return;
    boxes.value = [];

    const existing: Array<number> = [];
    let unprocessedFaces: Array<FaceResult> = [];
    let existingPeople: Array<ItemPersonInterface> = [...props.item.peopleSaved]; // Copy of existing people

    for (const face of result.value.face) {
        let matchIndex = existingPeople.findIndex(p =>
            p.isPositionInsideLeeway(face.box[0], face.box[1], face.box[2], face.box[3]));

        if (matchIndex !== -1) {
            const existingPersonObject = existingPeople[matchIndex];

            existingPersonObject.newFace = face;

            addObject(existingPersonObject);
            existing.push(existingPersonObject.getPersonId());

            existingPeople.splice(matchIndex, 1); // Remove matched person from existingPeople
        } else {
            // Collect unprocessed faces
            unprocessedFaces.push(face as FaceResult);
        }
    }

    // Now add any tagged which dont have a face match
    existingPeople = existingPeople.filter(person => {
        if (person.object.height && person.object.width) {
            addObject(person);
            return false;
        }
        return true;
    });

    // Finally add any remaining faces
    unprocessedFaces.forEach(face => {
        const object = addFace(face);

        if (props.autoTag && object && object.newFace) {
            // FaceMatch|null
            const match = peopleFindHighMatch(object.newFace, existing);
            if (match != null) {
                // We found a match, process it
                console.log(match.count, match.similarity);
                console.log(match.person.getCurrentName());
            }
        }
    });

    updateBoxes();
}

/**
 * Add an existing object
 */
function addObject(pObject: ItemPersonInterface, pUpdateBoxes: boolean = false) {

    boxes.value.push({ object: pObject } as BoundingBoxInterface);
    if (pUpdateBoxes)
        updateBoxes();
}

/**
 * Create an object from a face result and add it as a box
 */
function addFace(face: FaceResult): ItemPersonInterface | null {
    if (!imgElement.value) return null;

    const box = new cItemPersonObject({
        id: 0,
        type: ItemObjectType.Person,
        object: null,
        face: null,
        x: face.box[0],
        y: face.box[1],
        width: face.box[2],
        height: face.box[3]
    } as ItemObjectData, props.item);

    box.newFace = face;

    boxes.value.push({ object: box } as BoundingBoxInterface);
    return box;
}

/**
 * Clear boxes and selections
 */
function clearAll() {
    clearSelection();
    boxes.value = [];
    updateBoxes();
}

function clearSelection() {
    boxSelected.value = null;
    inputVisible.value = false;
    personSelected.value = null;
}

function boxSelect(pObject: ItemPersonInterface | null) {
    clearSelection();

    if (pObject === null) {
        boxSelected.value = null;
        return;
    }

    const foundBox = boxes.value.find(box => box.object === pObject);
    if (foundBox) {
        boxSelected.value = foundBox;
    }
}

/**
 * Update all boxes based on image scale
 */
function updateBoxes() {
    if (!imgElement.value) return;

    boxes.value.forEach((box: BoundingBoxInterface) => {
        Object.assign(box, imageScaler.scaleFromRealImage(box.object.getBoundingBox()));
    });

    inputUpdateStyle();
}

/**
 * Take an X/Y based on the displayed image, and calculate the offsets in the actual image
 */
function createObject(scaledX: number, scaledY: number, pSize: number): ImageScale | null {
    if (!imgElement.value) return null;

    const scale = imageScaler.calculateScale();
    const { naturalWidth, naturalHeight } = imgElement.value;
    const { clientWidth: canvasWidth, clientHeight: canvasHeight } = imgElement.value;

    let { x, y } = imageScaler.scaleToRealImage(scaledX, scaledY);

    // Check if the click is outside the image area
    const imageX = (canvasWidth - naturalWidth * scale) / 2;
    const imageY = 0;
    const imageRight = imageX + naturalWidth * scale;
    let imageBottom = imageY + naturalHeight * scale;

    if (x < 0 || y < 0 || scaledX < imageX || scaledX > imageRight || scaledY > imageBottom) {
        // Click is outside the image area
        return null;
    }

    x = Math.round(x - (pSize / 2));
    y = Math.round(y - (pSize / 2));

    const box: ImageScale = {
        x: Math.round(x),
        y: Math.round(y),
        width: Math.round(pSize),
        height: Math.round(pSize)
    };

    return box;
}

async function toggleInputVisibility() {
    // Make the input visible
    inputVisible.value = true;
    if (inputBox.value) {
        inputBox.value.menu = false;
    }
    // Wait for the next DOM update to ensure inputBox.value is set
    await nextTick();

    // Check if inputBox is now available
    if (!inputBox.value) {
        return;
    }

    // reset the search input, then show menu and input box
    peopleSearchInput('');
    inputBox.value.menu = true;
    inputBox.value.focus();
}

/**
 * Selects a box by its index and emits an event with the selected box.
 * If the index is out of bounds, emits null.
 */
function boxClicked(pBox: BoundingBoxInterface): void {
    const wasVisible = inputVisible.value;
    if (pBox.object.getId() !== 0 && boxSelected.value?.object.getId() == pBox.object.getId())
        return;

    clearSelection();

    boxSelected.value = pBox;
    emit('box-selected', pBox.object);
    if (wasVisible) {
        inputUpdateStyle();
        toggleInputVisibility();
    }
}

/**
 * Handle a double click on a bounding box
 */
function boxDblClick(pEvent: MouseEvent, pBox: BoundingBoxInterface) {

    boxSelected.value = pBox;

    peopleSearchFace(pBox.object.getFace());

    const targetElement = pEvent.target as HTMLElement;
    inputPosition.value = targetElement.parentElement;
    
    inputUpdateStyle();
    toggleInputVisibility();
    emit('box-selected', pBox.object);
}

/**
 * Update the position or size of a box
 */
function updateDimension(box: BoundingBoxInterface, dimension: 'x' | 'y' | 'width' | 'height', newValue: number) {
    if (box.object.getFace())
        return;

    const changeInPixels = (newValue - box[dimension]);
    box.object.object[dimension] = Math.round(box.object.object[dimension] + changeInPixels);
    updateBoxes();
}

/**
 * Box has finished being dragged
 */
function boxDragEnd(box: BoundingBoxInterface) {
    emit('box-update', box.object);
}

/**
 * Update the position of the input box
 */
const inputUpdateStyle = () => {
    const scale = imageScaler.calculateScale();
    if (!inputPosition.value || boxSelected.value == null)
        return;

    const box = imageScaler.scaleFromRealImage(boxSelected.value.object.getBoundingBox())
    const parentRect = inputPosition.value.getBoundingClientRect();
    const parentRightEdge = parentRect.width;
    const autocompleteWidth = (parentRect.width/1.5) + (box.width * scale);

    const top = box.y + box.height;
    let left = box.x + (box.width / 2) - (autocompleteWidth / 2);

    // Check if the right edge of the autocomplete box exceeds the parent's right edge
    if (left + autocompleteWidth > parentRightEdge) {
        // Adjust left to ensure the box stays within the parent element
        left = parentRightEdge - autocompleteWidth;
    }

    // Ensure left is not negative
    left = Math.max(left, 0);

    inputStyle.value = `top: ${top}px; left: ${left}px; 
                        width: ${autocompleteWidth}px;`;
}

/**
 * Trigger a save of the input box
 */
function inputSave() {
    // isMenuItem
    if (personIsMenuItem(personSelected.value)) {
        emit('box-save-input', personSelected.value.object as cPerson)
    } else if (personSelected.value instanceof cItemPersonObject) {
        emit('box-save-input', personSelected.value)
    } else if (personSelected.value instanceof cPerson) {
        emit('box-save-input', personSelected.value)
    } else if (typeof personSelected.value === 'string') {
        emit('box-save-input', personSelected.value)
    } else {
        emit('box-save-input', personSelected.value)
    }

    if (inputBox.value)
        inputBox.value.menu = false;
    inputVisible.value = false;
}

/**
 * Listen for the image to be displayed
 */
const setupImageLoadListeners = (img: HTMLImageElement) => {
    const onLoad = () => {
        imageLoaded.value = true;
    };

    const onError = () => {
        console.error('Image failed to load.');
        img.removeEventListener('load', onLoad); // Remove onLoad listener if error occurs
        imageLoaded.value = false;
    };

    img.addEventListener('load', onLoad, { once: true });
    img.addEventListener('error', onError, { once: true });
};

/**
 * Wait for the image tag to become available
 */
watch(() => imgElement.value, (img) => {
    if (img) {
        if (img.complete && img.naturalHeight !== 0) {
            imageLoaded.value = true;
        } else {
            setupImageLoadListeners(img);
        }
    }
});

/**
 * Wait for the image to load before doing detection
 */
watch(imageLoaded, (isLoaded) => {
    if (isLoaded && props.autoDetect && !people.isLoading) {
        detectFaces();
    }
});

// person is selected
watch(() => props.selectedPerson, (newVal) => {
    personSelected.value = newVal ?? null;
});

// people data loading
watch(() => people.isLoading, (newState) => {
    if (!newState && props.autoDetect) {
        detectFaces();
    }
});

// auto detect flag change
watch(() => props.autoDetect, (pNewState) => {
    if (pNewState && !people.isLoading) {
        detectFaces();
    }
});

// minimum confidence level changed
watch(() => dynamicToolbarSettings.getFacialRecognitionConfidence, () => {
    if (props.autoDetect && !people.isLoading) {
        detectFaces();
    }
});

// people saved change
watch(() => props.item.peopleSaved, () => {
    if (props.autoDetect && !people.isLoading) {
        updateFaces();
    }
});

const observer = new MutationObserver(() => {
    const img = props.imageElement.querySelector('img');
    if (img) {
        imgElement.value = img;
        observer.disconnect();
    }
});

observer.observe(props.imageElement, { childList: true, subtree: true });

defineExpose({ updateBoxes, addObject, createObject, clearAll, clearSelection, boxSelect });
</script>

<template>
    <template v-for="box in boxes">
        <BoundingBox obj-class="bounding-box" obj-class-selected="bounding-box-selected" v-bind="box" :selected="boxSelected == box" @click.stop="boxClicked(box)"
            @dblclick.stop="boxDblClick($event, box)" @update="boxDragEnd(box)"
            @update:left="newValue => updateDimension(box, 'x', newValue)"
            @update:top="newValue => updateDimension(box, 'y', newValue)"
            @update:width="newValue => updateDimension(box, 'width', newValue)"
            @update:height="newValue => updateDimension(box, 'height', newValue)" />
    </template>

    <v-tooltip v-model="imageTooltip" :style="tooltipPosition">
        {{ imageToolTipV }}
    </v-tooltip>

    <v-combobox v-if="inputVisible" ref="inputBox" class="input" v-model="personSelected" :items="peopleAvailable"
        @click.stop @dblclick.stop :item-title="personGetName" @update:search="peopleSearchInput" density="compact" hide-no-data
        hide-details label="Type a name" :style="inputStyle" return-object>

        <template v-slot:item="{ item, props }">
            <v-list-item v-if="item.value?.isHeadline == true" class="input-headline" title="">
                <v-list-item-title>{{ item.value.text }}</v-list-item-title>
            </v-list-item>
            <v-list-item v-else v-bind="props" title="">
                <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
        </template>
        <template v-slot:append>
            <div class="checkmark" @click.stop="inputSave">
                <v-icon color="green-darken-4">mdi-check</v-icon>
            </div>
        </template>
    </v-combobox>
</template>

<style scoped>
.bounding-box {
    z-index: 1;
    position: absolute;
    border: 2px solid rgba(50, 150, 250, 0.8);
    background-color: rgba(50, 150, 250, 0.2);
    box-shadow: 3px 3px 5px rgba(50, 50, 50, 0.5);
    border-radius: 10px;
}

.bounding-box-selected {
    border: 3px solid rgba(250, 80, 80, 0.8);
    /* Changed to a thicker, red border */
    background-color: rgba(250, 80, 80, 0.2);
    /* Changed to a light red background */
    box-shadow: 0px 0px 15px rgba(250, 80, 80, 0.5);
    /* Added a glowing red shadow */
    transform: scale(1.05);
    /* Slightly enlarges the selected element */
}

.br {
    right: -5px;
    bottom: -5px;
    cursor: nwse-resize;
}

.input {
    z-index: 10;
    position: absolute;
    border: 2px solid rgba(50, 150, 250, 0.8);
    background-color: rgba(50, 150, 250, 0.9);
    box-shadow: 3px 3px 5px rgba(50, 50, 50, 0.5);
    border-radius: 10px;
}

.input-headline {
    z-index: 10;
    font-weight: bold;
    color: grey;
}

.checkmark {
    z-index: 10;
    color: #ffffff;


    background-color: #007bff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    padding: 5px;
    transition: transform 0.3s ease;
}

.checkmark:hover {
    transform: scale(1.1);
}</style>
