import axios from 'axios';
import { defineStore } from 'pinia';
import { cItem } from '../domain/item';
import { ItemPageManager } from '../utility/ItemPageManager';
import { computed, ref } from 'vue';
import { useMessageStore } from './messages';
import { useItemNavigation } from '../utility/useItemNavigation';
import { ItemData } from 'rundown-common';

export const useItemMissingStore = defineStore('itemsMissing', () => {
    const pages = new ItemPageManager(true);
    const navigation = useItemNavigation(pages);

    // Get a range of items
    const getRange = async (startIndex: number, count: number) => {
        return await pages.getRange(startIndex, count);
    };

    // Get the total number of records
    const getTotalRecords = computed(() => pages.getTotalRecords());

    // Check if the store is currently loading data
    const isLoading = computed(() => pages.isLoading());
    const reloadCounter = computed(() => pages.getReloadCounter());

    
    async function load() {
        await pages.load();
        if(!navigation.selectedItem.value.isValid()) {
            navigation.selectNextItem();
        }
    }

    /**
     * Delete an item
     */
    async function remove(pItemID: number) {
        const messages = useMessageStore();
        try {
            const data = await axios.delete('/v1/item/' + pItemID.toString());
            
            if(data.status == 204) {
                pages.removeItem(pItemID);
            }

            return true;
        }
        catch (error) {
            messages.handleAxiosError(error);
        }
        return false;
    }

    async function update(pItem: ItemData, pDeleted: boolean, pContext: object) {
        if (pDeleted) {
            pages.removeItem(pItem.id);

            // Check if the deleted item is the selected item
            if (navigation.getSelectedItem.value.getId() === navigation.selectedItemIndex.value) {
                // Move to the next item, or clear selection if it was the last item
                if (navigation.selectedItemIndex.value < getTotalRecords.value - 1) {
                    navigation.selectedItemIndex.value++;
                } else {
                    navigation.clearSelectedItem();
                }
            }
        } else {
            const item = pages.getItem(pItem.id);
            item?.refresh(pItem, pContext);
        }
    }

    return {
        reloadCounter,
        getRange,
        getTotalRecords,
        isLoading,
        load,
        
        update,
        remove,

        getSelectedItem: navigation.getSelectedItem,
        getSelectedItemIndex: navigation.getSelectedItemIndex,
        selectItem: navigation.directlySelectItem,
        selectNextItem: navigation.selectNextItem,
        selectPreviousItem: navigation.selectPreviousItem,
        selectedJumpTo: navigation.selectItemAtIndex,
        selectedJumpBy: navigation.moveSelectionByOffset,
        clearSelectedItem: navigation.clearSelectedItem
    };
});