import { defineStore } from 'pinia';
import axios from 'axios';
import { useMessageStore } from './messages';
import { PersonData, PersonNameData, PersonFaceData, tPeopleNameArray, tPeopleFaceArray, ActionMessageInterface } from 'rundown-common';
import { cPerson } from '../domain/Person';
import { Ref, computed, ref } from 'vue';
import { PublicationContext } from 'centrifuge';

export const usePeopleStore = defineStore('people', () => {
    const people: Ref<Array<cPerson>> = ref([]);
    const peopleNames: Ref<Array<PersonNameData>> = ref([]);
    const peopleFaces: Ref<Map<number, PersonFaceData>> = ref(new Map<number, PersonFaceData>());
    const loading = ref(false);

    const get = computed(() => people.value);
    const getNames = computed(() => peopleNames.value);
    const getFaces = computed(() => peopleFaces.value);
    const isLoading = computed(() => loading.value);

    // Actions
    const load = async () => {
        const messages = useMessageStore();
        loading.value = true;
        try {
            const [peopleData, names, faces] = await Promise.all([
                loadPeople(),
                loadNames(),
                loadFaces()
            ]);

            people.value = peopleData;
            peopleNames.value = names;
            peopleFaces.value.clear();
            faces.reduce((acc, face: PersonFaceData) => {
                if (face.embedding_data && typeof face.embedding_data === 'string') {
                    try {
                        face.embedding_data = JSON.parse(face.embedding_data);
                    } catch (e) {
                        console.error('Error parsing embedding_data:', e);
                    }
                }
                peopleFaces.value.set(face.id, face);
                return acc;
            }, {});

        } catch (error) {
            messages.handleAxiosError(error);
        } finally {
            loading.value = false;
        }
    };

    const loadNames = async () => {
        const response = await axios.get('/v1/person/names');
        return response.data;
    };

    const loadFaces = async () => {
        const response = await axios.get('/v1/person/faces');
        return response.data;
    };

    const loadPeople = async () => {
        const response = await axios.get('/v1/person');
        return response.data.map((pPersonData:PersonData) => new cPerson(pPersonData));
    };

    /**
     * Handle a websocket message for a collection
     */
    const websocketMessage = async(pData: PublicationContext) => {
        const messages = useMessageStore();
        var parsed = pData.data as ActionMessageInterface;
        
        // Update the plugin
        if (parsed.person !== null) {
            const person = people.value.find((person: cPerson) => person.getId() === parsed.person?.id);

            if(person) {
                person.loadFrom(parsed.person);
                return;
            }
            
            let per = new cPerson(parsed.person as PersonData);
            people.value.push(per);
        }


        messages.processAction(parsed);
    }

    const createPerson = async (pPersonName: string) => {
        const response = await axios.post('/v1/person', { 'names': [pPersonName] });
        const newPerson = new cPerson(response.data);

        people.value.push(newPerson);
        peopleNames.value.push(response.data.names[0]);

        return newPerson;
    };

    const updatePerson = async (pPerson: PersonData) => {
        await axios.put(`/v1/person/${pPerson.id}`, pPerson);
    };

    const createName = async (pPerson: cPerson, pName: PersonNameData) => {
        const path = `/v1/person/${pPerson.getId()}/names`;
        const response = await axios.post(path, {
            'name': pName.name,
            'start': pName.active_from,
            'finish': pName.active_to
        });

        pPerson.person.names.push(response.data);
        peopleNames.value.push(response.data);

        return response.data;
    };

    const updateName = async (pPerson: cPerson, pName: PersonNameData) => {
        const path = `/v1/person/${pPerson.getId()}/names/${pName.id}`;
        await axios.put(path, {
            'name': pName.name,
            'start': pName.active_from ?? '',
            'finish': pName.active_to ?? ''
        });

        peopleNames.value = peopleNames.value.map((element: PersonNameData)=> {
            if (element.id === pName.id) {
                return pName;
            }
            return element;
        });
    };

    const deleteName = async (pPerson: cPerson, pName: PersonNameData) => {
        const path = `/v1/person/${pPerson.getId()}/names/${pName.id}`;
        await axios.delete(path);

        peopleNames.value = peopleNames.value.filter(e => e.id !== pName.id);
        pPerson.person.names = pPerson.person.names.filter(e => e.id !== pName.id);
    };

    const createFace = async (pPerson: cPerson, pFaceData: PersonFaceData) => {
        const path = `/v1/person/${pPerson.getId()}/faces`;
        const response = await axios.post(path, {
            'object': pFaceData.object,
            'embedding_data': pFaceData.embedding_data
        });

        if (response.data.embedding_data && typeof response.data.embedding_data === 'string') {
            try {
                response.data.embedding_data = JSON.parse(response.data.embedding_data);
            } catch (e) {
                console.error('Error parsing embedding_data:', e);
            }
        }

        peopleFaces.value.set(response.data.id, response.data);
    };

    const updateFace = async (pPerson: cPerson, pFace: PersonFaceData) => {
        const path = `/v1/person/${pPerson.getId()}/faces/${pFace.id}`;
        await axios.put(path, {
            'embedding_data': pFace.embedding_data
        });

        peopleFaces.value.set(pFace.id, pFace);
    };

    const deleteFace = async (pPerson: cPerson, pFace: PersonFaceData) => {
        const path = `/v1/person/${pPerson.getId()}/faces/${pFace.id}`;
        await axios.delete(path);

        peopleFaces.value.delete(pFace.id);
    };

    const getNameObjForDate = (pPerson: PersonData, pDate = new Date()) : PersonNameData => {
        if (!pPerson || !pPerson.names || pPerson.names.length === 0) {
            return { 'id': 0, 'name': '' } as PersonNameData;
        }

        let found = pPerson.names[pPerson.names.length - 1];

        pPerson.names.forEach(name => {
            const start = new Date(name.active_from);
            const end = new Date(name.active_to);

            if (pDate > start && (pDate <= end || !name.active_to)) {
                found = name;
            }
        });

        return found;
    };

    const getNameForDate = (pPerson: PersonData, pDate = new Date()) => {
        return getNameObjForDate(pPerson, pDate).name;
    };

    const getPersonByName = (pName: string) => {
        const namelower = pName.toLowerCase();
        const name = peopleNames.value.find(entry => entry.name.toLowerCase() === namelower);
        return name ?? pName;
    };

    const getPersonById = (pId: number): cPerson => {
        const person = people.value.find((person: cPerson) => person.getId() === pId);

        if(person === undefined) {
            throw new Error('Person not found');
        }
        return person;
    };

    const getPersonByPersonName = (pPersonName: PersonNameData) => {
        return people.value.find((person: cPerson) => person.getId() === pPersonName.person);
    };

    const getPersonFace = (pFaceId: number) => {
        return peopleFaces.value.get(pFaceId);
    };

    const getPersonFaces = (pPerson: number) => {
        let faces = [];
        for (let [id, faceData] of peopleFaces.value.entries()) {
            if (faceData.person === pPerson) {
                faces.push(faceData);
            }
        }
        return faces;
    };
    
    const getPersonFacesWithout = (pSkipIds: Array<number>) => {
        let faces = [];
        for (let [id, faceData] of peopleFaces.value.entries()) {
            if (!pSkipIds.includes(faceData.person)) {
                faces.push(faceData);
            }
        }
        return faces;
    };
    
    const removeUnused = async () => {
        const messages = useMessageStore();
        try {
            await axios.delete('/v1/person/unused');
        } catch (error) {
            messages.add('Failed to remove unused people');
        }
    };

    const exportData = async () => {
        const messages = useMessageStore();
        try {
            const response = await axios.get('/v1/export/people');
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(new Blob([response.data]));
            link.download = 'people.json';
            link.click();
            window.URL.revokeObjectURL(link.href);
        } catch (error) {
            messages.add('Failed to export people');
        }
    };

    return {
        // State
        people, peopleNames, peopleFaces, loading,

        // Getters
        get, getNames, getFaces, isLoading,

        // Actions
        load, loadNames, loadFaces, loadPeople, websocketMessage,
        createPerson, updatePerson, createName, updateName, deleteName,
        createFace, updateFace, deleteFace, getNameObjForDate, getNameForDate,
        getPersonByName, getPersonById, getPersonByPersonName, getPersonFace,
        getPersonFaces, removeUnused, exportData,

        getPersonFacesWithout
    };
});
