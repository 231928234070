import { defineStore } from 'pinia'
import axios from "axios"
import { ActionMessageInterface, PluginData } from 'rundown-common';
import { ref } from 'vue';
import { useAuthStore } from './auth';
import { PublicationContext } from 'centrifuge';
import { useMessageStore } from './messages';
import { Plugin } from '../domain/Plugin';

export const usePluginStore = defineStore('plugins', () => {
    const available = ref<PluginData[]>([]);
    const active = ref(new Map<string, Plugin>());
    const options = ref(new Map<string, any>());

    async function load() {
        await loadList();
        available.value.forEach(async plugin => {
            if (plugin.active) {
                if (useAuthStore().isAuthed) {
                    await optionsLoad(plugin);
                }
                startPlugin(plugin);
            }
        });
    }

    async function loadList() {
        const response = await axios.get('/v1/plugin/list');
        available.value = response.data;
    }

    /**
     * Handle a websocket message for a collection
     */
    const websocketMessage = async (pData: PublicationContext) => {
        const messages = useMessageStore();
        var parsed = pData.data as ActionMessageInterface;

        // Update the collection
        if (parsed.plugin !== null) {
            update(parsed.plugin);
        }

        messages.processAction(parsed);
    }


    async function optionsLoad(pPlugin: PluginData) {
        const response = await axios.get(`/v1/plugin/${pPlugin.id}/options`);
        options.value.set(pPlugin.id, response.data);
    }

    async function optionsSave(pPlugin: PluginData, pData: object) {
        const response = await axios.put(`/v1/plugin/${pPlugin.id}/options`, pData);
        if (response.status != 200)
            return;

        options.value.set(pPlugin.id, pData);
    }

    function getMatchingComponents(pElementName: string, pName: string): Map<string, [Plugin, string][]> {
        let matchingComponents = new Map<string, [Plugin, string][]>();
    
        active.value.forEach((plugin: any) => {
            if (plugin.hasComponent(pElementName, pName)) {
                const componentContent = plugin.getComponent(pElementName, pName);
                if (componentContent) {
                    // Check if the key already exists, if not initialise with an empty array
                    if (!matchingComponents.has(pElementName)) {
                        matchingComponents.set(pElementName, []);
                    }
                    // Get the current array from the Map
                    const existingArray = matchingComponents.get(pElementName);
                    // Push the new element to the array
                    existingArray.push([plugin, pName]);
                    // Set the updated array back to the Map
                    matchingComponents.set(pElementName, existingArray);
                }
            }
        });
        return matchingComponents;
    }


    function update(pPlugin: PluginData) {
        for (let i = 0; i < available.value.length; i++) {
            if (available.value[i].id === pPlugin.id) {
                Object.assign(available.value[i], pPlugin);
                reloadPlugin(pPlugin);
                return;
            }
        }        
    }

    /**
     * Start a plugin
     */
    async function startPlugin(pPlugin: PluginData) {
        if (active.value.has(pPlugin.name)) {
            return;
        }

        const plugin = new Plugin(pPlugin);
        plugin.loadComponents();

        active.value.set(pPlugin.name, plugin);
    }

    /**
     * Reload an active plugin
     */
    async function reloadPlugin(pPlugin: PluginData) {
        
        const plugin = active.value.get(pPlugin.name);
        if(plugin == undefined)
            return;

        plugin.loadComponents();
    }

    /**
     * Stop a plugin
     */
    async function stopPlugin(pPlugin: PluginData) {

        active.value.delete(pPlugin.name);
    }

    /**
     * Turn a plugin on/off
     */
    async function toggleActivation(pPlugin: PluginData): Promise<PluginData> {
        try {
            const response = await axios.post(`/v1/plugin/${pPlugin.id}/toggle`);
            return response.data;
        } catch (error) {
            console.error('Failed to toggle plugin: ', error);
            throw error;
        }
    }

    async function toggle(pPlugin: PluginData) {
        let plugin = await toggleActivation(pPlugin);

        update(plugin);

        if (plugin.active) {
            stopPlugin(pPlugin);
        } else {
            startPlugin(pPlugin);
        }
    }

    return {
        available, active, options,
        load, websocketMessage, getMatchingComponents, update, loadList, toggle, optionsSave
    };
});

