import { ObjectData, ObjectInterface } from "rundown-common";
import { useObjectsStore } from "../stores/objects";
import { PropertyData } from "rundown-common";
import { useMessageStore } from "../stores/messages";
import { Ref, ref } from "vue";

/**
 * Represents tangible items like keyboards or skateboards
 */
export class cObject implements ObjectInterface {
    object: ObjectData;
    properties: Ref<Array<PropertyData>> = ref([]);

    constructor(pObject: ObjectData) {
        this.object = pObject;
        this.loadFrom(pObject);
    }

    loadFrom = (pObject:ObjectData): void => {
        this.object = pObject;
        if(Array.isArray(this.object.properties)) {
            this.properties.value = [...this.object.properties]
        } else {
            this.properties.value = [];
        }
    }

    /**
     * Name
     */
    get name(): string {
        return this.object.name;
    }

    set name(value: string) {
        this.object.name = value;
        this.save();
    }

    /**
     * Description
     */
    get description(): string {
        return this.object.description;
    }

    set description(value: string) {
        this.object.description = value;
        this.save();
    }

    /**
     * Parent object
     */
    get parent(): ObjectInterface|null {
        const objectstore = useObjectsStore();
        if(this.object.isObjectId == null)
            return null;

        return objectstore.getObjectById(this.object.isObjectId);
    }

    set parent(pObject: ObjectInterface|null) {

        this.object.isObjectId = pObject?.getId() ?? null;
        this.save();
    }

    getId = (): number => {
        return this.object.id;
    }

    getSearchName = (): string => {
        return this.object.name;
    }

    getProperties = (): Ref<Array<PropertyData>> => {
        return this.properties;
    }

    getProperty = (pName: string): PropertyData | null => {
        const property = this.properties.value.find(prop => prop.name === pName);
        return property || null;
    };

    addProperty = async (pName: string, pValue: string): Promise<void> => {
        const obj = { id: 0, name: pName, value: pValue, hidden: false };
        this.properties.value.push(obj);
        await this.savePropertys();
    }

    deleteProperty = async (pProperty: PropertyData): Promise<void> => {
        this.properties.value = this.properties.value.filter(pProp => pProp.name !== pProperty.name);
        await this.savePropertys();
    };

    /**
     * Save all changes
     */
    save = async (): Promise<void> => {

        this.object.name = this.name;

        const objectstore = useObjectsStore();
        objectstore.updateObject(this);

        await this.savePropertys();
    }

    /**
     * Save all propertys
     */
    savePropertys = async (): Promise<void> => {
        const messages = useMessageStore();

        try {
            await this.saveNewPropertys();
            await this.saveDeletedProperties();

            this.properties.value = [...this.object.properties];
        } catch (error) {
            messages.add("Error saving properties:", error);
        }
    }

    private saveNewPropertys = async (): Promise<void> => {
        const objectstore = useObjectsStore();
    
        for (let i = 0; i < this.properties.value.length; i++) {
            const property = this.properties.value[i];

            if (!this.object.properties.some(objProp => objProp.id === property.id)) {
                this.properties.value[i] = await objectstore.addProperty(this, property);
            }
        }
    }
    
    private saveDeletedProperties = async (): Promise<void> => {
        const objectstore = useObjectsStore();
    
        const deletedItems = this.object.properties.filter(objProp =>
            objProp.id !== 0 && !this.properties.value.some(property => property.id === objProp.id));
    
        await Promise.all(deletedItems.map(pProperty => objectstore.deleteProperty(this, pProperty)));
    }
    
}
