import axios from 'axios';
import { defineStore } from 'pinia'
import { EventType, iEventLog } from './websocket';
import { ActionInterface, ActionMessageInterface } from 'rundown-common';

export const useMessageStore = defineStore("messages", {
    state: () => ({
        events: { 'error': [], 'status': [] } as Record<string, Array<iEventLog>>,
        client: [] as Array<string>
    }),

    getters: {
        hasError(state) {
            return (state.events['error'].length > 0);
        },

        getError(state): iEventLog {
            return state.events['error'][0] ?? [];
        },

        getErrors(state): Array<iEventLog> {
            return state.events['error']
        },

        hasStatus(state) {
            return (state.events['status'].length > 0);
        },

        getStatus(state): iEventLog {
            return state.events['status'][0] ?? [];
        },

        getStatuses(state): Array<iEventLog> {
            return state.events['status']
        },

        hasClient(state) {
            return state.client.length > 0;
        },

        getClient(state): string {
            return state.client[0] ?? '';
        },

    },

    actions: {

        handleAxiosError(error: any) {

            if (axios.isAxiosError(error)) {

                if (error.code === 'ECONNABORTED' || error.code === 'ERR_NETWORK') {
                    this.add('Run-Down server is currently not available');
                    return;
                }

                if (error.response?.status === 403) {
                    this.add('Permission Denied');
                    return;
                }
                
                if (error.response?.status === 500) {
                    this.add('Unknown server error');
                    return;
                }
                
            }
        },

        processAction(pMessage: ActionMessageInterface) {
            const entry = {
                id: 0,
                level: EventType.Status,
                message: pMessage.message
            } as iEventLog;

            this.process(entry);
        },

        process(pEventLog: iEventLog) {
            // Empty Messages can be discarded
            if (pEventLog.message == '') {
                return;
            }

            var targetArray = this.getEventArray(pEventLog);
            if(targetArray == undefined)
                return;

            targetArray.push(pEventLog);
        },

        getEventArray(pEventLog: iEventLog) {
            switch (pEventLog.level) {

                case EventType.Error:
                    return this.events['error'];

                case EventType.Notice:
                case EventType.Status:
                    return this.events['status'];
            }
        },

        remove(pType: string) {
            if (pType === 'client') {
                this.client.shift();
                return;
            }

            this.events[pType].shift();
        },

        removeItem(pEventLog: iEventLog) {
            var targetArray = this.getEventArray(pEventLog);
            if(targetArray == undefined || targetArray?.length == 0)
                return;

            const index = targetArray.indexOf(pEventLog);
            targetArray.splice(index, 1);
        },

        add(pMessage: string) {
            if (!this.client.includes(pMessage)) {
                this.client.push(pMessage);
            }
        }        
    },
})