import axios, { AxiosResponse } from 'axios';
import { ItemObjectData } from 'rundown-common';

export default {

    create(pItemID: number, pTagName: string, pItemObject: ItemObjectData) : Promise<AxiosResponse> {
        return axios.post('v1/item/' + pItemID.toString() + '/tag/' + pTagName + '/object', pItemObject);
    },

    update(pItemID: number, pTagName: string, pItemObject: ItemObjectData) : Promise<AxiosResponse> {
        return axios.put('v1/item/' + pItemID.toString() + '/tag/' + pTagName + '/object', pItemObject);
    },

    delete(pItemID: number, pTagName: string, pItemObject: ItemObjectData) : Promise<AxiosResponse> {
        return axios.delete('v1/item/' + pItemID.toString() + '/tag/' + pTagName + '/object/' + pItemObject.id.toString());
    },

    /**
     * Objects
     */
    async objectCreate(pItemID: number, pItemObject: ItemObjectData) : Promise<ItemObjectData> {
        pItemObject.type = "object";
        return (await this.create(pItemID, 'Object', pItemObject)).data;
    },

    async objectUpdate(pItemID: number, pItemObject: ItemObjectData) : Promise<ItemObjectData> {
        return (await this.update(pItemID, 'Object', pItemObject)).data;
    },

    async objectDelete(pItemID: number, pItemObject: ItemObjectData) : Promise<AxiosResponse> {
        return (await this.delete(pItemID, 'Object', pItemObject)).data;
    },

    /**
     * Person
     */
    async personCreate(pItemID: number, pItemObject: ItemObjectData) : Promise<ItemObjectData> {
        pItemObject.type = "person";
        return (await this.create(pItemID, 'People', pItemObject)).data;
    },

    async personUpdate(pItemID: number, pItemObject: ItemObjectData) : Promise<ItemObjectData> {
        return (await this.update(pItemID, 'People', pItemObject)).data;
    },

    async personDelete(pItemID: number, pItemObject: ItemObjectData) : Promise<AxiosResponse> {
        return (await this.delete(pItemID, 'People', pItemObject)).data;
    }
};