<script setup lang="ts">
import { PropType, computed, ref, watch } from 'vue';
import ObjectDetails from '../../components/object/ObjectDetails.vue';
import { ObjectInterface } from 'rundown-common';

const props = defineProps({
    object: {
        type: Object as PropType<ObjectInterface | null>,
        default: null
    },
    visible: {
        type: Boolean,
        required: true,
        default: false
    }
});


const emit = defineEmits<{
    (e: 'closed'): void;
}>();

const activeObject = computed(() => props.object);
const isVisible = ref(false);


const closeDialog = async () => {
    isVisible.value = false;
    emit('closed');
}

watch(() => props.visible, (newVal) => {
    isVisible.value = newVal;
});

</script>

<template>
    <template v-if="isVisible == true && activeObject">
        <v-dialog v-model="isVisible" @update:model-value="closeDialog" color="surface-variant">
            <v-card height="600" width="600" class="mx-auto" variant="elevated" title="Object Editor">
                <v-card-item>
                    <object-details :object="activeObject" />
                </v-card-item>
                <v-spacer></v-spacer>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text="Close" variant="plain" @click="closeDialog()"></v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </template>
</template>