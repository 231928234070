import axios, { AxiosResponse } from 'axios';
import { ItemData, ItemTagData, ItemTagsData, tTagTypeStrings } from 'rundown-common';
const isEqual = (...objects: any) => objects.every((obj: any) => JSON.stringify(obj) === JSON.stringify(objects[0]));

export default {

    create(pItemID: number, pTagName: string, pItemTag: ItemTagData<any>): Promise<AxiosResponse> {
        return axios.post('v1/item/' + pItemID.toString() + '/tag/' + pTagName, pItemTag);
    },

    update(pItemID: number, pTagName: string, pItemTag: ItemTagData<any>): Promise<AxiosResponse> {
        return axios.put('v1/item/' + pItemID.toString() + '/tag/' + pTagName, pItemTag);
    },

    delete(pItemID: number, pTagName: string, pItemTag: ItemTagData<any>): Promise<AxiosResponse> {
        return axios.delete('v1/item/' + pItemID.toString() + '/tag/' + pItemTag.id.toString());
    },

    /**
     * Set an item tag
     */
    async setTag(pItem: ItemData, pTagName: string, pData: any): Promise<ItemTagData<any> | undefined> {

        const TagName = pTagName as keyof ItemTagsData;
        const Tag = pItem.tags[TagName];

        // Remove timezone name off dates
        if (pData instanceof Date) {
            pData = pData.toString();
            pData = pData.replace(/\s\(.+\)$/, '');
        }

        if (pData != null) {

            // New Tag?
            if (Tag == undefined || Tag.id == 0) {

                // If data is empty, dont create a tag
                if (pData.length == 0) {
                    return undefined;
                }

                return (await this.create(pItem.id, pTagName, { "data": pData } as ItemTagData<any>)).data;
            }

            // update if it has changed
            if (!isEqual(Tag.data, pData))
                return (await this.update(pItem.id, pTagName, { "data": pData } as ItemTagData<any>)).data;

            return pItem.tags[TagName];
        }

        if (Tag != undefined) {
            if (Tag.id != 0) {
                (await this.delete(pItem.id, pTagName, Tag));
            }
        }

        return undefined;
    },

    /**
     * Set an ARRAY_STRING type
     */
    async setTagStrings(pItem: ItemData, pTagName: string, pData: Array<string>): Promise<ItemTagData<tTagTypeStrings> | undefined> {

        var data: Array<string> = [];

        pData.forEach(function (value) {
            if (value != '' && value != null) {
                data.push(value);
            }
        });

        return (await this.setTag(pItem, pTagName, (data.length > 0) ? data : null));
    },

    /**
     * Set a tag
     */
    async set(pItem: ItemData, pTagName: string, pData: any): Promise<void> {
        const TagName = pTagName as keyof ItemTagsData;

        pItem.tags[TagName] = await this.setTag(pItem, pTagName, pData);
    },

    /**
     * Set an ARRAY_STRING tag
     */
    async setStrings(pItem: ItemData, pTagName: string, pData: Array<string>): Promise<void> {
        const TagName = pTagName as keyof ItemTagsData;

        pItem.tags[TagName] = (await this.setTagStrings(pItem, pTagName, pData));
    }
}
