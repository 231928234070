import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import axios from 'axios';
import { useMessageStore } from './messages';
import { ActionInterface, useWebSocketStore } from './websocket';
import { useItemPagingStore } from './itemPaging';
import { cItem } from '../domain/item';
import { useItemSelectionAndNavigation } from '../utility/useItemSelectionAndNavigation';
import { ArrayMap } from '../utility/arraymap';
import { ActionMessageInterface } from 'rundown-common';

export interface iCartParams {
    date: boolean;
    names: boolean;
    category: boolean;
    keywords: boolean;
}

export const useItemCartStore = defineStore('itemcart', {
    state: () => ({
        itemSelectionAndNavigation: useItemSelectionAndNavigation(useItemPagingStore()),
    }),
    getters: {

        selectedItem(state) {
            return state.itemSelectionAndNavigation.itemNavigation.getSelectedItem;
        },
        count(state) {
            return state.itemSelectionAndNavigation.itemSelection.selectedItems.getTotalRecords();
        },
    },
    actions: {
        clear() {
            this.itemSelectionAndNavigation.itemSelection.clear();
        },
        hasItem(pItem: cItem): boolean {
            return this.itemSelectionAndNavigation.itemSelection.hasItem(pItem);
        },
        toggleSelectedItem(pItem: cItem) {
            this.itemSelectionAndNavigation.itemSelection.toggleSelectedItem(pItem);
        },
        
        listenMessages() {
            useWebSocketStore().joinPrivate('BuildSlideshow', this.websocketMessage);
        },
        
        websocketMessage(pData: any) {
            const messages = useMessageStore();
            var data = pData.data as ActionMessageInterface;

            if (data.action == ActionInterface.Complete && 'fileid' in data.context) {
                this.startDownload(data.context.fileid);
                return;
            }

            messages.process(data);
        },

        async startDownload(pId) {
            await axios.get('/v1/slideshow/' + pId, { responseType: "blob" })
                .then((response) => {
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(new Blob([response.data]));
                    link.download = 'slideshow.zip';
                    link.click();
                    window.URL.revokeObjectURL(link.href);
                });
        },
    },
    persist: {
        serializer: {
            serialize: (state) => {
                // Convert the Map to an array for serialization
                const stateCopy = { ...state, selectedItems: state.itemSelectionAndNavigation.itemSelection.selectedItems.all() };
                return JSON.stringify(stateCopy);
            },
            deserialize: (str) => {
                const state = JSON.parse(str);
                // Convert the array back to a Map

                state.itemSelectionAndNavigation.itemSelection.selectedItems.items = new ArrayMap<number, cItem>();
                state.selectedItems.forEach((i: cItem) => {
                    let item = new cItem();
                    Object.assign(item, i);
                    state.itemSelectionAndNavigation.itemSelection.selectedItems.items.set(item.getId(), item);
                });

                return state;
            }
        },
        afterRestore: (state) => {

            state.store.listenMessages();
        }
    }
});

