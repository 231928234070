<script setup lang="ts">
import { ref } from 'vue';

import { useFilterStore } from '../../stores/filters';
import { useKeywordStore } from '../../stores/keywords';
import { usePeopleStore } from '../../stores/people';
import { PersonNameData } from 'rundown-common';
import { Filter, FilterPerson, FilterKeyword, FilterGroup } from '../../domain/FilterParameters/Filter';
import { computed } from 'vue';
import { cPerson } from '../../domain/Person';
import { cObject } from '../../domain/Object';

const keywords = useKeywordStore();
const filters = useFilterStore();
const people = usePeopleStore();

const keywordSearchBox = ref(null);

const filterGroupCount = computed(() => filters.searchboxmodel.reduce((count, kw) => count + (kw instanceof FilterGroup ? 1 : 0), 0));

var displayKeywordSearch = ref(true);
var autocompleteList = ref([] as Array<string | cPerson | object>);

const draggedKeyword = ref<Filter | null>(null);
const draggedFromGroup = ref<FilterGroup | null>(null);

/**
 * Show or Hide the keyword selector dropdown
 */
function menuDisplayed(value: boolean) {
    setTimeout(function () {
        keywordSearchBox._value.menu = value;
    }, 100);
}

/**
 * Event: Keyword dropdown update
 */
function keywordSearchUpdate(pKeywords: Array<string | cPerson | FilterGroup>) {

    let filterGroup = pKeywords.find(kw => kw instanceof FilterGroup);
    let filterGroupCount = pKeywords.reduce((count, kw) => count + (kw instanceof FilterGroup ? 1 : 0), 0);

    if (filterGroupCount === 1 && filterGroup != undefined) {
        pKeywords.forEach((kw, index) => {
            if (!(kw instanceof FilterGroup)) {
                pKeywords.splice(index, 1);
                filterGroup.add(kw);
            }
        });
    } else {
        pKeywords.forEach((kw, index) => {
            if (!(kw instanceof FilterGroup)) {
                pKeywords[index] = new FilterGroup(kw);
            }
        });
    }


    // Close the search menu
    menuDisplayed(false);
}


/**
 * Event: Keyword search keypress
 */
async function keywordSearchInput(pSearchValue: string) {
    autocompleteList.value = [];

    if (pSearchValue.length < 3) {
        menuDisplayed(false);
        return;
    }

    autocompleteList.value.push(...keywords.getAll, ...people.get);

    menuDisplayed(true);
}

function getItemTitle(pItem: string | cPerson | FilterGroup | cObject) {
    if (typeof pItem === 'string') {
        return pItem;
    }

    if(pItem instanceof FilterGroup) {
        return pItem;
    }

    return pItem.getSearchName() ?? pItem;
}

function startDrag(keyword: Filter, pGroup: FilterGroup, event: DragEvent) {
    draggedKeyword.value = keyword;
    draggedFromGroup.value = pGroup;
    //event.dataTransfer?.setData("text", keyword);
}

function onDropCombo(event: DragEvent) {
    event.preventDefault();
    if (draggedKeyword.value) {
        const keyword = draggedKeyword.value;
        const group = draggedFromGroup.value;

        if (group?.remove(keyword)) {

            filters.$state.searchboxmodel.forEach((pGroup, index) => {
                if (pGroup === group) {
                    filters.$state.searchboxmodel.splice(index, 1);
                }
            });
        }

        filters.$state.searchboxmodel.push(new FilterGroup(keyword));

        draggedKeyword.value = null;
        draggedFromGroup.value = null;
    }
}

function onDrop(event: DragEvent, pGroup: FilterGroup) {
    event.preventDefault();
    if (draggedKeyword.value) {
        const keyword = draggedKeyword.value;
        const group = draggedFromGroup.value;

        if (group?.remove(keyword)) {

            filters.$state.searchboxmodel.forEach((pGroup, index) => {
                if (pGroup === group) {
                    filters.$state.searchboxmodel.splice(index, 1);
                }
            });
        }
        pGroup.add(keyword);

        draggedKeyword.value = null;
        draggedFromGroup.value = null;
    }
}

function allowDrop(event: DragEvent) {
    event.preventDefault();
}

const onChipClick = (keyword: Filter) => {
    keyword.toggleMode();
}

const onChipClose = (pGroup: FilterGroup, keyword: Filter) => {
    if (pGroup.remove(keyword)) {
        filters.$state.searchboxmodel.forEach((group, index) => {
            if (group === pGroup) {
                filters.$state.searchboxmodel.splice(index, 1);
            }
        });
    }
}
</script>

<template>
    <v-btn v-if="!displayKeywordSearch" icon>
        <v-icon @click="displayKeywordSearch = !displayKeywordSearch" icon="mdi-magnify"></v-icon>
        <v-tooltip activator="parent" location="bottom">Keyword Filter</v-tooltip>
    </v-btn>

    <v-combobox ref="keywordSearchBox" v-model="filters.$state.searchboxmodel" v-if="displayKeywordSearch"
        variant="solo-filled" hide-details :delimiters="[',']" :items="autocompleteList" multiple closable-chips chips
        :item-title="getItemTitle" @update:modelValue="keywordSearchUpdate" @update:search="keywordSearchInput"
        hide-selected @drop="onDropCombo($event)" @dragover="allowDrop">
        <template v-slot:prepend>
            <v-icon class="d-none d-sm-flex" @click="displayKeywordSearch = !displayKeywordSearch">mdi-magnify</v-icon>
        </template>>

        <template v-slot:chip="{ item, index }">

            <div :class="{ 'keyword-group': filterGroupCount > 1 }" :key="index" @drop="onDrop($event, item.value)" @dragover="allowDrop">

                <v-chip class="chip" v-for="keyword in item.value.get()" :key="keyword.id()" :color="keyword.getColor()"
                    closable :draggable="true" @mousedown.capture.stop=""
                    @dragstart="startDrag(keyword, item.value, $event)" @click.stop="onChipClick(keyword)"
                    @click:close.stop="onChipClose(item.value, keyword)">
                    {{ keyword.value() }}
                </v-chip>
            </div>
        </template>
    </v-combobox>
</template>
<style scoped>
.keyword-group {
    display: flex;
    flex-wrap: wrap;
    background-color: #454545;
    /* Medium grey for visibility */

    border-radius: 6px;
    border: 2px solid #6e6e6e;
    /* Visible border */
    margin: 20px 0;
    /* Increased margin for better spacing */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.6);
    /* Shadow for depth */
}

.keyword-group .chip {
    display: inline-flex;
    align-items: center;
    padding: 6px 12px;
    margin: 4px;
    background-color: #5c5c5c;
    /* Slightly lighter for distinction */
    border-radius: 15px;
    /* Rounded edges */
    color: #ffffff;
    /* White text */
    font-size: 13px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s, transform 0.2s;
}

.keyword-group .chip:hover {
    background-color: #6d6d6d;
    /* Hover effect */
    transform: translateY(-2px);
    /* Lifting effect */
}

.keyword-group .chip .close-btn {
    margin-left: 6px;
    cursor: pointer;
}

/* Style for the close button */
.keyword-group .chip .close-btn:before {
    content: '\00D7';
    /* 'X' symbol */
    color: #ffffff;
    font-size: 14px;
    font-weight: bold;
}
</style>