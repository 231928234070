import { ref } from 'vue'
import { useItemSlidingWindowStore } from '../stores/itemSlidingWindow';
import { cItem } from '../domain/item';
import { useItemPagingStore } from '../stores/itemPaging';
import { ItemManager } from './ItemManager';

export function useItemSelection(store: ReturnType<typeof useItemSlidingWindowStore|typeof useItemPagingStore>,pItems:Array<cItem> | null = null) {
    const selectedItems = ref(new ItemManager());

    function clear() {
        selectedItems.value.clearItems();
    }

    /**
     * Select all items on the page
     */
    function selectAllItems(event: Event) {
        const target = event.target as HTMLInputElement;

        // If the checkbox has been checked
        if (target.checked) {

            // Add items that are not already selected
            store.items.forEach(item => {
                selectedItems.value.addItem(item);
            });

        } else {

            // Remove the selected items 
            store.items.forEach(item => {
                selectedItems.value.removeItem(item);
            });
        }
    }

    /**
     * Toggle an item as selected
     */
    function toggleSelectedItem(item: cItem) {

        if (selectedItems.value.hasItem(item)) {
            selectedItems.value.removeItem(item);
        } else {
            selectedItems.value.addItem(item);
        }
    }

    function hasItem(item: cItem) {
        return selectedItems.value.hasItem(item);
    }

    /**
     * 
     */
    function isAllItemsInPageSelected() {
        return selectedItems.value.getTotalRecords() > 0 && store.items.every(item => selectedItems.value.hasItem(item));
    }

    return {
        selectedItems,
        clear,
        hasItem,
        selectAllItems,
        toggleSelectedItem,
        isAllItemsInPageSelected
    }
}