<script setup lang="ts">

import { ref } from 'vue';
import { useCollectionStore } from '../../stores/collections';
import { useItemSlidingWindowStore } from '../../stores/itemSlidingWindow';
import ItemTable from '../../components/table/items.vue';
import { onBeforeMount } from 'vue';
import { useItemStore } from '../../stores/items';
import { watch } from 'vue';
import { cItem } from '../../domain/item';
import { useItemSelection } from '../../utility/useItemSelection';
import { CollectionData } from 'rundown-common';

const collections = useCollectionStore();
const itemWindow = useItemSlidingWindowStore();
const items = useItemStore();

const { selectedItems, selectAllItems, toggleSelectedItem, isAllItemsInPageSelected } = useItemSelection(itemWindow);

var loading = ref();
var notready = ref(true);

var destinationCollection = ref({ 'id': 0, 'name': '' } as CollectionData);

watch(selectedItems, function () {
    checkIfReady();
});

/**
 * Check if source/destination are selected
 */
function checkIfReady() {
    if (selectedItems.value.size() > 0 && destinationCollection.value.id != 0) {
        notready.value = false;
    } else {
        notready.value = true;
    }
}

/**
 * Event: Destination Selected
 */
function destinationCollectionSelect(pCollection: CollectionData | null) {
    if (pCollection == null)
        return;

    destinationCollection.value = pCollection;
    checkIfReady();
}

/**
 * Event: Start Import
 */
async function collectionTransfer() {
    loading.value = true;
    var selectedItemIds = Array.from(selectedItems.value.all()).map((item: cItem) => item.getId().toString());
    await collections.transfer(destinationCollection.value, selectedItemIds);
    loading.value = false;
}

onBeforeMount(async () => {
    await items.load();
})
</script>


<template>
    <v-container>
        <v-card class="mx-auto w-50" variant="outlined">
            <template v-slot:title>
                Transfer Items
            </template>
            <v-card-item>

                <v-select prepend-icon="mdi-export" :items="collections.getManaged" item-title="name"
                    label="Destination Collection" return-object single-line
                    @update:modelValue="destinationCollectionSelect">
                </v-select>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn :loading="loading" :disabled="loading || notready" color="blue-grey"
                        prepend-icon="mdi-cloud-upload" @click="collectionTransfer()">
                        Start
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card-item>
        </v-card>


        <ItemTable height="100%" :isLoading="items.isLoading" read-only show-path show-actions :items="itemWindow.items"
            :item-current="items.getSelectedItem" :page="itemWindow.windowNumber" :pages="itemWindow.totalWindows"
            :items-per-page="itemWindow.windowSize" @page-select="itemWindow.changeToWindow" @item-select="items.selectItem"
            @items-per-page-change="itemWindow.setWindowSize" @item-next="itemWindow.moveDown"
            @item-previous="itemWindow.moveUp" @page-next="itemWindow.moveWindowDown"
            @page-previous="itemWindow.moveWindowUp">

            <template #title>
                Items
            </template>

            <template #actionHeader="{ }">
                <v-checkbox-btn label="All" :model-value="isAllItemsInPageSelected()"
                    @click="selectAllItems"></v-checkbox-btn>
            </template>
            <template #action="{ item }">

                <v-checkbox-btn :model-value="selectedItems.hasItem(item)"
                    @click.stop="toggleSelectedItem(item)"></v-checkbox-btn>

            </template>

        </ItemTable>

    </v-container>
</template>
