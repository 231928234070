<script setup lang="ts">
import { ref } from 'vue';
import { useFilterStore } from '../../stores/filters';
import { useSettingsStore } from '../../stores/settings';

const props = defineProps({
    listMode: {
        type: Boolean,
        default: true
    },
});

const filter = useFilterStore();
const settings = useSettingsStore();

var visible = ref(false);
var searchInTags = ['Keywords', 'Category', 'People', 'Author'];

</script>

<template>
    <v-menu v-model="visible" :close-on-content-click="false" location="start" transition="scale-transition">
        <template v-slot:activator="{ props }">
            <template v-if="listMode == true">
                <v-btn icon v-bind="props">
                    <v-icon icon="mdi-account-cog"></v-icon>
                    <v-tooltip activator="parent" location="bottom">Options</v-tooltip>
                </v-btn>
            </template>
            <template v-else>
                <v-list-item v-bind="props" prepend-icon="mdi-account-cog">

                    <v-list-item-title>Options</v-list-item-title>
                </v-list-item>
            </template>
        </template>

        <v-card min-width="300">
            <v-list>
                <v-list-item prepend-icon="mdi-account-cog" title="Options" subtitle="Filters"></v-list-item>
            </v-list>

            <v-divider></v-divider>

            <v-list>
       
                <v-list-item>
                    <v-switch  v-model="settings.homeViewModeIsPaged" color="purple" true-value="Infinite Scroll" false-value="Paging"
                        :label="`View Mode: ${settings.homeViewModeIsPaged}`" hide-details>
                    </v-switch>
                </v-list-item>

                <v-list-item>
                    <v-combobox v-model="filter.searchInTags" :items="searchInTags" label="Search in tags" multiple chips
                        closable-chips></v-combobox>
                </v-list-item>

            </v-list>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text="Cancel" @click="visible = false">

                </v-btn>
                <v-btn color="primary" text="Done" @click="visible = false">
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-menu>
</template>