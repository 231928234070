import { ImageScale, ItemInterface, ItemObjectData, ItemObjectInterface, ItemPersonInterface } from "rundown-common";
import { cItem } from "./item";
import { cPerson } from "./Person";
import { cObject } from "./Object";

export abstract class cItemObjectBase {
    item: ItemInterface;
    object: ItemObjectData;

    abstract save(): Promise<boolean>;
    abstract delete(): Promise<boolean>;
    
    /**
     * Constructor
     */
    constructor(pItemObjectData: ItemObjectData | cObject | cPerson, pItem: cItem) {
        this.item = pItem;

        // If an cPerson|cObject is passed in, we are creating a new item object
        if (pItemObjectData instanceof cObject || pItemObjectData instanceof cPerson) {
            this.object = { id: 0, object: pItemObjectData.getId() } as ItemObjectData;
        } else {
            this.object = pItemObjectData;
        }
    }

    getId = (): number => {
        return this.object.id;
    }

    getBoundingBox = (): ImageScale => {
        return {
            x: this.object.x,
            y: this.object.y,
            width: this.object.width,
            height: this.object.height
        };
    }

    isPositionInside = (pX: number, pY: number): boolean => {
        if (!this.object.width && !this.object.height)
            return false;

        const withinX = pX >= this.object.x && pX <= (this.object.x + this.object.width);
        const withinY = pY >= this.object.y && pY <= (this.object.y + this.object.height);
        return withinX && withinY;
    }

    isPositionInsideLeeway = (pX: number, pY: number, pWidth: number, pHeight: number, pLeeway: number = 5) => {
        return (
            Math.abs(this.object.x - pX) <= pLeeway &&
            Math.abs(this.object.y - pY) <= pLeeway &&
            Math.abs(this.object.width - pWidth) <= pLeeway &&
            Math.abs(this.object.height - pHeight) <= pLeeway
        );
    }

    positionSet = async (pX: number, pY: number, pWidth: number, pHeight: number) => {
        this.object.x = pX;
        this.object.y = pY;
        this.object.width = pWidth;
        this.object.height = pHeight;

        if(this.getId())
            await this.save();
    }

    positionFrom = async (pObj: ItemObjectInterface|ItemPersonInterface) => {
        await this.positionSet(pObj.object.x, pObj.object.y, pObj.object.width, pObj.object.height);
    }
}
