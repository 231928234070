import axios from "axios";
import { defineStore } from "pinia";
import { useMessageStore } from "./messages";
import { jwtDecode, JwtPayload } from "jwt-decode";
import { useRouter } from 'vue-router'
import { UserData } from "rundown-common";

export const useAuthStore = defineStore("auth", {
  state: () => ({
    accessToken: "",
    globalAdmin: false,
    showRegisterDialog: false,
    showLoginDialog: false,
    protectedRoute: false,
  }),

  getters: {
    isAuthed: (state) => state.accessToken.length > 0,
    isGlobalAdmin: (state) => state.globalAdmin,
    hasDialog: (state) => state.showRegisterDialog || state.showLoginDialog,
    getToken: (state) => state.accessToken,
    getTokenAsBearer: (state) => (state.accessToken.length > 0 ? "Bearer " + state.accessToken : ""),

    getTokenContent(state): JwtPayload {
      state.globalAdmin = false;
      if (!this.isAuthed) return {} as JwtPayload;

      var decoded = jwtDecode<JwtPayload>(state.accessToken);
      state.globalAdmin = decoded.globaladmin ?? false;
      
      return decoded;
    }
  },

  actions: {
    setAxiosHeader() {
      if (this.isAuthed) {
        axios.defaults.headers.common["Authorization"] = this.getTokenAsBearer;
      } else {
        delete axios.defaults.headers.common["Authorization"];
      }
    },

    /**
     * Set the access token
     */
    setAccessToken(pAccessToken: string = "") {
      this.accessToken = pAccessToken;
      this.checkExpired();
      this.setAxiosHeader();
    },

    /**
     * Check if the token has expired
     */
    checkExpired() {
      var token = this.getTokenContent;
      if (token.exp !== undefined) {
        // Completely expired
        if (token.exp <= Math.floor(Date.now() / 1000)) {
          this.setAccessToken();
          this.showLoginDialog = true;
          return;
        }

        // 10minutes before expiry
        var tentoexpiry = token.exp - (60 * 10);
        let secondsTilExpiry = tentoexpiry - Math.floor(Date.now() / 1000);
        if (secondsTilExpiry < 1) {
          secondsTilExpiry = 1;
        }

        setTimeout(() => {
          this.tokenRefresh();
        }, secondsTilExpiry * 1000);
      }
    },

    /**
     * Register
     */
    async register(pUsername: string, pName: string, pEmail: string, pPassword: string) {
      const messages = useMessageStore();

      try {
        this.setAccessToken();

        const data = await axios.post("/v1/users/register", {
          username: pUsername,
          name: pName,
          email: pEmail,
          password: pPassword,
        });
      } catch (error) {
        messages.add("Registration Failed");
        return false;
      }
      return true;
    },

    /**
     * Login
     */
    async login(pUsername: string, pPassword: string) {
      const messages = useMessageStore();

      try {
        this.setAccessToken();

        const data = await axios.post("/v1/users/login", {
          username: pUsername,
          password: pPassword,
        });

        this.setAccessToken(data.data.token);
      } catch (error) {

        messages.add("Login Failed");
        return false;
      }

      return true;
    },

    /**
     * Logout
     */
    async logout() {
      try {
        await axios.get('/v1/users/logout');
      } catch(e) {
        
      }
      this.setAccessToken();
    },

    /**
     *
     */
    async tokenRefresh() {
      const messages = useMessageStore();

      try {
        const data = await axios.get("/v1/users/renew");
        this.setAccessToken(data.data.token);
      } catch (error) {
        messages.add("Failed to renew session");
        this.setAccessToken();
      }
    },

    /**
     *
     */
    async cancelLogin() {
      this.showLoginDialog = false;
      if (this.protectedRoute) {
        this.protectedRoute = false;
        useRouter().push({ path: "/", replace: true });
      }
    },

    /**
     *
     */
    async getUsers(): Promise<Array<UserData>> {
      const messages = useMessageStore();

      try {
        const data = await axios.get("/v1/users");
        return data.data;
      } catch (error) {
        messages.add(error);
        return [];
      }
    },
  },

  persist: {
    afterRestore: (ctx) => {
      ctx.store.setAxiosHeader();
      ctx.store.checkExpired();
    },
  },
});
