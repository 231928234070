<script setup lang="ts">
import { onMounted } from 'vue';
import { computed } from 'vue';

const props = defineProps({
  x: {
    type: Number,
    required: true
  },
  y: {
    type: Number,
    required: true
  },
  width: {
    type: Number,
    required: true
  },
  height: {
    type: Number,
    required: true
  },
  selected: {
    type: Boolean,
    requried: true
  }
});

const emit = defineEmits<{
  (e: 'update:left', value: number): void;
  (e: 'update:top', value: number): void;
  (e: 'update:width', value: number): void;
  (e: 'update:height', value: number): void;
  (e: 'update'): void;
  (e: 'dblclick', pEvent: MouseEvent): void;
  (e: 'hover', pEvent: PointerEvent): void;
  (e: 'hover-leave', pEvent: PointerEvent): void;
}>();

const style = computed(() => ({
  left: props.x + 'px',
  top: props.y + 'px',
  width: props.width + 'px',
  height: props.height + 'px'
}));

const isSelected = computed(() => props.selected);

let dragging: boolean = false;
let dragged: boolean = false;
let start: { x: number, y: number } = { x: 0, y: 0 };

let startWidth: number;
let startHeight: number;
let initialMouseX: number;
let initialMouseY: number;

let currentResizeHandler: ((e: PointerEvent) => void) | null = null;

const doubleClick = (event: MouseEvent) => {
  emit('dblclick', event);
};

const pointerOver = (event: PointerEvent) => {
  emit('hover', event);
}

const pointerLeave = (event: PointerEvent) => {
  emit('hover-leave', event);
}

const doDrag = (event: PointerEvent) => {
  const dx = event.clientX - initialMouseX;
  const dy = event.clientY - initialMouseY;
  const distanceMoved = Math.sqrt(dx * dx + dy * dy);

  // Initiate drag if moved beyond threshold and not already dragging
  if (!dragged && distanceMoved > 0) {
    dragged = true;
  }

  // Update position if dragging
  if (dragged) {
    emit('update:left', start.x + dx);
    emit('update:top', start.y + dy);
  }
};

const startDrag = (event: PointerEvent) => {
  if (currentResizeHandler || dragging)
    return;

  dragging = true;
  start.x = props.x;
  start.y = props.y;
  initialMouseX = event.clientX;
  initialMouseY = event.clientY;
  document.addEventListener('pointermove', doDrag);
  event.preventDefault();
};

const endDrag = (event: PointerEvent) => {
  dragging = false;

  document.removeEventListener('pointermove', doDrag);
  if (dragged)
    emit('update');
  
  dragged = false;
  event.preventDefault();
};

const startResize = (event: PointerEvent, corner: string) => {
  start.x = props.x;
  start.y = props.y;
  initialMouseX = event.clientX;
  initialMouseY = event.clientY;
  currentResizeHandler = (e: PointerEvent) => doResize(e, corner);
  document.addEventListener('pointermove', currentResizeHandler);
  document.addEventListener('pointerup', endResize, { capture: true });
  event.preventDefault();
};

const doResize = (event: PointerEvent, corner: string) => {
  const dx = event.clientX - initialMouseX;
  const dy = event.clientY - initialMouseY;

  if (corner === 'br') {
    emit('update:width', Math.max(startWidth, startWidth + dx));
    emit('update:height', Math.max(startHeight, startHeight + dy));
  }
};

const endResize = () => {
  if (currentResizeHandler) {
    document.removeEventListener('pointermove', currentResizeHandler);
    currentResizeHandler = null;
  }
  document.removeEventListener('pointerup', endResize);
  emit('update');
};

onMounted(() => {
  startWidth = props.width;
  startHeight = props.height;
})
</script>

<template>
  <div :class="{ 'bounding-box': true, 'bounding-box-selected': isSelected }" :style="style" @dblclick="doubleClick"
    @pointermove="pointerOver" @pointerleave="pointerLeave" @pointerdown.stop="startDrag" @pointerup.stop="endDrag">
    <div class="resize-handle br" @pointerdown.capture.prevent="startResize($event, 'br')"></div>
  </div>
</template>
  
<style scoped>

.resize-handle {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: blue;
  z-index: 10001;
}
.br {
  right: -5px;
  bottom: -5px;
  cursor: nwse-resize;
}
</style>
