import { defineStore } from 'pinia';

import { cItem } from '../domain/item';
import { useFilterStore } from './filters';
import { computed, ref } from 'vue';
import axios from 'axios';
import { ItemData } from 'rundown-common';

export type OnThisDayValueType = {
    [year: string]: cItem[];
};

export const useItemsOnThisDayStore = defineStore('itemsOnThisDay', () => {
    const onThisDay = ref({} as OnThisDayValueType);

    const getOnThisDay = computed(() => onThisDay.value);
    
    /**
     * Load items for 'on this day'
     */
    async function load() {
        const filters = useFilterStore();
        let query = `${filters.getCollectionId}?${filters.getParameters}&onthisday=${Intl.DateTimeFormat().resolvedOptions().timeZone}`;

        try {
            const response = await axios.get(`/v1/item/onthisday/${query}`);
            const data = response.data;

            onThisDay.value = {};
            Object.keys(data).forEach(year => {
                (onThisDay.value as OnThisDayValueType)[year]= data[year].map((pEntry: ItemData) => new cItem(pEntry));
            });
        } catch (error) {
            console.error('Error fetching data for On This Day:', error);
            // Handle the error appropriately
        }
    
    }

    return {
        getOnThisDay,

        load
    };
});