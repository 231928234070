<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { useTokenStore, eTokenType } from '../../stores/tokens';
import { useRouter } from 'vue-router'

const tokens = useTokenStore();

const props = defineProps({
    token: {
        type: String,
        required: true
    },
});

const name = ref('');
const router = useRouter();

onMounted(() => function () {

});

async function create() {

    try {
        var token = await tokens.create(props.token, name.value, eTokenType.ScreenSaver);
        if(token.id > 0) {
            router.push({ name: "Screen Saver", replace: true, query: { showtokens: 'true'} });
        }
    } catch(e) {
        // Token probably already exists
    }
}

function cancel() {
    router.push({ path: "/", replace: true });
}

</script>

<template>

    <v-container >
        <v-row>
        <v-col></v-col>
        <v-col cols="12" md="4">
        <v-card title="Register Token" text="">

            <v-row>
                <v-col >
                    <v-text-field v-model="props.token" hide-details="auto" label="Token" readonly
                        disabled></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col >
                    <v-text-field v-model="name" clearable hide-details="auto" label="Name" placeholder="Loungeroom TV"></v-text-field></v-col>
            </v-row>
            <v-card-actions>
                <v-btn color="error" @click="cancel">Cancel</v-btn>
                <v-spacer></v-spacer>
                <v-btn @click="create">Create</v-btn>                
            </v-card-actions>
        </v-card>
    </v-col>
    <v-col></v-col>
</v-row>
    </v-container>
</template>