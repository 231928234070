import { Ref, ref } from 'vue';
import { cItem } from '../domain/item';
import { ItemManagerInterface } from './ItemManagerInterface';
import { ArrayMap } from './arraymap';

export class ItemManager implements ItemManagerInterface {
    items: ArrayMap<number, cItem>;
    
    constructor() {
        this.items = new ArrayMap<number, cItem>();
    }

    getIndexOfItem(item: cItem): number {
        return this.items.getIndexByKey(item.getId()) ?? -1;
    }

    async getItemByIndex(index: number): Promise<cItem | undefined> {
        return this.items.getByIndex(index);
    }

    size(): number {
        return this.items.length();
    }
    
    getTotalRecords(): number {
        return this.items.length();
    }

    addItem(item: cItem): void {
        this.items.set(item.getId(), item);
    }

    removeItem(item: cItem): void {
        this.items.delete(item.getId());
    }

    hasItem(item: cItem): boolean {
        return this.items.hasKey(item.getId());
    }

    getItemById(id: number): cItem | undefined {
        return this.items.getByKey(id);
    }

    clearItems(): void {
        this.items.clear();
    }

    map(): ArrayMap<number, cItem> {
        return this.items;
    }

    all(): cItem[] {
        return this.items.entries();
    }
}
