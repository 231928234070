import axios, { AxiosResponse } from 'axios';
import { CollectionData } from 'rundown-common';

function create(pCollection: CollectionData): Promise<AxiosResponse> {
    return axios.post('v1/collection', pCollection);
};

function update(pCollection: CollectionData): Promise<AxiosResponse> {
    return axios.put('v1/collection/' + pCollection.id, pCollection);
};

function remove(pCollection: CollectionData): Promise<AxiosResponse> {
    return axios.delete('v1/collection/' + pCollection.id);
};

function scan(pCollection: CollectionData): Promise<AxiosResponse> {
    return axios.put('v1/collection/scan/' + pCollection.id, {});
};
function sort(pCollection: CollectionData): Promise<AxiosResponse> {
    return axios.put('v1/collection/sort/' + pCollection.id, {});
};
function importitemmeta(pCollection: CollectionData): Promise<AxiosResponse> {
    return axios.put('v1/collection/meta/import/' + pCollection.id, {});
};
function exportitemmeta(pCollection: CollectionData): Promise<AxiosResponse> {
    return axios.put('v1/collection/meta/export/' + pCollection.id, {});
};
export default {

    /**
     * Save a collection
     */
    async save(pCollection: CollectionData): Promise<CollectionData> {

        if (pCollection.id == undefined) {
            return (await create(pCollection)).data;
        } else {
            return (await update(pCollection)).data;
        }
    },

    /**
     * Delete a collection
     */
    async delete(pCollection: CollectionData): Promise<any> {

        if (pCollection.id == undefined) {
            return;
        } else {
            return (await remove(pCollection)).data;
        }
    },

    /**
     * Scan a collection for new items
     */
    async scan(pCollection: CollectionData): Promise<any> {
        if (pCollection.id == undefined) {
            return;
        }

        return (await scan(pCollection)).data;
    },

    /**
     * Sort a collection
     */
        async sort(pCollection: CollectionData): Promise<any> {
            if (pCollection.id == undefined) {
                return;
            }
    
            return (await sort(pCollection)).data;
        },

    /**
     * Import metadata for item in collection
     */
    async importitemmeta(pCollection: CollectionData): Promise<any> {
        if (pCollection.id == undefined) {
            return;
        }

        return (await importitemmeta(pCollection)).data;
    },

    /**
     * Export metadata to items in collection
     */
    async exportitemmeta(pCollection: CollectionData): Promise<any> {
        if (pCollection.id == undefined) {
            return;
        }

        return (await exportitemmeta(pCollection)).data;
    },

    /**
     * Import items from a collection to a managed collection
     */
    async import(pSource: CollectionData, pDestination: CollectionData): Promise<any> {
        if (pSource.id == undefined || pDestination.id == undefined) {
            return;
        }

        return (await axios.put('v1/collection/import/' + pSource.id + "/" + pDestination.id, {}));
    },

    async transfer(pDestination: CollectionData, pItemIDs: Array<string>): Promise<any> {
        if (pDestination.id == undefined) {
            return;
        }

        return (await axios.put('v1/collection/transfer/' + pDestination.id, {"items": pItemIDs}));
    },

    /**
     * 
     */
    async export(pCollection: CollectionData): Promise<any> {

        if (pCollection.id != undefined) {
            return (await axios.get('v1/export/collection/' + pCollection.id,
                { responseType: 'blob' })
            );
        }

        return;
    },

}