<script setup lang="ts">
import { ThumbnailSize, cItem } from '../domain/item';
import { ref } from 'vue';
import Image from './Image.vue';
import { watch } from 'vue';
import { useSettingsStore } from '../stores/settings';

const props = defineProps({
  item: {
    type: cItem,
    required: true,
    default: new cItem()
  },
  thumbnail: {
    type: String,
    default: ThumbnailSize.Small
  },
  height: {
    type: String,
    default: "auto"
  },
  cover: {
    type: Boolean,
    default: false
  },
  shared: {
    type: String,
    default: "",
    required: false
  },
  options: {
    type: Object,
    default() {
      return {};
    }
  }
});

const emit = defineEmits<{
  (e: 'video-ended'): void

}>();

const settings = useSettingsStore();

const videoError = ref(false);
const videoErrorMessage = ref('');
const videoSource = ref([{ "src": "", "type": "video/mp4" }]);
const player = ref<null | any>(null);

/**
 * End of video
 */
function videoEnded() {
  emit('video-ended');
}

/**
 * Event: Full screen
 */
function videoFullScreen() {
  if (player.value.isFullscreen_) {
    settings.setFullscreen(true);
  } else {
    settings.setFullscreen(false);
  }
}

/**
 * Set 'player' to the video player
 */
function setPlayerInstance(pPlayer) {

  player.value = pPlayer.player;
}

function handleVideoError(event: Event) {
  // Accessing the error details
  videoError.value = true;

  const error = (event.target as HTMLVideoElement).error;
  if (error) {
    switch (error.code) {
      case error.MEDIA_ERR_ABORTED:
        videoErrorMessage.value = 'The video playback was aborted.';
        break;
      case error.MEDIA_ERR_NETWORK:
        videoErrorMessage.value = 'A network error caused the video download to fail.';
        break;
      case error.MEDIA_ERR_DECODE:
        videoErrorMessage.value = 'The video playback was aborted due to a corruption problem or because the video used features your browser did not support.';
        break;
      case error.MEDIA_ERR_SRC_NOT_SUPPORTED:
        videoErrorMessage.value = 'The video could not be loaded, either because the server or network failed or because the format is not supported.';
        break;
      default:
        videoErrorMessage.value = 'An unknown error occurred.';
        break;
    }
  }

  videoEnded();
}

watch(props, async function () {
  videoError.value = false;

  if (player.value != null) {
    if (props.item.getId() == 0) {
      videoSource.value[0].src = "";
      return;
    }
  }

  videoSource.value[0].src = props.item.getVideoUrl(props.shared);

}, { immediate: true })

</script>

<template>
  <template v-if="videoError == true">
    <Image :item="item" :height="height" :cover="cover" :thumbnail="thumbnail">
      <template v-slot:header>
        <slot name="header"></slot>
      </template>
    </Image>

  </template>
  <template v-else>
    <div v-if="item.getId() > 0">
      <video-player :sources="videoSource" :poster="item.getImageUrl(ThumbnailSize.Large, '', shared)" :autoplay="true"
        controls :volume="1" liveui :control-bar="{
          progressControl: true,
          currentTimeDisplay: true,
          remainingTimeDisplay: true
        }" @click.stop="" @ended="videoEnded" @error="handleVideoError" @abort="handleVideoError"
        @mounted="setPlayerInstance" @fullscreenchange="videoFullScreen">

      </video-player>

    </div>
  </template>
  <slot name="header"></slot>
</template>

<style scoped>
.video-js {
  position: initial !important
}

.vjs-control-bar {
  bottom: 8em !important;
}
</style>