import { PersonInterface, PersonData, PersonNameData } from "rundown-common";
import { usePeopleStore } from "../stores/people";

/**
 * Representation of a person
 */
export class cPerson implements PersonInterface {
    person: PersonData;
    names: Array<PersonNameData> = [];

    constructor(pPerson: PersonData = { 'id': 0 } as PersonData) {
        this.person = pPerson;

        this.loadFrom(pPerson);
    }

    loadFrom = (pPersonData:PersonData): void => {
        this.person = pPersonData;
        if(this.person.names) {
            this.person.names.forEach((val) => {
                this.names.push(val);
            })
        }
    }

    getId = () => {
        return this.person.id;
    }

    getNames = (): PersonNameData[] => {
        return this.names;
    }

    /**
     * Get a persons name at a date
     */
    getNameAtDate = (pDate: Date = new Date()): PersonNameData => {
        if (!this.person?.names?.length) {
            return { id: 0, person: 0, name: 'No name available' } as PersonNameData;
        }
    
        let found = this.person.names[this.person.names.length - 1];
    
        for (const name of this.person.names) {
            const start = new Date(name.active_from);
            const end = new Date(name.active_to);
    
            if (pDate >= start && (pDate <= end || !name.active_to)) {
                found = name;
                break;
            }
        }
    
        return found; 
    }

    /**
     * Get the persons current name
     */
    getCurrentName = (): PersonNameData => {
        
        return this.getNameAtDate();
    }

    getSearchName = (): string => {
        const name = this.getCurrentName();
        const year = this.getBirthYear();
        if(year.length)
            return name.name + ` (${year})`;

        return name.name;
    }

    getBirthYear = (): string => {
        if(this.person.birthdate.length == 0)
            return '';

        const date = new Date(this.person.birthdate);
        return date.getFullYear().toString();
    }

    /**
     * 
     */
    createName = async (pNameData: PersonNameData): Promise<void> => {
        const people = usePeopleStore();
        const name = await people.createName(this, pNameData);
        this.names.push(name);
    }

    /**
     * 
     */
    updateName = async (pNameData: PersonNameData): Promise<void> => {
        const people = usePeopleStore();
        await people.updateName(this, pNameData);
    }

    /**
     * 
     */
    deleteName = async (pNameData: PersonNameData): Promise<void> => {
        const people = usePeopleStore();

        if(this.names.length <= 1)
            return;

        await people.deleteName(this, pNameData);

        
        this.names = this.names.filter(function (e) {
            return e.id !== pNameData.id;
        });
    }

    /**
     * 
     */
    save = async (): Promise<void> => {
        const people = usePeopleStore();
        await people.updatePerson(this.person);
    }
}
