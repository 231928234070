<script setup lang="ts">
import { ref } from 'vue';
import { usePeopleStore } from '../../stores/people';
import { cItem } from '../../domain/item';
import { nextTick } from 'vue';

const props = defineProps({
    item: {
        type: cItem,
        required: true
    }
});

const visible = ref(false);
const clientX = ref(0);
const clientY = ref(0);
const imageX = ref(0);
const imageY = ref(0);

const style = ref<any>({});
const name = ref('');

const nameSet = (async () => {
    const object = props.item.getPersonByPosition(imageX.value, imageY.value);
    if (!object) {
        visible.value = false;
        await nextTick();
        return;
    }

    name.value = object.getPerson().getCurrentName().name;
    visible.value = true;
});

const positionSet = (pX: number, pY: number, pImageX: number, pImageY: number) => {
    nameSet();
    imageX.value = pImageX;
    imageY.value = pImageY;
    clientX.value = pX;
    clientY.value = pY;

    style.value = {
            zIndex: `10000`,
            position: `absolute`,
            top: `${clientY.value}px`,
            left: `${clientX.value}px`
        };
}

defineExpose({ positionSet });

</script>
<template>
    <v-tooltip v-model="visible" :style="style">
        {{ name }}
    </v-tooltip>
</template>