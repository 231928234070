import { ItemData, ItemPageData } from 'rundown-common';
import Pagination, { PageLinks, PageMetaData } from '../services/Pagination';
import { ArrayMap } from './arraymap';
import { cItem } from '../domain/item';

import { useAuthStore } from '../stores/auth';
import { useFilterStore } from '../stores/filters';
import { useMessageStore } from '../stores/messages';

import axios from 'axios';
import { Ref, ref } from 'vue';

export type ItemMap = ArrayMap<number, cItem>;

/**
 * Low level management of item pages
 */
export class ItemPageManager {

    private pageData = {
        _meta: { total_records: 0, limit: 10 } as PageMetaData,
        _links: [] as PageLinks,
        items: [] as Array<ItemData>
    } as ItemPageData;

    private collectionId = ref(-1);
    private totalPages = ref(0);
    private totalRecords = ref(0);
    private resultsPerPage = ref(0);
    private reloadCounter = ref(0);

    private pages: Ref<Map<number, ItemMap>> = ref(new Map());
    private loading: Ref<boolean> = ref(false);
    private missing: boolean = false;

    private reloadTimer: number | null = null;

    constructor(pMissing: boolean = false) {
        this.missing = pMissing;
    }

    isLoading(): boolean {
        return this.loading.value;
    }

    getReloadCounter(): number {
        return this.reloadCounter.value;
    }

    /**
     * Get the total number of pages
     */
    private getTotalPages(): number {
        return this.totalPages.value;
    }

    /**
     * Get the number of results per page
     */
    private getResultsPerPage(): number {
        return this.resultsPerPage.value;
    }

    /**
     * Get the total number of records
     */
    getTotalRecords(): number {
        return this.totalRecords.value;
    }

    getCollectionId(): number {
        return this.collectionId.value;
    }

    /**
     * Get a range of items starting at pIndex
     */
    async getRange(pIndex: number, pCount: number) {
        const target: Array<cItem> = [];
        const itemsPerPage = this.getResultsPerPage();
        const totalPages = this.getTotalPages();

        // Calculate the start and end page numbers
        let startPage = Math.ceil((pIndex + 1) / itemsPerPage);
        let endPage = Math.min(totalPages, Math.ceil((pIndex + pCount) / itemsPerPage));

        //pTarget.splice(0, pTarget.length);

        // Remaining items to fetch
        let remainingItems = pCount;

        for (let page = startPage; page <= endPage; page++) {
            const pageItems = await this.getPage(page);
            if (!pageItems) continue; // Skip if the page is not available

            // Calculate start index for the current page
            let startIndex = page === startPage ? (pIndex) % itemsPerPage : 0;

            // Calculate end index for the current page
            let endIndex = Math.min(startIndex + remainingItems - 1, itemsPerPage - 1);

            // Add items from the current page to the range
            for (let i = startIndex; i <= endIndex; i++) {
                const item = pageItems.getByIndex(i);
                if (item) target.push(item);
            }

            // Update remaining items to fetch
            remainingItems -= (endIndex - startIndex + 1);
        }
        return target;
    }

    /**
     * Get a specific page
     */
    private async getPage(pNumber: number): Promise<ItemMap | undefined> {
        if (pNumber < 1 || pNumber > this.getTotalPages()) {
            return undefined;
        }
        await this.loadPage(pNumber);
        return this.pages.value.get(pNumber) ?? new ArrayMap<number, cItem>();
    }

    /**
     * Load pages in front and behind the current page
     */
    private async loadPages(pPageNumber: number): Promise<void> {
        if (pPageNumber < 1 || pPageNumber > this.getTotalPages()) {
            return undefined; // Prevent setting an invalid page number
        }

        await this.loadPage(pPageNumber);

        const totalPages = this.getTotalPages();
        const prevPage = pPageNumber > 1 ? pPageNumber - 1 : totalPages;
        const nextPage = pPageNumber < totalPages ? pPageNumber + 1 : 1;
        await Promise.all([this.loadPage(prevPage), this.loadPage(nextPage)]);
    }

    /**
     * Load the first page/setup the paging info
     */
    async load() {
        const filters = useFilterStore();
        const messages = useMessageStore();


        let url = '/v1/item/';
        let query = `list/${filters.getCollectionId}?${filters.getParameters}`;

        try {
            if (this.missing) {
                query = `missing/${filters.getCollectionId}`;
            }

            this.loading.value = true;
            this.collectionId.value = filters.getCollectionId;
        
            const response = await axios.get(url + query);
            this.pages.value.clear();
            this.pages.value.set(1, this.processPageData(response.data));

            this.reloadCounter.value++;
            await this.loadPages(1);
        } catch (error) {
            messages.handleAxiosError(error);
        } finally {
            this.loading.value = false;
        }
    }

    /**
     * Load a page
     */
    private async loadPage(pageNumber: number): Promise<void> {
        if (pageNumber < 1 || pageNumber > this.getTotalPages() || this.pages.value.has(pageNumber)) {
            return;
        }

        this.loading.value = true;
        try {
            const response = await Pagination.getPage(this.pageData._links, pageNumber);
            this.pages.value.set(pageNumber, this.processPageData(response.data));
        } catch (error) {
            console.error(`Error loading page ${pageNumber}:`, error);
        } finally {
            this.loading.value = false;
        }
    }

    /**
     * Turn each in a page of results into a cItem and return a new ArrayMap containing them
     */
    private processPageData(pPageData: ItemPageData): ArrayMap<number, cItem> {
        var items = new ArrayMap<number, cItem>();

        this.pageData = pPageData;

        this.totalRecords.value = this.pageData._meta.total_records;
        this.totalPages.value = Math.ceil(this.pageData._meta.total_records / (this.pageData._meta.limit ?? 1));
        this.resultsPerPage.value = this.pageData._meta.limit ?? 0;

        this.pageData.items.forEach((entry: ItemData) => {
            var item = new cItem(entry);
            items.set(item.getId(), item);
        });

        return items;
    }

    /**
     * Reload all loaded pages
     */
    async reload(): Promise<void> {

        if (this.reloadTimer) {
            clearTimeout(this.reloadTimer);
        }

        this.reloadTimer = setTimeout(async () => {
            const loadedPages = Array.from(this.pages.value.keys());
            for (const pageNumber of loadedPages) {
                this.pages.value.delete(pageNumber);
                await this.loadPage(pageNumber);
            }
        }, 20000);
    }

    /**
     * Get a loaded item
     */
    getItem(pItemID: number): cItem | undefined {
        const page = this.getPageWithItem(pItemID);
        return page?.getByKey(pItemID);
    }

    /**
     * Remove a loaded item
     */
    removeItem(pItemID: number): void {
        const page = this.getPageWithItem(pItemID);
        page?.delete(pItemID);
        ++this.reloadCounter.value;
    }

    /**
     * Get the page number that contains this itemid
     */
    getPageNumberWithItem(pItemID: number): number | undefined {
        for (const [key, page] of this.pages.value.entries()) {
            if (page.hasKey(pItemID)) {
                return key;
            }
        }
        return undefined;
    }


    /**
     * Get the page with this item
     */
    private getPageWithItem(pItemID: number): ItemMap | undefined {

        for (const pPage of this.pages.value.values()) {

            if (pPage.hasKey(pItemID))
                return pPage as ItemMap;
        }

        return undefined;
    }

    /**
     * Get an item by its global index
     */
    async getItemByIndex(globalIndex: number): Promise<cItem | undefined> {
        const itemsPerPage = this.getResultsPerPage();
        const pageNumber = Math.ceil((globalIndex + 1) / itemsPerPage);
        const page = await this.getPage(pageNumber);

        if (!page) {
            return undefined;
        }

        const indexInPage = globalIndex % itemsPerPage;
        return page.getByIndex(indexInPage);
    }

    /**
     * Get the global index of a cItem
     */
    getIndexOfItem(targetItem: cItem): number {
        return this.getIndexOfItemId(targetItem.getId());
    }

    /**
     * Get the global index of an item by its id
     */
    getIndexOfItemId = (targetItem: number): number => {
        const itemsPerPage = this.getResultsPerPage();

        for (const [pageNumber, page] of this.pages.value.entries()) {
            if (page.hasKey(targetItem)) {
                const indexInPage = page.getIndexByKey(targetItem);
                if (indexInPage === undefined)
                    break;

                return ((pageNumber - 1) * itemsPerPage) + indexInPage;
            }
        }

        return -1;
    }
}
