<script setup lang="ts">
import { PropType, ref } from 'vue';
import { cItem } from '../domain/item';
import Image from './Image.vue';
import VideoPreview from './VideoPreview.vue';
import VideoPlayer from './VideoPlayer.vue';
import ItemMenu from '../menu/ContextMenuItem.vue';
import ItemObjectHover from './item/ItemObjectHover.vue';

const props = defineProps({
    item: {
        type: Object as PropType<cItem>,
        default: new cItem(),
        required: true
    },
    shared: {
        type: String,
        default: "",
        required: false
    },
    imageHeight: {
        type: Number,
        default: 150
    },
    imageThumb: {
        type: String,
        default: "medium"
    },
    minHeight: {
        type: Number,
        default: 365
    },
    vIntersect: {
        type: String,
        default: ""
    },
    cover: {
        type: Boolean,
        default: true
    },
    canZoom: {
        type: Boolean,
        default: false
    },
    isDialog: {
        type: Boolean,
        default: false
    },
    isHovering: {
        type: Boolean,
        default: false
    }
});

const imageObjectHover = ref<InstanceType<typeof ItemObjectHover>|null>(null);

const ContextMenuVisible = ref(false);
const menuX = ref(0);
const menuY = ref(0);
const emit = defineEmits<{
    (e: 'select-item', pItem: cItem): void
    (e: 'video-ended'): void
}>();

function videoEnded() {
    emit('video-ended');
}

function imagePointerMove(pPointerEvent: any, pX: number, pY: number) {
    imageObjectHover.value?.positionSet(pPointerEvent.clientX, pPointerEvent.clientY, pX, pY);
}

/**
 * Show the context menu
 */
 function menuShow(pEvent: MouseEvent) {
    menuX.value = pEvent.clientX;
    menuY.value = pEvent.clientY;
    ContextMenuVisible.value = true
}

/**
 * Hide the context menu
 */
function menuHide(pEvent: Event|undefined) {
    if(ContextMenuVisible.value && pEvent)
        pEvent.stopPropagation();

    ContextMenuVisible.value = false;
}

</script>

<template>
    
    <v-card :v-intersect="props.vIntersect"  @contextmenu.prevent="menuShow"  @click.capture="menuHide">
        <ItemMenu :item="item" :show="ContextMenuVisible" @menu-close="menuHide" :positionX="menuX" :positionY="menuY"></ItemMenu>

        <template v-if="item.isImage()">
            <Image :can-zoom="canZoom" :item="item" :shared="shared" :thumbnail="imageThumb" :height="imageHeight.toString()" :cover="cover" @pointer-move="imagePointerMove">
                
                <template v-slot:header>
                    <slot></slot>
                </template>

                <template v-slot:overlay>
                    <ItemObjectHover  v-if="isDialog==true" ref="imageObjectHover" :item="item"/>
                </template>
            </Image>
        </template>
        <template v-else>
            <template v-if="isDialog == false">
                <VideoPreview :item="item" :shared="shared" :thumbnail="imageThumb" :height="imageHeight.toString()" :cover="cover"
                    @video-ended="videoEnded">
                    <template v-slot:header>
                        <slot></slot>
                    </template>
                </VideoPreview>
            </template>
            <template v-else>

                <VideoPlayer :item="item" :shared="shared" :thumbnail="imageThumb" :height="imageHeight.toString()" :cover="cover"
                    @video-ended="videoEnded">
                    <template v-slot:header>
                        <slot></slot>
                    </template>
                </VideoPlayer>
            </template>
        </template>

    </v-card>
</template>

<style scoped>
img {
    object-position: top;
}

video {
    object-position: top;
}
</style>