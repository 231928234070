<script setup lang="ts">
import mapboxgl, { LngLat } from 'mapbox-gl';
import { ref } from 'vue';
import { useFilterStore, eRadiusMode } from '../../stores/filters';
import { useSettingsStore } from '../../stores/settings';
import { useGeoJsonStore } from '../../stores/geojson';
import ItemMap from '../../components/map/ItemMap.vue';

const filters = useFilterStore();
const settings = useSettingsStore();
const geojson = useGeoJsonStore();

var mapBox = null as mapboxgl.Map | null;

var displayLocationMenu = ref(false);
var markerSelectedLocation = ref(null as null | mapboxgl.Marker);

var filterRangeMode = ref(filters.getGeoRadiusMode);
var filterRange = ref(filters.getGeoRadiusRange[1]);

//https://qa.wujigu.com/qa/?qa=774045/drawing-a-circle-with-the-radius-in-miles-meters-with-mapbox-gl-js
function createGeoJSONCircle(pCenter: LngLat, pRadiusKm: number) {
    var points = 64;

    var ret = [];
    var distanceX = pRadiusKm / (111.320 * Math.cos(pCenter.lat * Math.PI / 180));
    var distanceY = pRadiusKm / 110.574;

    var theta, x, y;
    for (var i = 0; i < points; i++) {
        theta = (i / points) * (2 * Math.PI);
        x = distanceX * Math.cos(theta);
        y = distanceY * Math.sin(theta);

        ret.push([pCenter.lng + x, pCenter.lat + y]);
    }
    ret.push(ret[0]);

    return {
        "type": "FeatureCollection",
        "features": [{
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [ret]
            }
        }]
    };
};

/**
 * Dialog: Map Opened
 */
function openMapWindow() {
    settings.hideSidebar();

    if (markerSelectedLocation.value) {
        markerSelectedLocation.value.remove();
        markerSelectedLocation.value = null;
    }

    displayLocationMenu.value = true;

    filterRange.value = filters.getGeoRadiusRange[1];
    filterRangeMode.value = filters.getGeoRadiusMode;
}

/**
 * Dialog: Map Closed
 */
function closeMapWindow() {
    displayLocationMenu.value = false;

    if (markerSelectedLocation.value) {

        filters.setGeoLocation(markerSelectedLocation.value.getLngLat());
        filters.setGeoRadius([0, filterRange.value]);
        filters.setGeoRadiusMode(filterRangeMode.value);

        markerSelectedLocation.value.remove();
        markerSelectedLocation.value = null;
    }
}

/**
 * Event: Range Change
 */
function filterRangeUpdate() {
    markerUpdateRadius();
}

/**
 * Get the range in kilometers
 */
function filterRangeGetKilometers() {
    var range = filterRange.value;
    if (filterRangeMode.value == eRadiusMode.Meters) {
        range /= 1000;
    }
    return range;
}

/**
 * Update the marker radius circle
 */
function markerUpdateRadius() {
    if (mapBox && markerSelectedLocation.value) {
        // Zoom0  = 78271 meters per pixel
        // Zoom10 = 76.437 meters per pixel
        // Zoom22 = 0.019 meters per pixel
        var source = mapBox.getSource('marker-radius');
        if (source) {
            source.setData(
                createGeoJSONCircle(markerSelectedLocation.value.getLngLat(), filterRangeGetKilometers()));
        }
    }
}

/**
 * Setup the selected-location marker
 */
function markerSelectedSet(pLngLat: LngLat) {

    if (mapBox) {
        if (markerSelectedLocation.value == null) {
            markerSelectedLocation.value = new mapboxgl.Marker()
                .setLngLat(pLngLat)
                .addTo(mapBox);
        }

        markerSelectedLocation.value.setLngLat(pLngLat);

        markerUpdateRadius();
    }

}

/**
 * Event: Map Loaded
 */
function mapLoaded(pMapBox: mapboxgl.Map) {
    mapBox = pMapBox;

    mapBox.addSource('marker-radius', {
        "type": "geojson",
        "data": {} as mapboxgl.MapboxGeoJSONFeature,
        cluster: false
    });

    mapBox.addLayer({
        'id': 'marker-radius-circles',
        "type": "fill",
        'source': 'marker-radius',

        "layout": {},
        "paint": {
            "fill-color": "blue",
            "fill-opacity": 0.6
        }
    });

    /**
     * Event: Map left click on a cluster
     */
    mapBox.on('click', 'clusters', (e) => {
        if (!mapBox)
            return;
        const features = mapBox.queryRenderedFeatures(e.point, {
            layers: ['clusters']
        });

        e.preventDefault();
        markerSelectedSet(features[0].geometry.coordinates);
    });

    /**
     * Event: Map Left Click
     */
    mapBox.on('click', (e) => {
        if (e.defaultPrevented)
            return;

        markerSelectedSet(e.lngLat);
    });

    // Zoom to a location if one is selected
    if (filters.hasGeoLocation) {
        markerSelectedSet(filters.getGeoLocation);
        mapBox.setCenter(filters.getGeoLocation);
        if (filters.geoRadiusMode == eRadiusMode.Meters)
            mapBox.setZoom(14);
        else
            mapBox.setZoom(4);
    }
}

function mapGeoSearchResult(pLocation: any) {
    markerSelectedSet({ lat: pLocation.geometry.coordinates[1], lng: pLocation.geometry.coordinates[0] } as LngLat);
}

function dialogSize() {
    if (settings.screenSmall)
        return 'width:350px;height:50vw';

    return 'width:50vw;height:50vh';
}
</script>

<template>
    <v-btn @click="openMapWindow">
        <v-icon icon="mdi-crosshairs-gps"></v-icon>
        <v-tooltip activator="parent" location="bottom">World Map</v-tooltip>&nbsp;Show Map
    </v-btn>

    <v-dialog v-model="displayLocationMenu" :fullscreen="settings.isScreenSmall" min-height="500px">

        <v-card>

            <v-list>
                <v-list-item prepend-icon="mdi-crosshairs-gps" title="Location" subtitle="Filters"></v-list-item>
            </v-list>
            <v-card-text>

                <ItemMap :style="dialogSize()" :mapLoadedCallback="mapLoaded" :mapSearchCallback="mapGeoSearchResult">
                </ItemMap>

                <v-progress-linear v-show="geojson.isLoading == true" v-model="geojson.getFeatureLoadProgress"
                    height="25">
                    <strong>Loading {{ Math.ceil(geojson.getFeatureLoadProgress) }}%</strong>
                </v-progress-linear>

            </v-card-text>
            <v-card-actions>
                <v-col>
                    <v-switch v-model="filterRangeMode" @update:modelValue="filterRangeUpdate" hide-details
                        :true-value="eRadiusMode.Kilometers" :false-value="eRadiusMode.Meters" :label="filterRangeMode"
                        density="compact"></v-switch>

                </v-col>
                <v-col>
                    <label class="v-label" for="switch-62">Radius</label>

                    <v-row>
                        <v-slider v-model="filterRange" @update:modelValue="filterRangeUpdate" :min="0.1" :max="1000"
                            thumb-label="always" density="compact">
                        </v-slider>
                    </v-row>
                </v-col>
                <v-spacer></v-spacer>
                <v-btn text @click="displayLocationMenu = false">
                    Cancel
                </v-btn>
                <v-btn color="primary" text @click="closeMapWindow">
                    Done
                </v-btn>
            </v-card-actions>
        </v-card>

    </v-dialog>
</template>
