<script setup lang="ts">

import { ref, watch, PropType } from 'vue';
import { cItem, ThumbnailSize } from '../domain/item';
import { useMessageStore } from '../stores/messages';
import { useRouter } from 'vue-router'
import { useItemStore } from '../stores/items';
import { useItemSlidingWindowStore } from '../stores/itemSlidingWindow';

const messages = useMessageStore();
const menuShow = ref(false);
const items = useItemStore();
const itemWindow = useItemSlidingWindowStore();

const props = defineProps({
    item: {
        type: Object as PropType<cItem>,
        default: new cItem(),
        required: false
    },
    show: {
        type: Boolean,
        default: false,
        required: true
    },
    positionX: {
        type: Number,
        required: true
    },
    positionY: {
        type: Number,
        required: true
    }
});

const emit = defineEmits<{
    (e: 'menu-close'): void
}>();
const router = useRouter();

/**
 * Open image in new tab
 */
async function openImage() {
    var blob = await props.item.getImageBlob(ThumbnailSize.Variant);
    var fileURL = URL.createObjectURL(blob);

    window.open(fileURL, '_blank')?.focus();
}

/**
 * Copy image to clipboard
 */
async function copyImage(pThumbnailSize: ThumbnailSize) {
    if (props.item.getId() == 0)
        return false;

    var imageBlob = await props.item.getImageBlob(pThumbnailSize, 'png');
    if (imageBlob === false) {
        messages.add("Image not found");
        return false;
    }

    await navigator.clipboard.write([
        new ClipboardItem({ [imageBlob.type]: imageBlob }),
    ]);
    return true;
}

/**
 * Copy image to clip board
 */
async function copyImageToClipboard(pSize: ThumbnailSize) {

    if (await copyImage(pSize)) {
        messages.add('Image copied to clipboard');
    }
}

/**
 * 
 * @param pHours 
 */
async function share(pHours: number) {
    if (!props.item.getId())
        return;

    var result = await props.item.share(pHours);
    if (result == null) {
        messages.add('Failed to create share');
        return;
    }

    var route = router.resolve({ name: 'shared', params: { 'name': result.name } });
    const absolute = new URL(route.href, window.location.origin).href;

    navigator.clipboard.writeText(absolute);

    messages.add('Share link copied to clipboard');
}

function zoomToItem() {
    router.push({ name: 'World Map', params: { 'itemid': props.item.getId()}  })
}

function editMetaData() {
    itemWindow.changeToWindowWithItem(props.item.getId());
    router.push({ name: 'Item Metadata' })
}

watch(props, async function () {
    menuShow.value = props.show;
});

const menuClosed = () => {
    emit('menu-close');
};
</script>

<template>
    <v-menu origin="auto" :target="[positionX, positionY]" v-model="menuShow" @update:model-value="menuClosed" style="opacity: 0.85;">
        <v-list>
            <v-list-item prepend-icon="mdi-tag" @click.stop="editMetaData">
                <v-list-item-title>Edit Metadata</v-list-item-title>
            </v-list-item>

            <v-divider></v-divider>

            <v-list-item prepend-icon="mdi-map-marker" @click.stop="zoomToItem">
                <v-list-item-title>Locate on Map</v-list-item-title>
            </v-list-item>

            <v-divider></v-divider>

            <v-menu open-on-hover location="end">
                <template #activator="{ props }">
                    <v-list-item prepend-icon="mdi-file-image" v-bind="props" color="primary" @click.stop>
                        <v-list-item-title>Copy to clipboard</v-list-item-title>
                    </v-list-item>
                </template>

                <v-list>
                    <v-list-item @click="copyImageToClipboard(ThumbnailSize.Tiny)">
                        <v-list-item-title>Tiny</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="copyImageToClipboard(ThumbnailSize.Small)">
                        <v-list-item-title>Small</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="copyImageToClipboard(ThumbnailSize.Medium)">
                        <v-list-item-title>Medium</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="copyImageToClipboard(ThumbnailSize.Large)">
                        <v-list-item-title>Large</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="copyImageToClipboard(ThumbnailSize.Variant)">
                        <v-list-item-title>Variant</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>


            <v-divider></v-divider>

            <v-list-item prepend-icon="mdi-file-image" @click="openImage">
                <v-list-item-title>Open original</v-list-item-title>
            </v-list-item>

            <v-divider></v-divider>


            <v-menu open-on-hover>
                <template #activator="{ props }">
                    <v-list-item title="Share" prepend-icon="mdi-share" v-bind="props" color="primary" @click.stop />
                </template>
                <v-list>
                    <v-menu>
                        <template #activator="{ props }">
                            <v-list-item title="24 Hours" prepend-icon="mdi-clock" v-bind="props" color="primary"
                                @click="share(24)" />
                            <v-list-item title="48 Hours" prepend-icon="mdi-clock" v-bind="props" color="primary"
                                @click="share(48)" />
                            <v-list-item title="A week" prepend-icon="mdi-clock" v-bind="props" color="primary"
                                @click="share(24 * 7)" />
                        </template>
                    </v-menu>
                </v-list>
            </v-menu>

        </v-list>
    </v-menu>
</template>