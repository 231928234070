<script setup lang="ts">
import { useSettingsStore } from '../stores/settings';
import { useHelpStore } from '../stores/help';

import itemPanelScroll from './itempanelscroll.vue';

import itemPanel from './itempanel.vue';
import helpNoCollections from '../components/help/nocollections.vue';
import helpNoitemsSignin from '../components/help/noitems-signin.vue';
import { computed } from 'vue';

const settings = useSettingsStore();
const help = useHelpStore();

const scrollmode = computed(()=> settings.isScreenSmall == true || settings.isHomeViewModePaged == false);

</script>

<template>
    <template v-if="help.displaySignIn == true">
        <help-noitems-signin>
        </help-noitems-signin>
    </template>

    <template v-else-if="help.displayCreate == true">
        <help-no-collections>
        </help-no-collections>
    </template>

    <template v-if="scrollmode == false">
        <itemPanel></itemPanel>
    </template>
    <template v-else>
        <itemPanelScroll></itemPanelScroll>
    </template>

</template>
