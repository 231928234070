import { Centrifuge, PublicationContext, PublishResult, Subscription } from 'centrifuge';
import { defineStore } from 'pinia'
import { useAuthStore } from './auth';
import { ref } from 'vue';
import { CollectionData, ItemData, ObjectData, PersonData, UserData } from 'rundown-common';
import { getConfig } from '../config';
import { RPCCallInterface, RPCResponseInterface, WebSocketStoreInterface, tWebSocketCallback } from 'rundown-common';

export enum EventType {
    Error = 'error',
    Notice = 'notice',
    Status = 'status',
}

export enum ActionInterface {
    Create = 'create',
    Update = 'update',
    Delete = 'delete',
    Complete = 'complete'
}

export interface iEventSlideshow {
    fileid: string;
}

interface iEventLogWhen {
    date: string,
    timezone: string
}

export interface iEventLog {
    id: number;
    level: EventType;
    when: iEventLogWhen;
    job: string;
    taskid: number;
    message: string;
    collection?: CollectionData;
    item?: ItemData;
    user?: string;
    context: object | iEventSlideshow;
}

const data = ref({ token: "" });

export const useWebSocketStore = defineStore("websocket", (): WebSocketStoreInterface => {
    const channels = ref({} as Record<string, Subscription>);
    const centrifuge = ref({} as Centrifuge);
    const pendingJobs = ref({} as Record<string, (data: any) => void>);

    async function connect(): Promise<boolean> {
        const auth = useAuthStore();

        var path = new URL((await getConfig()).websocket + '/connection/websocket');

        switch (path.protocol) {
            default:
                break;
            case 'http:':
                path.protocol = 'ws';
                if (path.port === '')
                    path.port = '80';
                break;
            case 'https:':
                path.protocol = 'wss';
                path.port = '443';
                break;
        }

        if (!auth.isAuthed)
            return false;

        data.value.token = auth.getToken;

        centrifuge.value = new Centrifuge(path.toString(),
            {
                data: data
            });

        centrifuge.value.on('error', function (ctx) {
            if (auth.isAuthed == false) {
                useWebSocketStore().disconnect();
                return;
            }
            data.value.token = auth.getToken;
        });

        centrifuge.value.connect();
        await joinPrivate('RPC', rpcCallback);
        return true;
    }

    function disconnect() {
        centrifuge.value.disconnect();
        channels.value = {} as Record<string, Subscription>;
    }

    async function joinPrivate(pChannel: string, pCallback: tWebSocketCallback) {
        if (centrifuge.value.state == undefined) {
            await connect();
        }
        const auth = useAuthStore();
        try {
            var chan = centrifuge.value.newSubscription(pChannel + '#' + auth.getTokenContent.sub);
            chan.on('publication', pCallback).subscribe();
        } catch(e) {
            console.log(e);
        }
    }

    async function joinChannel(pChannel: string, pCallback: tWebSocketCallback) {
        if (centrifuge.value.state == undefined) {
            if(await connect() == false) {
                return;
            }
        }
        if (channels.value[pChannel] === undefined) {
            channels.value[pChannel] = centrifuge.value.newSubscription(pChannel);
            channels.value[pChannel].on('publication', pCallback).subscribe();
        }
    }

    async function publish(pChannel: string, pData: any): Promise<PublishResult> {
        if (channels.value[pChannel] === undefined) {
            throw new Error("Not subscribed");
        }

        return channels.value[pChannel].publish(pData);
    }

    function rpcCallback(ctx: PublicationContext) {
        const response: RPCResponseInterface = ctx.data;
        if (pendingJobs.value[response.id]) {
            pendingJobs.value[response.id](ctx.data);
            delete pendingJobs.value[response.id];
        }
    }

    async function rpc(pPlugin: string, pMethod: string, pData: object): Promise<any> {
        if (centrifuge.value.state == undefined) {
            await connect();
        }
        const data: RPCCallInterface = {
            module: pPlugin,
            method: pMethod,
            data: pData
        }

        const response: RPCResponseInterface = (await centrifuge.value.rpc('plugin', JSON.stringify(data))).data;
        if(response.job) {
            // Return a promise that resolves when the job is completed
            return new Promise((resolve, reject) => {
                // Store the resolve function in pendingJobs with the job ID
                pendingJobs.value[response.id] = resolve;
                // Optionally, handle job timeout or rejection here
            });
        } else {
            // No job, return response immediately
            return response;
        }
    }

    return { channels, centrifuge, connect, disconnect, joinPrivate, joinChannel, publish, rpc};
});
