import { computed, ref } from 'vue';
import { cItem } from '../domain/item';
import { ItemManagerInterface } from './ItemManagerInterface';

/**
 * A custom hook for item navigation within a list.
 */
export function useItemNavigation(pages: ItemManagerInterface) {
    const selectedItemIndex = ref<number>(-1);
    const selectedItem = ref<cItem>(new cItem());

    // Clear the selected item
    const clearSelectedItem = () => {
        selectedItemIndex.value = -1;
        selectedItem.value = new cItem();
    };

    /**
     * Directly selects a specific item.
     */
    const directlySelectItem = (item: cItem) => {
        const index = pages.getIndexOfItem(item);
        if (index !== -1) {
            selectedItemIndex.value = index;
            selectedItem.value = item;
        }
    };

    const getSelectedItem = computed(() => selectedItem.value as cItem);
    const getSelectedItemIndex = computed(() => selectedItemIndex.value);

    /**
     * Jumps to and selects an item by its index.
     */
    const selectItemAtIndex = async (index: number) => {
        const item = await pages.getItemByIndex(index);
        selectedItem.value = item ?? new cItem();
        selectedItemIndex.value = index;
        scrollToItem();
    };

    /**
     * Moves the selection to a relative position from the current item.
     * @param offset The relative index to move by.
     */
    const moveSelectionByOffset = async (offset: number) => {
        const newIndex = selectedItemIndex.value + offset;
        await selectItemAtIndex(newIndex);
    };

    const scrollToItem = () => {
        const elmnt = document.querySelector(`table tr[data-itemid="${selectedItem.value.getId()}"]`);

        if (elmnt) {

            elmnt.scrollIntoView({ behavior: "smooth", block: 'center' });
        }
    }

    /**
     * Moves the selection to the next item in the list.
     */
    const selectNextItem = async () => {
        const nextIndex = selectedItemIndex.value < pages.getTotalRecords() - 1 ? selectedItemIndex.value + 1 : 0;
        await selectItemAtIndex(nextIndex);
    };

    /**
     * Moves the selection to the previous item in the list.
     */
    const selectPreviousItem = async () => {
        const prevIndex = selectedItemIndex.value > 0 ? selectedItemIndex.value - 1 : pages.getTotalRecords() - 1;
        await selectItemAtIndex(prevIndex);
    };

    return {
        selectedItem,
        selectedItemIndex,
        getSelectedItem,
        getSelectedItemIndex,
        clearSelectedItem,
        directlySelectItem,
        moveSelectionByOffset,
        selectNextItem,
        selectPreviousItem,
        selectItemAtIndex
    };
}
