<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { useScreenSaverStore, iScreenSaver } from '../../stores/screensavers';
import { useTokenStore, iToken } from '../../stores/tokens';
import { PropType } from 'vue';
import List from './list.vue';

const tokens = useTokenStore();
const screens = useScreenSaverStore();

const selectedToken = ref({} as iToken);
const selectedTokenId = ref(0);
const selectedScreenSaver = ref({} as iScreenSaver);
const deviceChanged = ref(false);

/**
 * Get a screen saver from its ID
 * 
 * @param pToken
 */
function screenGet(pToken: iToken): iScreenSaver {
    var screen = screens.get(pToken.screensaverid);
    if (screen == undefined) {
        screen = {} as iScreenSaver;
    }
    return screen;
}

/**
 * Device Token selected
 * @param pToken 
 */
function tokenClick(pToken: iToken) {
    selectedTokenId.value = pToken.id;
    selectedToken.value = pToken;

    var screen = screens.get(selectedToken.value.screensaverid);
    if (screen == undefined) {
        screen = {} as iScreenSaver;
    }

    selectedScreenSaver.value = screen;
    deviceChanged.value = false;
}

/**
 * 
 */
function tokenSave() {
    if (selectedScreenSaver.value?.id != undefined) {
        selectedToken.value.screensaverid = selectedScreenSaver.value?.id;
        tokens.save(selectedToken.value)
        deviceChanged.value = false;
    }
}

async function deleteToken(pToken: iToken) {
    await tokens.delete(pToken);
}

function saverSelected(pScreenSaver: iScreenSaver) {
    if (pScreenSaver.id !== selectedScreenSaver.value.id && pScreenSaver.id !== undefined)
        deviceChanged.value = true;

    selectedScreenSaver.value = pScreenSaver;
}

onMounted(async function () {
    tokens.load();
});

</script>

<template>
    <v-container @click="tokenClick({} as iToken)">

        <v-card title="Device Tokens">
            <v-table density="compact">
                <thead>
                    <tr>
                        <th class="text-left" style="width:240px">
                            Name
                        </th>
                        <th>
                            Using Screen Saver
                        </th>
                        <th class="text-left">
                            Random Value
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="token in tokens.getTokens" :key="token.id"
                        :class="{ 'bg-primary': token.id == selectedTokenId, 'row-pointer': true }"
                        @click.stop="tokenClick(token)">

                        <td>{{ token.name }}</td>
                        <td>{{ screenGet(token).name }}</td>
                        <td>{{ token.value }}</td>
                        <td>


                            <v-tooltip location="start">
                                <template v-slot:activator="{ props }">
                                    <v-icon v-bind="props" @click="deleteToken(token)">
                                        mdi-delete-forever
                                    </v-icon>
                                </template>
                                <span>Delete Token</span>
                            </v-tooltip>

                        </td>
                    </tr>
                </tbody>
            </v-table>

            <template v-if="deviceChanged == true">
                <v-row class="mt-12  mb-3 justify-center">
                    <v-btn color="primary" @click="tokenSave()" prepend-icon="mdi-content-save" class="justify-center">

                        <span>Save Device</span>
                    </v-btn></v-row>
            </template>
        </v-card>
    </v-container>
    <template v-if="selectedToken.id != undefined">
        <List title="Select Screen Saver" :screensaver="selectedScreenSaver" @select-screensaver="saverSelected">
        </List>
    </template>
</template>