
<script setup lang="ts">
import { CollectionData } from 'rundown-common';
import { collectionAll, useCollectionStore } from '../../stores/collections';

const collections = useCollectionStore();


function totalItemsCount() {
    var totalItems = 0;

    collections.getCollections.forEach(function (pCollection: CollectionData) {
        totalItems += pCollection.itemcount;
    });

    return totalItems;
}
</script>

<template>
    <v-list density="compact" nav>
        <v-list-subheader prepend-icon="mdi-image-multiple">
            <v-list-item-title>Collection Filter</v-list-item-title>
        </v-list-subheader>

        <v-list-item :active="collections.getSelected == 0" value="All" @click="collections.setSelected(collectionAll)"
            :color="collections.getSelected == collectionAll.id ? 'primary' : undefined" title="All">

            <template v-slot:append>
                <v-badge color="info" :content="totalItemsCount()" inline></v-badge>
            </template>

        </v-list-item>

        <template v-for="(item, i) in collections.getCollections">
            <v-list-item :active="collections.getSelected == item.id" :value="item.name"
                @click="collections.setSelected(item)"
                :color="collections.getSelected == item.id ? 'primary' : undefined" :title="item.name">

                <template v-slot:append>
                    <v-badge color="info" :content="item.itemcount" inline></v-badge>
                </template>

            </v-list-item>
        </template>
    </v-list>
</template>