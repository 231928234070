import axios, { AxiosResponse } from 'axios';

axios.defaults.withCredentials = true;

export interface PageMetaData {
  total_records: number;
  page?: number;
  limit?: number;
  count?: number;
}

export interface PageLink {
  href: string;
  rel: string;
}

export type PageLinks = Array<PageLink>;

export default {

  getUrl(pUrl: string) {
    return pUrl.split("?")[0];
  },
  
  getParams(pUrl: string) {
    return pUrl.split("?")[1];
  },

  getEmpty() : Promise<any> {
    return new Promise((resolutionFunc, rejectionFunc) => {
      setTimeout(() => {
        resolutionFunc( {
          data: {
            _meta: {
              total_records: 0,
              limit: 0
            },
            _links: [
  
            ],
            items: []
          }
        });
      }, 1);
      
    });
  },

  getPage(pLinks: PageLinks, pPage: number = 1, pLimit: number = 0) {
    if (pLinks.length == 0) {
      return this.getEmpty();
    }
    var query = new URLSearchParams(this.getParams(pLinks[0].href));
    query.set('page', pPage.toString());
    if(pLimit>0) {
      query.set('limit', pLimit.toString());
    }
    return axios.get(this.getUrl(pLinks[0].href) + '?' + query.toString());
  },

  getNextPage(pLinks: PageLinks) : Promise<AxiosResponse> {
    if (pLinks.length == 0) {
      return this.getEmpty();
    }
    return axios.get(pLinks[4].href);
  },

  getPreviousPage(pLinks: PageLinks) : Promise<AxiosResponse> {
    if (pLinks.length == 0) {
      return this.getEmpty();
    }
    return axios.get(pLinks[3].href);
  }
};