<script setup lang="ts">
import { onMounted, onUnmounted } from 'vue'
import { cItem } from '../../domain/item';
import { usePeopleStore } from '../../stores/people';
import { PersonData, ItemLocationData, ItemObjectData } from 'rundown-common';
import { cPerson } from '../../domain/Person';
import { PropType } from 'vue';

const people = usePeopleStore();

const props = defineProps({
    readOnly: {
        type: Boolean,
        default: false
    },
    itemCurrent: {
        type: Object as PropType<cItem|null>,
        required: true
    },
    items: {
        type: Array<cItem>,
        required: true,
    },
    itemsPerPage: {
        type: Number,
        required: true
    },
    isLoading: {
        type: Boolean,
        required: false,
        default: false
    },
    page: {
        type: Number,
        default: 1,
        required: false
    },
    pages: {
        type: Number,
        default: 0,
        required: false
    },
    height: {
        type: String,
        default: "500px",
        required: false
    },
    showActions: {
        type: Boolean,
        default: false
    },
    showPath: {
        type: Boolean,
        default: false
    }
});

const itemsPerPageArray = [10, 50, 100, 500];

const emit = defineEmits<{
    (e: 'page-select', pPage: number): void
    (e: 'page-next'): void
    (e: 'page-previous'): void
    (e: 'item-select', pItem: cItem): void
    (e: 'item-next'): void
    (e: 'item-previous'): void
    (e: 'items-per-page-change', pItemsPerPage: number): void
    (e: 'date-click', pEvent: Event, pDate: string): void
    (e: 'category-click', pEvent: Event, pCategory: string): void
    (e: 'person-chip-click', pEvent: Event, pPerson: cPerson): void
    (e: 'keyword-chip-click', pEvent: Event, pKeyword: string): void
    (e: 'location-click', pEvent: Event, pLocation: ItemLocationData | undefined): void
    (e: 'item-ctrl-click', pEvent: Event, pItem: cItem): void
}>();

/**
 * Event: Click a page number
 */
function pageSelect(pPage: number) {
    emit('page-select', pPage);
}

/**
 * Manage key presses
 */
function keypress(event: KeyboardEvent) {

    if (event.defaultPrevented) {
        return;
    }

    switch (event.key) {
        default:
            return;

        case "Down":
        case "ArrowDown":
            emit('item-next');
            break;

        case "Up":
        case "ArrowUp":
            emit('item-previous');
            break;

        case "Left":
        case "ArrowLeft":
            emit('page-previous');
            break;

        case "Right":
        case "ArrowRight":
            emit('page-next');
            break;
    }

    event.preventDefault();
}

onMounted(() => {
    document.addEventListener("keydown", keypress, { capture: false });
});

onUnmounted(() => {
    document.removeEventListener("keydown", keypress, { capture: false })
});

</script>


<template>
    <v-card elevation="2" class="justify-center ma-5 overflow-y-auto mx-0">
        <v-progress-linear :active="isLoading" indeterminate absolute bottom
            color="deep-purple-accent-4"></v-progress-linear>
        <v-card-title>
            <slot name="title"></slot>
        </v-card-title>
        <v-table density="compact" fixed-header :height="height">
            <thead>
                <tr>
                    <template v-if="showActions == true">
                        <th style="width:120px">
                            <slot name="actionHeader" />
                        </th>
                    </template>
                    <template v-if="showPath == true">
                        <th style="width:120px">Path</th>
                    </template>
                    <th style="width:120px">Name</th>
                    <th style="width:180px">Date</th>
                    <th>Category</th>
                    <th>People</th>
                    <th>Keywords</th>
                    <th>Address</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in items" :key="item.getId()"
                    @click.ctrl.prevent.capture="$emit('item-ctrl-click', $event, item)"
                    @click="readOnly == true && $emit('item-select', item)"
                    :class="{ 'bg-primary': item == itemCurrent, 'row-pointer': readOnly == true }"
                    :data-itemid="item.getId()">

                    <template v-if="showActions == true">
                        <td>
                            <slot :item="item" name="action" />
                        </td>
                    </template>

                    <template v-if="showPath == true">
                        <td>
                            <span class="row-pointer" @click="$emit('item-select', item)">{{ item.getPath() }}</span>
                        </td>
                    </template>

                    <td>
                        <span class="row-pointer" @click="$emit('item-select', item)">{{ item.getName() }}</span>
                    </td>

                    <td>
                        <span v-if="item.getDateTaken() != ''" @dblclick="$emit('date-click', $event, item.getDateTaken())">
                            {{ item.getDateTakenFormatted() }}</span>
                    </td>

                    <td>
                        <v-chip-group column>
                            <v-chip size="x-small" v-if="item.category != null && item.category.length > 0"
                                @dblclick="$emit('category-click', $event, item.category)">
                                {{ item.category }}
                            </v-chip>

                        </v-chip-group>
                    </td>

                    <td>
                        <v-chip-group column>
                            <v-chip size="x-small" v-for="itemobject in item.peopleSaved" :key="itemobject.getId()"
                                :value="itemobject.getCurrentName()"
                                @dblclick="$emit('person-chip-click', $event, itemobject.getPerson())">
                                {{ itemobject.getCurrentName() }}
                            </v-chip>
                        </v-chip-group>
                    </td>

                    <td>
                        <v-chip-group column>
                            <v-chip size="x-small" v-for="keyword in item.keywords" :key="keyword" :value="keyword"
                                @dblclick="$emit('keyword-chip-click', $event, keyword)">
                                {{ keyword }}
                            </v-chip>

                        </v-chip-group>
                    </td>
                    <td>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ props }">
                                <span v-bind="props" @dblclick="$emit('location-click', $event, item.locations[0])">
                                    {{ item.locations[0]?.placeName.substring(0, 15) }}
                                    {{ item.locations[0]?.placeName.length > 15 ? '...' : '' }}</span>
                            </template>
                            {{ item.locations[0]?.placeName }}
                        </v-tooltip>
                    </td>
                </tr>
            </tbody>
        </v-table>
    </v-card>

    <template v-if="pages > 0">
        <v-row justify="center">
            <v-col :cols="12" class="relative-container">
                <v-pagination :total-visible="10" :model-value="props.page" :length="props.pages"
                    @update:modelValue="pageSelect">
                </v-pagination>

                <div class="items-per-page-selector mr-5">
                    <span>Items per page</span>
                    <v-menu>
                        <template v-slot:activator="{ props }">
                            <v-btn style="min-width: 85px;" color="primary" class="ml-2" append-icon="mdi-chevron-down"
                                v-bind="props">
                                {{ itemsPerPage }}
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item v-for="(number, index) in itemsPerPageArray" :key="index" :title="number"
                                @click="emit('items-per-page-change', number);"></v-list-item>
                        </v-list>
                    </v-menu>

                </div>
            </v-col>
        </v-row>
    </template>
</template>

<style scoped>
.row-pointer:hover {
    cursor: pointer;
}

.relative-container {
    position: relative;
}

.items-per-page-selector {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
}
</style>