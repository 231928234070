
<script setup lang="ts">
import { ref, watch } from 'vue';
import { eRadiusMode, useFilterStore } from '../../stores/filters';
import FilterLocationDialog from './FilterLocationDialog.vue';

const filters = useFilterStore();
var updateTimer = ref(null as any);

var range = ref(filters.getGeoRadiusRange);
var rangeMode = ref(filters.getGeoRadiusMode);

function updateRange(pRange: any) {

    if (updateTimer.value !== null) {
        clearTimeout(updateTimer.value);
    }

    updateTimer.value = setTimeout(() => {
        filters.setGeoRadius(pRange);
    }, 500);
}

function updateMode() {
    filters.setGeoRadiusMode(rangeMode.value);
}

watch(filters.$state, function () {
    range.value = filters.getGeoRadiusRange;
    rangeMode.value = filters.getGeoRadiusMode;
});
</script>

<template>
    <v-list density="compact" nav>
        <v-list-subheader icon="mdi-crosshairs-gps">
            <v-list-item-title>Location Filter</v-list-item-title>
        </v-list-subheader>

        <FilterLocationDialog></FilterLocationDialog>

        <template v-if="filters.hasGeoLocation == true">
            <div class="text-caption pt-3">Range</div>
            <v-range-slider v-model="range" @update:modelValue="updateRange" class="px-2" step="1" max="1000"
                density="compact" :thumb-label="true">
            </v-range-slider>

            <v-switch v-model="rangeMode" @update:modelValue="updateMode" hide-details
                        :true-value="eRadiusMode.Kilometers" :false-value="eRadiusMode.Meters" :label="rangeMode"
                        density="compact"></v-switch>
        </template>
    </v-list>


</template>
