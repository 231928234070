
import { FilterMode } from '../../stores/filters';
import { ObjectInterface, PersonInterface, PersonNameData } from 'rundown-common';
import { cPerson } from '../Person';
import { cObject } from '../Object';
import { usePeopleStore } from '../../stores/people';
import { useObjectsStore } from '../../stores/objects';

/**
 * Abstract Filter
 */
export abstract class Filter {
    type: string;
    mode: FilterMode;

    constructor(pType: string, pFilterMode: FilterMode) {
        this.type = pType;
        this.mode = pFilterMode;
    }

    getColor(): string {
        switch (this.mode) {
            case FilterMode.AND:
                return ''; // Color for AND
            //case FilterMode.OR:
            //    return 'blue'; // Color for OR
            case FilterMode.NOT:
                return 'red'; // Color for NOT
            default:
                return 'black'; // Default color
        }
    }

    toggleMode() {
        // Cycle through the FilterMode enum values
        // Enum is bidirectional, so we divide the length by 2 to get the actual number of enum members
        this.mode = (this.mode + 1) % (Object.keys(FilterMode).length / 2);
    }

    abstract id(): any;
    abstract value(): string;
}

/**
 * Fitler Keyword
 */
export class FilterKeyword extends Filter {
    text: string;

    constructor(text: string, pFilterMode: FilterMode = FilterMode.AND) {
        super('Keyword', pFilterMode);
        this.text = text;
    }

    id(): string {
        return this.text;
    }

    value(): string {
        return this.text;
    }
}

/**
 * Filter Person
 */
export class FilterPerson extends Filter {
    person: PersonInterface;

    constructor(person: PersonInterface, pFilterMode: FilterMode = FilterMode.AND) {
        super('Person', pFilterMode);
        this.person = person;
    }

    getName(): PersonNameData {
        return this.person.getCurrentName();
    }

    id(): number {
        return this.person.getId();
    }

    value(): string {
        return this.person.getSearchName();
    }
}

/**
 * Filter Object
 */
export class FilterObject extends Filter {
    object: ObjectInterface;

    constructor(pObject: ObjectInterface, pFilterMode: FilterMode = FilterMode.AND) {
        super('Object', pFilterMode);
        this.object = pObject;
    }

    getName(): string {
        return this.object.getName();
    }

    id(): number {
        return this.object.getId();
    }

    value(): string {
        return this.object.getName();
    }
}

/**
 * Filter Group. 
 * 
 * Contains multiple Filters
 */
export class FilterGroup {
    filters: Filter[];

    constructor(pVal: string | PersonInterface | Filter) {
        this.filters = [];
        this.add(pVal);
    }

    get() {
        return this.filters;
    }

    getKeywords(): Array<FilterKeyword> {
        return this.get().filter(filter => filter.type === 'Keyword') as  Array<FilterKeyword>;
    }
    
    getPeople(): Array<FilterPerson> {
        return this.get().filter(filter => filter.type === 'Person') as Array<FilterPerson>;
    }
    
    getObjects(): Array<FilterObject> {
        return this.get().filter(filter => filter.type === 'Object') as Array<FilterObject>;
    }

    empty(): boolean {
        return this.filters.length == 0;
    }

    add(pVal: Array<object> | string | PersonInterface | Filter): void {
        let filter: Filter | null = null;

        if (Array.isArray(pVal)) {
            this.fromArray(pVal);
            return;
        } else
        if (pVal instanceof Filter) {
            filter = pVal;

        } else if (pVal instanceof cPerson) {
            filter = new FilterPerson(pVal);

        } else if (pVal instanceof cObject) {
            filter = new FilterObject(pVal);

        }else if (typeof pVal === 'string') {
            filter = new FilterKeyword(pVal);
        } 

        // Don't add duplicates
        if (filter && !this.filters.some(kw => kw.id() === filter?.id())) {
            this.filters.push(filter);
        }
    }

    remove(keyword: Filter): boolean {
        this.filters = this.filters.filter(kw => kw.id() !== keyword.id());

        return this.filters.length == 0;
    }

        
    fromArray(pData: Array<object>): void {
        const people = usePeopleStore();
        const objects = useObjectsStore();

        this.filters = pData.map(item => {
            let filter: Filter;

            switch (item.type) {
                case 'Person':
                    const person = people.getPersonById(item.id);
                    filter = new FilterPerson(person, item.mode);
                    break;

                case 'Object':
                    const object = objects.getObjectById(item.id);
                    filter = new FilterObject(object, item.mode);
                    break;

                case 'Keyword':
                    filter = new FilterKeyword(item.id, item.mode);
                    break;

                default:
                    throw new Error("Unknown filter type");
                    break;
            }

            return filter;
        });
    }

    toQueryParam(): string {
        return JSON.stringify(this.filters.map(filter => ({
            type: filter.type,
            mode: filter.mode,
            id: filter.id()
        })));
    }
}
