<script setup lang="ts">
import { onUnmounted, ref, watch } from 'vue';
import mapboxgl, { GeoJSONSource, LngLat, MapLayerMouseEvent, MapMouseEvent, MapboxGeoJSONFeature } from 'mapbox-gl';
import { useGeoJsonStore } from '../../stores/geojson';
import ItemPopup from './ItemPopup.vue';
import { cItem } from '../../domain/item';
import { useFilterStore } from '../../stores/filters';
import { ItemData } from 'rundown-common';

const props = defineProps({
    mapLoadedCallback: {
        type: Function,
        default: function () { return; }
    },
    mapSearchCallback: {
        type: Function,
        default: null
    },
    style: {
        type: String
    },
    zoomToItem: {
        type: cItem,
        required: false,
        default: new cItem()
    }
});

const geojson = useGeoJsonStore();
const filters = useFilterStore();

var mapBox = null as mapboxgl.Map | null;
var mapIsOpen = ref(false);

var popupPhotoVisible = ref(false);
var popupPhotoLocation = ref({ lng: 0, lat: 0 } as LngLat);
var popupPhotoItems = ref([] as Array<ItemData|GeoJSON.Feature>);

// Watch for filter changes
watch(filters.$state, function () {
    geojson.loadFeatures(featuresLoaded);
});

/**
 * Features loaded callback
 */
function featuresLoaded(pFeatures: GeoJSON.FeatureCollection, pPage: number, pPageTotal: number) {

    if (mapBox && mapIsOpen.value) {
        mapBox.getSource('photos').setData(pFeatures);
    }
}

/**
 * Event: Map Loaded
 */
function mapLoaded(pMapBox: mapboxgl.Map) {
    mapBox = pMapBox;

    const canvas = mapBox.getCanvasContainer();
    let start: mapboxgl.Point | null;
    let current: mapboxgl.Point | null;
    let box: HTMLDivElement | null;

    mapIsOpen.value = true;

    mapBox.on('click', () => {
        if (popupPhotoVisible.value == true)
            popupPhotoVisible.value = false;
    });

    mapBox.addSource('photos', {
        "type": "geojson",
        "data": geojson.getFeatureCollection,
        cluster: true,
        clusterMaxZoom: 14, // Max zoom to cluster points on
        clusterRadius: 50   // Radius of each cluster when clustering points (defaults to 50)
    });

    mapBox.addLayer({
        'id': 'photo-points',
        'type': 'circle',
        'source': 'photos',
        'paint': {
            'circle-radius': 6,
            'circle-color': '#B42222'
        },
        'filter': ['==', '$type', 'Point']
    });

    mapBox.addLayer(
        {
            'id': 'photo-points-highlighted',
            'type': 'circle',
            'source': 'photos',
            'paint': {
                'circle-radius': 60,
                'circle-color': '#B42222'
            },
            'filter': ['in', ['id'], '']
        },
    );

    mapBox.addLayer({
        id: 'clusters',
        type: 'circle',
        source: 'photos',
        filter: ['has', 'point_count'],
        paint: {
            // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
            // with three steps to implement three types of circles:
            //   * Blue, 20px circles when point count is less than 100
            //   * Yellow, 30px circles when point count is between 100 and 750
            //   * Pink, 40px circles when point count is greater than or equal to 750
            'circle-color': [
                'step',
                ['get', 'point_count'],
                '#51bbd6',
                100,
                '#f1f075',
                750,
                '#f28cb1'
            ],
            'circle-radius': [
                'step',
                ['get', 'point_count'],
                20,
                100,
                30,
                750,
                40
            ]
        }
    });

    mapBox.addLayer({
        id: 'cluster-count',
        type: 'symbol',
        source: 'photos',
        filter: ['has', 'point_count'],
        layout: {
            'text-field': '{point_count_abbreviated}',
            'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
            'text-size': 12
        }
    });

    mapBox.addLayer({
        id: 'unclustered-point',
        type: 'circle',
        source: 'photos',
        filter: ['!', ['has', 'point_count']],
        paint: {
            'circle-color': '#11b4da',
            'circle-radius': 4,
            'circle-stroke-width': 1,
            'circle-stroke-color': '#fff'
        }
    });

    canvas.addEventListener('mousedown', mouseDown, true);

    // Return the xy coordinates of the mouse position
    function mousePos(e: MouseEvent): mapboxgl.Point {
        const rect = canvas.getBoundingClientRect();
        return new mapboxgl.Point(
            e.clientX - rect.left - canvas.clientLeft,
            e.clientY - rect.top - canvas.clientTop
        );
    }

    function mouseDown(e: MouseEvent) {

        // Continue the rest of the function if the shiftkey is pressed.
        if (!(e.shiftKey && e.button === 0)) return;

        // Disable default drag zooming when the shift key is held down.
        mapBox?.dragPan.disable();

        // Call functions for the following events
        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('mouseup', onMouseUp);
        document.addEventListener('keydown', onKeyDown);

        // Capture the first xy coordinates
        start = mousePos(e);
    }


    function onMouseMove(e: MouseEvent) {
        // Capture the ongoing xy coordinates
        current = mousePos(e);

        // Append the box element if it doesnt exist
        if (!box) {
            box = document.createElement('div');
            box.classList.add('boxdraw');
            canvas.appendChild(box);
        }

        if(start==null)
            return;

        const minX = Math.min(start.x, current.x),
            maxX = Math.max(start.x, current.x),
            minY = Math.min(start.y, current.y),
            maxY = Math.max(start.y, current.y);

        // Adjust width and xy position of the box element ongoing
        const pos = `translate(${minX}px, ${minY}px)`;
        box.style.transform = pos;
        box.style.width = maxX - minX + 'px';
        box.style.height = maxY - minY + 'px';
    }

    function onMouseUp(e: MouseEvent) {

        if(start==null)
            return;
       
        // Capture xy coordinates
        finish([start, mousePos(e)]);
    }

    function onKeyDown(e: KeyboardEvent) {
        // If the ESC key is pressed
        if (e.keyCode === 27) finish(null);
    }

    async function finish(bbox: [mapboxgl.Point, mapboxgl.Point] | null) {
        // Remove these events now that finish has been called.
        document.removeEventListener('mousemove', onMouseMove);
        document.removeEventListener('keydown', onKeyDown);
        document.removeEventListener('mouseup', onMouseUp);

        if (box) {
            box.parentNode?.removeChild(box);
            box = null;
        }

        // If bbox exists. use this value as the argument for `queryRenderedFeatures`
        if (bbox && mapBox) {
            var ids = [] as Array<Number|string|undefined>;

            const features = mapBox.queryRenderedFeatures(bbox, {
                layers: ['unclustered-point']
            });

            ids = features.map((feature) => feature.id);
            mapBox.setFilter('photo-points-highlighted', ["all",
                ["in", ["id"], ['literal', ids]]
            ]);
/*
            const featuresc = mapBox.queryRenderedFeatures(bbox, { layers: ['clusters'] });
            if (featuresc.length > 0) {

                var clusterSource = mapBox.getSource('photos');
                var clusterId = featuresc[0].properties?.cluster_id ?? 0;
                var point_count = featuresc[0].properties?.point_count ?? 0;

                // Get all points under a cluster
                await clusterSource.getClusterLeaves(clusterId, point_count, 0, function (err, aFeatures) {
                    debugger;
                    ids = ids.concat(aFeatures.map((feature) => feature.id));
                    mapBox.setFilter('photo-points-highlighted', ["all",
                    ["in", ["id"], ['literal', ids]]]);
                });
            }
            */
        }

        mapBox?.dragPan.enable();
    }

    /**
     * Handle a click on a cluster
     */
    mapBox.on('click', 'clusters', (e: MapLayerMouseEvent) => {
        if (!mapBox || e.features == undefined)
            return;

        var features = mapBox.queryRenderedFeatures(e.point, { layers: ['clusters'] });
        if (features.length == 0)
            return;

        var clusterId = features[0].properties?.cluster_id ?? 0;
        var point_count = features[0].properties?.point_count ?? 0;

        var clusterSource = mapBox.getSource('photos') as GeoJSONSource;

        // Get all points under a cluster
        clusterSource.getClusterLeaves(clusterId, point_count, 0, function (err, aFeatures) {

            popupPhotos(features[0].geometry.coordinates.slice(), aFeatures, e);
        })
    });

    /**
     * Handle click on a point
     */
    mapBox.on('click', 'unclustered-point', (e) => {

        if (e.features != undefined) {
            popupPhotos(e.features[0].geometry.coordinates.slice(), e.features, e);
        }
    });

    geojson.loadFeatures(featuresLoaded);
    props.mapLoadedCallback(mapBox);

    if (props.zoomToItem.getId() != 0) {
        if (props.zoomToItem.locations[0].latitude && props.zoomToItem.locations[0].longitude) {
            mapBox.flyTo({
                center: [props.zoomToItem.locations[0].longitude,
                props.zoomToItem.locations[0].latitude
                ],
                zoom: 13,
                speed: 2,
                curve: 1,
                easing(t) {
                    return t;
                }
            });

            popupPhoto(props.zoomToItem.locations[0], props.zoomToItem);
        }
    }
}

function popupPhotos(pCoordinates, pFeatures: GeoJSON.Feature<GeoJSON.Geometry>, e: MapLayerMouseEvent) {
    const uniqueIds = new Set();
    // Ensure that if the map is zoomed out such that
    // multiple copies of the feature are visible, the
    // popup appears over the copy being pointed to.
    while (Math.abs(e.lngLat.lng - pCoordinates[0]) > 180) {
        pCoordinates[0] += e.lngLat.lng > pCoordinates[0] ? 360 : -360;
    }
    const uniqueFeatures = pFeatures.filter(feature => {
    if (!uniqueIds.has(feature.id)) {
        uniqueIds.add(feature.id);
        return true;
    }
    return false;
    });

    popupPhotoItems.value = uniqueFeatures;

    popupPhotoLocation.value.lat = pCoordinates[1];
    popupPhotoLocation.value.lng = pCoordinates[0];
    popupPhotoVisible.value = true;
}

function popupPhoto(pCoordinates, pItem: cItem) {

    popupPhotoItems.value = [pItem.item];

    popupPhotoLocation.value.lat = pCoordinates.latitude;
    popupPhotoLocation.value.lng = pCoordinates.longitude;
    popupPhotoVisible.value = true;
}

/**
 * Event: Geocoder Search result selected
 */
function mapGeoSearchResult(pLocation: any) {

    if (props.mapSearchCallback != null) {
        props.mapSearchCallback(pLocation);
        return;
    }

    if (mapBox) {
        mapBox.flyTo({
            center: pLocation.geometry.coordinates,
            essential: false,
            zoom: 16
        });

    }
}

onUnmounted(function () {
    mapIsOpen.value = false;
});

</script>

<template>
    <mapbox-map :style="style" :accessToken="mapboxToken" mapStyle="dark-v10" :zoom="1" @loaded="mapLoaded">
        <mapbox-geocoder-control @result="mapGeoSearchResult" :marker="false" />

        <mapbox-popup :closeOnClick="false" v-if="popupPhotoVisible == true"
            :lngLat="[popupPhotoLocation.lng, popupPhotoLocation.lat]">
            <ItemPopup :itemid="popupPhotoItems"></ItemPopup>
        </mapbox-popup>
    </mapbox-map>

</template>

<style scoped>
.boxdraw {
    background: rgba(56, 135, 190, 0.1);
    border: 2px solid #3887be;
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
}
</style>