<script setup lang="ts">
import { onMounted } from 'vue';
import { ThumbnailSize, cItem } from '../domain/item';
import { ref } from 'vue';
import { onUnmounted } from 'vue';
import Image from './Image.vue';
import { watch } from 'vue';

const props = defineProps({
  item: {
    type: cItem,
    required: true,
    default: new cItem()
  },
  thumbnail: {
    type: String,
    default: ThumbnailSize.Small
  },
  height: {
    type: String,
    default: "auto"
  },
  cover: {
    type: Boolean,
    default: false
  },
  shared: {
    type: String,
    default: "",
    required: false
  }
});

const videoRef = ref<HTMLVideoElement | null>(null);
const showImage = ref(true);
const videoError = ref(false);
const videoErrorMessage = ref('');
const videoReady = ref(false);

onMounted(() => {
  if (!videoError.value && videoRef.value) {
    videoRef.value.volume = 0;
    videoRef.value?.load();
  }
})

function loadedVideo() {
  if (!videoError.value && videoRef.value) {
    videoReady.value = true;
    videoRef.value.volume = 0;
  }
}

/**
 * Trigger video playback
 */
function playVideo() {
  if (videoReady.value) {
    if (!videoError.value && videoRef.value) {
      //videoRef.value?.load();
      videoRef.value?.play().catch((e) => {
        console.log(e);
        /* error handler */
      });
      showImage.value = false;
    }
  }
}

/**
 * Pause video playback
 */
function pauseVideo() {
  videoRef.value?.pause();
  showImage.value = true;
}

function handleVideoError(event: Event) {
  // Accessing the error details
  showImage.value = true;
  videoError.value = true;

  const error = (event.target as HTMLVideoElement).error;
  if (error) {
    switch (error.code) {
      case error.MEDIA_ERR_ABORTED:
        videoErrorMessage.value = 'The video playback was aborted.';
        break;
      case error.MEDIA_ERR_NETWORK:
        videoErrorMessage.value = 'A network error caused the video download to fail.';
        break;
      case error.MEDIA_ERR_DECODE:
        videoErrorMessage.value = 'The video playback was aborted due to a corruption problem or because the video used features your browser did not support.';
        break;
      case error.MEDIA_ERR_SRC_NOT_SUPPORTED:
        videoErrorMessage.value = 'The video could not be loaded, either because the server or network failed or because the format is not supported.';
        break;
      default:
        videoErrorMessage.value = 'An unknown error occurred.';
        break;
    }
  }
}

watch(props, async function () {
  videoError.value = false;
  videoRef.value?.load();

}, { immediate: true })

</script>

<template>
  <div class='live-player' @mouseover="playVideo" @mouseleave="pauseVideo">

    <Image v-show="showImage == true" :item="item" :height="height" :cover="cover" :thumbnail="thumbnail">
      <template v-slot:header>
        <slot name="header"></slot>
      </template>
    </Image>

    <div v-show="!showImage" class="video-container" :style="{ height: `${height}px` }">
      <slot name="header"></slot>
      <video :class="{ 'video-cover': cover }" ref="videoRef" :style="{ height: `${height}px` }" playsinline loop
        @loadeddata="loadedVideo" :poster="item.getImageUrl(ThumbnailSize.Large)">

        <source :src="item.getVideoUrl(shared)" @error="handleVideoError" />
        Your browser does not support the video tag.
        <slot name="footer"></slot>
      </video>
    </div>

  </div>
</template>

<style scoped>
.video-container {
  position: relative;
  overflow: hidden;
}

.video-container video {
  width: 100%;
  height: 100%;
}

.video-cover {
  object-fit: cover;
}
</style>