import { defineStore } from 'pinia';
import { computed, nextTick, ref, watch } from 'vue';
import { useItemMissingStore } from './itemsMissing';
import { cItem } from '../domain/item';

export const useItemMissingSlidingWindowStore = defineStore('itemMissingSlidingWindow', () => {
    const itemStore = useItemMissingStore();
    const windowSize = ref(10);
    const currentPosition = ref(0);

    const totalItems = computed(() => itemStore.getTotalRecords);
    const totalWindows = computed(() => Math.ceil(itemStore.getTotalRecords / windowSize.value));

    const items = ref<cItem[]>([]);

    // Function to check if the store is currently loading data
    const isLoading = computed(() => itemStore.isLoading);

    const windowNumber = computed(() => {
        return Math.ceil((currentPosition.value + 1) / windowSize.value);
    });

    const reloadCounter = computed(() => itemStore.reloadCounter);

    const reloadItems = async() => {
        const start = currentPosition.value;
        const count = Math.min(windowSize.value, totalItems.value - start + 1);

        items.value = await itemStore.getRange(currentPosition.value, count);
        
        nextTick(() => {
            scrollToItem(true);
        });
    };

    // move the window up by one position
    const moveUp = async () => {
        const selectedItemIndex = itemStore.getSelectedItemIndex - 1; // Assuming this gets the index of the selected item
        const diff = selectedItemIndex - currentPosition.value;

        if (selectedItemIndex < 0) {
            // Cycle to the bottom if the selected item is already the first one and we're moving up
            currentPosition.value = totalItems.value - 1;
            await itemStore.selectedJumpTo(itemStore.getTotalRecords - 1);
        } else {
            // Check if the selected item is at or before the halfway point in the window
            if (diff <= Math.floor(items.value.length / 2)) {
                // Move the window up if we're not at the start of the list
                if (currentPosition.value > 0) {
                    currentPosition.value--;
                }
            }
            await itemStore.selectedJumpBy(-1);
        }

        nextTick(() => {
            scrollToItem(true);
        });
    };


    // move the window down by one position
    const moveDown = async () => {

        const selectedItemIndex = itemStore.getSelectedItemIndex + 1;
        const diff = selectedItemIndex - currentPosition.value;

        // Check if the selected item is halfway or more through the window
        if (diff >= Math.floor(items.value.length / 2)) {
            // Move the window down if the end of the list isn't reached
            if (selectedItemIndex < totalItems.value && currentPosition.value < totalItems.value - 1) {
                currentPosition.value++;
                await itemStore.selectedJumpBy(1);
            } else {
                currentPosition.value = 0
                await itemStore.selectedJumpTo(0);
            }
        } else {
            await itemStore.selectedJumpBy(1);
        }

        nextTick(() => {
            scrollToItem(true);
        });

    };

    // Move down by one window
    const moveWindowDown = () => {

        if (currentPosition.value + windowSize.value < totalItems.value) {
            itemStore.selectedJumpBy(windowSize.value); // Normal jump down
        } else {
            itemStore.selectedJumpTo(0)
        }
    };

    // move up by one window
    const moveWindowUp = () => {

        if (currentPosition.value < windowSize.value) {
            itemStore.selectedJumpTo(totalItems.value - 1);
        } else {
            itemStore.selectedJumpBy(-windowSize.value);
        }
    };

    // jump to a specific position
    const jumpTo = (position: number) => {

        currentPosition.value = Math.max(0, Math.min(position, totalItems.value - windowSize.value));
    };

    const changeToWindow = (windowNumber: number) => {

        const newPosition = (windowNumber - 1) * windowSize.value;
        currentPosition.value = newPosition;
    };

    const setWindowSize = (pWindowSize: number) => {
        windowSize.value = pWindowSize;
    }

    const scrollToItem = (pDown: boolean) => {
        const itemId = itemStore.getSelectedItem.getId();
        const elmnt = document.querySelector(`table tr[data-itemid="${itemId}"]`);

        if (elmnt) {

            let blockPosition = pDown === true ? 'center' : 'center' as ScrollLogicalPosition;
            elmnt.scrollIntoView({ behavior: "smooth", block: blockPosition });
        }
    }

    watch([reloadCounter], () => {
        currentPosition.value = 0;
        reloadItems();
    });

    watch([currentPosition, windowSize], async () => {
        reloadItems();
    }, {immediate: true});


    return {
        currentPosition,
        totalItems,
        totalWindows,
        windowSize,
        isLoading,
        items,
        windowNumber,

        moveUp,
        moveDown,
        moveWindowDown,
        moveWindowUp,
        jumpTo,
        setWindowSize,
        changeToWindow,

    };
});
