import { defineStore } from 'pinia';
import { computed, ref, watch } from 'vue';
import { useItemStore } from './items';
import { cItem } from '../domain/item';
import { useSettingsStore } from './settings';

export const useItemPagingStore = defineStore('itemPaging', () => {
    const settings = useSettingsStore();
    const itemStore = useItemStore();

    const items = ref<cItem[]>([]);
    const currentPage = ref(1);

    const itemsPerPage = computed(()=>settings.itemsPerPage);
    const totalPages = computed(() => Math.ceil(itemStore.getTotalRecords / itemsPerPage.value));

    // Function to check if the store is currently loading data
    const isLoading = computed(() => itemStore.isLoading);
    const reloadCounter = computed(() => itemStore.reloadCounter);

    // Watch both our index and the item store, and update our items if either change

    const reloadItems = async() => {
        const startIndex = (currentPage.value - 1) * itemsPerPage.value;
        items.value = await itemStore.getRange(startIndex, itemsPerPage.value);
    }

    // Previous page
    const goToPage = (pageNumber: number) => {
        currentPage.value = Math.max(1, Math.min(pageNumber, totalPages.value));
    };

    // Next page
    const goToNextPage = () => {
        if (currentPage.value < totalPages.value) {
            currentPage.value++;
        }
    };

    // Function to go to the previous page
    const goToPreviousPage = () => {
        if (currentPage.value > 1) {
            currentPage.value--;
        }
    };

    watch([reloadCounter], async () => {
        currentPage.value = 1;
        await reloadItems();
        await itemStore.selectedJumpTo(0)   
    });

    watch([currentPage, itemsPerPage], async () => {
        reloadItems();
    }, {immediate: true});


    return {
        items,
        currentPage,
        isLoading,
        itemsPerPage,
        totalPages,

        goToPage,
        goToNextPage,
        goToPreviousPage,
    };
});
