interface RuntimeConfig {
    api: string;
    websocket: string;
    mapbox_token: string;
  }
  
  let cachedConfig: RuntimeConfig | null = null;
  
  export const getConfig = async (): Promise<RuntimeConfig> => {
    if (cachedConfig) {
      return cachedConfig;
    }
  
    const configData = await (await fetch("/config/runtime.json")).json();
  
    if (
      !configData ||
      typeof configData.api !== "string" ||
      typeof configData.websocket !== "string" ||
      typeof configData.mapbox_token !== "string"
    ) {
      throw new Error("Invalid config data format");
    }
  
    cachedConfig = configData as RuntimeConfig;
    return configData as RuntimeConfig;
  };
  