<script setup lang="ts">
import { ref } from 'vue';
import { usePeopleStore } from '../../stores/people';
import { cItem } from '../../domain/item';
import { nextTick } from 'vue';
import { ItemObjectInterface, ItemPersonInterface } from 'rundown-common';
import PluginRender from '../PluginRender.vue';

const props = defineProps({
    item: {
        type: cItem,
        required: true
    }
});

const visible = ref(false);
const clientX = ref(0);
const clientY = ref(0);
const imageX = ref(0);
const imageY = ref(0);

const tooltipRef = ref(null);
const style = ref<any>({});
const hoverObject = ref<null | ItemObjectInterface>(null);
const hoverPerson = ref<null | ItemPersonInterface>(null);

const positionSet = async (pX: number, pY: number, pImageX: number, pImageY: number) => {
    const object = props.item.getObjectByPosition(pImageX, pImageY);
    const person = props.item.getPersonByPosition(pImageX, pImageY);
    const hasHoverContent = object || person;
    const previousVisible = visible.value;

    if (hasHoverContent) {
        hoverObject.value = object;
        hoverPerson.value = person;
        visible.value = true;
    } else {
        visible.value = false;

        if (previousVisible) {
            await nextTick();
        }
    }
    if (tooltipRef.value) {
        imageX.value = pImageX;
        imageY.value = pImageY;
        clientX.value = pX;
        clientY.value = pY;

        if (person) {
            // If it's a person, position the tooltip over the cursor
            style.value = {
                zIndex: `10000`,
                position: `absolute`,
                top: `${clientY.value}px`,
                left: `${clientX.value}px`
            };
        }
    }
}

defineExpose({ positionSet });

</script>
<template>
    <template v-if="hoverObject !== null && visible == true">

        <v-layout class="panel">

            <v-app-bar color="indigo-darken-3" density="compact">

                <v-toolbar-title>Object: {{ hoverObject?.getCurrentName() }}</v-toolbar-title>

            </v-app-bar>
            <v-main>

                <v-table class="panel-overlay" density="compact">
                    <tbody>
                        <PluginRender render-element="ItemObject" render-piece="Hover" :item="item"
                            :item-object="hoverObject">
                        </PluginRender>
                    </tbody>
                </v-table>


            </v-main>
        </v-layout>

    </template>
    <template v-else="hoverPerson !== null">
        <v-tooltip ref="tooltipRef" v-model="visible" :style="style">

            {{ hoverPerson?.getCurrentName() }}

        </v-tooltip>
    </template>
</template>


<style scoped>
.panel {
    position: absolute;
    left: 0;
    width: 20%;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8);
    background: rgba(0, 0, 0, 0.6) !important;
}

.panel-overlay {
    overflow-y: hidden;
    width: 100%;
    background: rgba(0, 0, 0, 0.6) !important;
}
</style>