<script setup lang="ts">
import { useAuthStore } from '../../stores/auth';

function signIn() {
    useAuthStore().showLoginDialog = true;
}

function register() {
    useAuthStore().showRegisterDialog = true;
}
</script>
<template>
    <v-row class="mt-12" justify="center" align="center">
        <v-col cols="12" sm="8" md="6">
            <v-card class="px-5 py-8 rounded-lg" color="#252525" dark>
                <h2 class="mb-5">No items available</h2>
                <p class="mb-5">Please sign in or register to add items to your collection.</p>
                <v-row justify="center" align="center" class="mt-7">
                    <v-btn class="custom-btn mr-3 px-10" rounded="pill" color="green" dark @click="signIn">Sign In</v-btn>
                    <v-btn class="custom-btn px-10" rounded="pill" color="blue" dark @click="register">Register</v-btn>
                </v-row>
            </v-card>
        </v-col>
    </v-row>
</template>
