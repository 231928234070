import { defineStore } from 'pinia'
import { cItem } from '../domain/item';
import { ItemData } from 'rundown-common';
import { Ref, ref } from 'vue';

export const useSettingsStore = defineStore("settings", {
    state: () => ({
        screenSmall: false,
        sidebarVisible: true,
        screenSize: ref('md'),
        selectedItem: new cItem(),
        fullscreen: false,
        homeViewModeIsPaged: 'Paging',
        dialogOpen: false,
        itemsPerPage: 2,
        totalHeight: 0
    }),

    getters: {
        isScreenSmall: (state) => state.screenSmall,
        isSidebarVisible: (state) => state.sidebarVisible,
        isFullscreen: (state) => state.fullscreen,
        isHomeViewModePaged: (state) => state.homeViewModeIsPaged === 'Paging',
        isDialogOpen: (state) => state.dialogOpen,
        getSelectedItem: (state) => state.selectedItem,
        getAvailableHeight: (state) => state.totalHeight,
        getItemsPerPage: (state) => state.itemsPerPage,

        getItemsPerRow(): number {
            switch (this.screenSize) {
                case 'xs': return 1;
                case 'sm': return 2;
                case 'md': return 2;
                case 'lg': return 3;
                case 'xl': return 4;
                case 'xxl': return 6;
                default:
                    return 1;
            }
        },

        getHeaderSize(): number {
            switch (this.screenSize) {
                case 'xs':
                    return 48;
                case 'sm':
                case 'md':
                    return 48;
                case 'lg':
                case 'xl':
                case 'xxl':
                    return 48;

                default:
                    break;
            }
            return 0;
        },

        getHeaderFooterSize(): number {
            switch (this.screenSize) {
                case 'xs':
                    return this.getHeaderSize + 73;
                case 'sm':
                case 'md':
                    return this.getHeaderSize + 105;
                case 'lg':
                case 'xl':
                case 'xxl':
                    return this.getHeaderSize + 105;

                default:
                    break;
            }
            return 0;
        },

        getImageCardSize(): number {
            switch (this.screenSize) {
                case 'xs':
                    return 430;
                case 'sm':
                case 'md':
                    return 455;
                case 'lg':
                case 'xl':
                case 'xxl':
                    return 455;

                default:
                    break;
            }
            return 235;
        },

        getImageSize(): number {
            return this.getImageCardSize;
            /*
                        switch(this.screenSize) {
                            case 'xs':
                                return 290;
                            case 'sm':
                            case 'md':
                                return 344;
                            case 'lg':
                            case 'xl':
                            case 'xxl':
                                return 455;
            
                            default:
                                break;
                        }
                        return 150;*/
        },

        getImageThumb(): string {
            switch (this.screenSize) {
                case 'xs':
                    return 'small';
                case 'sm':
                case 'md':
                    return 'small';
                case 'lg':
                case 'xl':
                case 'xxl':
                    return 'medium';

                default:
                    break;
            }
            return 'small';
        },

        getOnThisDayImageWidth(): number {
            switch (this.screenSize) {
                case 'xs':
                    return 150;
                case 'sm':
                case 'md':
                    return 270;
                case 'lg':
                    return 600;
                case 'xl':
                    return 1000;
                case 'xxl':
                    return 1200;

                default:
                    break;
            }
            return 150;
        },

    },

    actions: {

        calculateItemsPerPage(pHeight: Ref<number>) {
            if (this.isDialogOpen)
                return;

            var rows = Math.floor(((pHeight.value - this.getHeaderFooterSize) / (this.getImageCardSize)));

            this.itemsPerPage = this.getItemsPerRow * rows;
            this.totalHeight = (this.getImageCardSize * rows) + (rows * 10);
        },

        hideSidebar() {
            if (this.isScreenSmall)
                this.sidebarVisible = false;
        },

        setSidebarVisible() {
            this.sidebarVisible = !this.sidebarVisible;
        },

        setScreenSize(name: string) {

            // If a dialog is open, dont change the screen size
            if (this.isDialogOpen) {
                return;
            }

            this.screenSize = name;

            var set = false;
            switch (name) {
                case 'xs': set = true; break;
                case 'sm': set = false; break;
                case 'md': set = false; break;
                case 'lg': set = false; break;
                case 'xl': set = false; break;
                case 'xxl': set = false; break;
                default:
                    break;
            }

            this.screenSmall = set;
            if (!set) {
                this.sidebarVisible = true;
            } else {
                this.sidebarVisible = false;
            }
        },

        clearSelectedItem() {
            this.selectedItem = new cItem({ "id": 0 } as ItemData);
        },

        setSelectedItem(pItem: cItem) {
            this.selectedItem = pItem;
        },

        setFullscreen(pMode: boolean) {
            this.fullscreen = pMode;
        },

        setDialogOpen(pOpen: boolean) {
            this.dialogOpen = pOpen;
        }
    },
})