import { ImageScale } from 'rundown-common';
import { Ref } from 'vue'

export function useImageScaling(pImageContainer: Ref<HTMLImageElement|null>) {

    function calculateScale() {
        if (!pImageContainer.value) return 1;
        const { naturalWidth, naturalHeight, clientWidth, clientHeight } = pImageContainer.value;
        return naturalWidth / naturalHeight > clientWidth / clientHeight ? clientWidth / naturalWidth : clientHeight / naturalHeight;
    }

    /**
     * Scale from image to displayed image
     */
    function scaleFromRealImage(pBox: ImageScale): ImageScale {
        if (!pImageContainer.value) return {x: -1, y: -1, width: 0, height: 0};

        const scale = calculateScale();
        const { naturalWidth, naturalHeight } = pImageContainer.value;
        const { clientWidth: canvasWidth, clientHeight: canvasHeight } = pImageContainer.value;
        const transformValues = new DOMMatrixReadOnly(getComputedStyle(pImageContainer.value).transform);
        const [scaleX, scaleY, translateX, translateY] = [transformValues.a, transformValues.d, transformValues.e, transformValues.f];
    
        // Adjust y-coordinate for object-position: top
        let adjustedY;
        if (canvasHeight < naturalHeight * scale) {
            // When the scaled image is smaller than the canvas height
            adjustedY = pBox.y * scale * scaleY + translateY;
        } else {
            // When the scaled image fills or exceeds the canvas height
            adjustedY = (pBox.y * scale + (canvasHeight - naturalHeight * scale) / 2) * scaleY + translateY;
        }
    
        return {
            x: (pBox.x * scale + (canvasWidth - naturalWidth * scale) / 2) * scaleX + translateX,
            y: adjustedY,
            width: pBox.width * scale * scaleX,
            height: pBox.height * scale * scaleY
        };
    }

    /**
     * Scale to real image from display image
     */
    function scaleToRealImage(transformedX: number, transformedY: number) {
        if (!pImageContainer.value) return {x: -1, y: -1};
        const scale = calculateScale();
    
        const { naturalWidth, naturalHeight } = pImageContainer.value;
        const { clientWidth: canvasWidth, clientHeight: canvasHeight } = pImageContainer.value;
        const transformValues = new DOMMatrixReadOnly(getComputedStyle(pImageContainer.value).transform);
        const [scaleX, scaleY, translateX, translateY] = [transformValues.a, transformValues.d, transformValues.e, transformValues.f];
    
        // Reverse x-coordinate transformation
        const x = (transformedX - translateX) / scaleX;
        const adjustedX = (x - (canvasWidth - naturalWidth * scale) / 2) / scale;
    
        // Reverse y-coordinate transformation
        let adjustedY;
        if (canvasHeight > naturalHeight * scale) {
            // When the scaled image is smaller than the canvas height
            adjustedY = (transformedY - translateY) / (scale * scaleY);
        } else {
            // When the scaled image fills or exceeds the canvas height
            adjustedY = ((transformedY - translateY) / scaleY - (canvasHeight - naturalHeight * scale) / 2) / scale;
        }
    
        return {
            x: adjustedX,
            y: adjustedY
        };
    }
    
    return { calculateScale, scaleFromRealImage, scaleToRealImage }
};
