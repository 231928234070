import axios from 'axios';
import { defineStore } from 'pinia';
import { cItem } from '../domain/item';
import { ItemPageManager } from '../utility/ItemPageManager';
import { ItemData, ItemRotate } from 'rundown-common';
import { computed, ref } from 'vue';
import { useMessageStore } from './messages';
import { useFilterStore } from './filters';
import { useItemNavigation } from '../utility/useItemNavigation';
import { useCollectionStore } from './collections';

export const useItemStore = defineStore('items', () => {
    const pages: ItemPageManager = new ItemPageManager();
    const navigation = useItemNavigation(pages);

    // Get a range of items
    const getRange = async ( startIndex: number, count: number) => {
        return await pages.getRange(startIndex, count);
    };

    // Get the total number of records
    const getTotalRecords = computed(() => pages.getTotalRecords());

    // Check if the store is currently loading data
    const isLoading = computed(() => pages.isLoading());
    const reloadCounter = computed(() => pages.getReloadCounter());
   
    async function load() {
        await pages.load();
        if(!navigation.selectedItem.value.isValid()) {
            navigation.selectNextItem();
        }
    }

    async function reload() {
        await pages.reload();
    }
    /**
     * Update an item or handle its deletion
     */
    async function update(pItem: ItemData, pDeleted: boolean, pContext: object) {
        const item = pages.getItem(pItem.id);

        // Item has moved
        if(item?.item.collection_id != pItem.collection_id) {

            // If the new collection id isnt the current collection, remove this item
            if(pItem.collection_id != pages.getCollectionId()) {
                pDeleted = true;
            }
        }

        if (pDeleted) {
            pages.removeItem(pItem.id);

            // Check if the deleted item is the selected item
            if (navigation.getSelectedItem.value.getId() === navigation.selectedItemIndex.value) {
                // Move to the next item, or clear selection if it was the last item
                if (navigation.selectedItemIndex.value < getTotalRecords.value - 1) {
                    navigation.selectedItemIndex.value++;
                } else {
                    navigation.clearSelectedItem();
                }
            }

            return;
        } 
        
        item?.refresh(pItem, pContext);
    }

    /**
     * Get a single item by its ID
     */
    async function getByID(pID: number): Promise<cItem> {
        if(pID > 0) {
            const messages = useMessageStore();
            try {
                const data = await axios.get('/v1/item/' + pID);
                return new cItem(data.data);
            } catch (error) {
                messages.handleAxiosError(error);
            }
        }
        return new cItem();
    }

    /**
     * Get details of an item which has been shared
     */
    async function getShared(pName: string) {

        const data = await axios.get('/v1/share/' + pName);
        return new cItem(data.data);
    }

    function addHours(numOfHours: number, date: Date = new Date()) {
        date.setTime(date.getTime() + numOfHours * 60 * 60 * 1000);
        return date.toUTCString();
    }

    /**
     * Share an item for a number of hours
     */
    async function share(pID: number, pHours: number) {
        const messages = useMessageStore();
        try {
            const data = await axios.post('/v1/item/' + pID + '/share', { expires: addHours(pHours) });
            return data.data;
        } catch (error) {
            messages.handleAxiosError(error);
        }
        return null;
    }

    /**
     * 
     */
    async function download() {
        const messages = useMessageStore();
        const filters = useFilterStore();
        try {
            const query = filters.getCollectionId + "?" + filters.getParameters;

            const data = await axios.get('/v1/item/download/' + query, {
                responseType: 'blob'
            });
            return data.data;
        }
        catch (error) {
            messages.handleAxiosError(error);
        }
    }

    /**
     * Rotate an item
     */
    async function rotate(pItemID: number, pDirection: ItemRotate) {
        const query = new URLSearchParams();
        query.append("direction", pDirection.toLowerCase());

        const messages = useMessageStore();
        try {
            const data = await axios.put('/v1/item/' + pItemID.toString() + '/tools/rotate?' + query.toString());
            return true;
        }
        catch (error) {
            messages.handleAxiosError(error);
        }
        return false;
    }

    return {

        getRange,
        reloadCounter,
        getTotalRecords,
        isLoading,
        update,
        load,
        reload,

        getShared,
        getByID,
        share,
        download,
        rotate,

        getIndexOfItemId: pages.getIndexOfItemId,
        getSelectedItem: navigation.getSelectedItem,
        getSelectedItemIndex: navigation.getSelectedItemIndex,
        selectItem: navigation.directlySelectItem,
        selectItemAtIndex: navigation.selectItemAtIndex,
        selectNextItem: navigation.selectNextItem,
        selectPreviousItem: navigation.selectPreviousItem,
        selectedJumpTo: navigation.selectItemAtIndex,
        selectedJumpBy: navigation.moveSelectionByOffset,
        clearSelectedItem: navigation.clearSelectedItem
    };
});