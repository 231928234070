<script setup lang="ts">
import { onMounted } from 'vue';
import { cItem } from '../../domain/item';
import { useItemMissingStore } from '../../stores/itemsMissing';
import { useItemMissingSlidingWindowStore } from '../../stores/itemMissingSlidingWindow';
import Image from '../../components/Image.vue';
import ItemTable from '../../components/table/items.vue';

const items = useItemMissingStore();
const itemWindow = useItemMissingSlidingWindowStore();

onMounted(async () => {
    await items.load();
});

function remove(pItem: cItem) {
    items.remove(pItem.getId());
}

</script>

<template>
    <template v-if="itemWindow.items.length">
        <v-card elevation="2" class="justify-center w-100">
            <v-row>
                <v-col cols="5">
                    <v-card-title>{{ items.getSelectedItem.getName() }}
                        <Image :item="items.getSelectedItem" :height="'400'" thumbnail="large">
                        </Image>
                    </v-card-title>
                </v-col>
                <v-col class="mt-12">
                    <v-btn class="justify-center" prepend-icon="mdi-delete" @click="remove(items.getSelectedItem)">
                        Remove Item
                    </v-btn>
                </v-col>
            </v-row>

            <ItemTable read-only :isLoading="items.isLoading" :items="itemWindow.items"
                :item-current="items.getSelectedItem" :page="itemWindow.windowNumber" :pages="itemWindow.totalWindows"
                :items-per-page="itemWindow.windowSize" @page-select="itemWindow.changeToWindow" @item-select="items.selectItem"
                @items-per-page-change="itemWindow.setWindowSize" @item-next="itemWindow.moveDown" @item-previous="itemWindow.moveUp"
                @page-next="itemWindow.moveWindowDown" @page-previous="itemWindow.moveWindowUp">

            </ItemTable>
        </v-card>
    </template>
    <template v-else>
        <v-row v-if="items.isLoading == false" class="mt-12" justify="center" align="center">
            <v-col cols="12" sm="8" md="6">
                <v-card class="px-5 py-8 rounded-lg" dark>
                    <h2 class="mb-5">No missing items</h2>
                </v-card>
            </v-col>
        </v-row>
        <v-row v-else class="mt-12" justify="center" align="center">
            <v-progress-circular indeterminate></v-progress-circular>
        </v-row>
    </template>
</template>