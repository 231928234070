<script setup lang="ts">
import { ref } from 'vue';
import { useAuthStore } from '../../stores/auth';

const usernameRules = [
    (v: string) => !!v || 'Username is required',
    (v: string) => v.length <= 20 || 'Username must be less than 10 characters',
];

const nameRules = [
    (v: string) => !!v || 'Name is required',
    (v: string) => v.length <= 40 || 'Name must be less than 10 characters',
];

const emailRules = [
    (v: string) => !!v || 'E-mail is required',
    (v: string) => /.+@.+/.test(v) || 'E-mail must be valid',
];

const auth = useAuthStore();

const valid = ref(false);
const username = ref("");
const name = ref("");
const email = ref("");
const password = ref("");
const loading = ref(false);

async function register() {
    loading.value = true;
    if (await auth.register(username.value, name.value, email.value, password.value) == true) {
        auth.showRegisterDialog = false;
    }
    loading.value = false;
}

</script>

<template>
    <v-dialog v-model="auth.showRegisterDialog">
        <v-card class="mx-auto px-8 py-4" min-width="300" max-width="500">

            <span class="text-h6 text-center pb-6 mt-0">Create Account</span>

            <v-form v-model="valid">

                <v-text-field v-model="username" label="Username" :rules="usernameRules" required variant="underlined">
                </v-text-field>

                <v-text-field v-model="password" label="Password" type="password" required variant="underlined">
                </v-text-field>

                <v-text-field v-model="name" label="Name" :rules="nameRules" required variant="underlined">
                </v-text-field>

                <v-text-field v-model="email" label="Email" :rules="emailRules" required variant="underlined">
                </v-text-field>

            </v-form>
            <v-card-actions>
                <v-btn :disabled="!valid" :loading="loading" block color="success" size="large" type="submit"
                    variant="elevated" @click="register">
                    Create
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>