import Human, { Config, Result, match } from "@vladmandic/human";
import { PersonFaceData } from "rundown-common";
import { useDynamicToolbarSettings } from "../stores/DynamicToolbarSettings";
import { watch } from "vue";

export interface FaceResult {
    similarity: number,
    face: PersonFaceData,
}

export function useHumanDetector(pConfig: Config | null = null) {

    const Dynamic = useDynamicToolbarSettings();
    
    if (pConfig == null) {
        pConfig = {
            debug: false,
            filter: { enabled: true, equalization: true, flip: false },
            face: {
                mesh: { enabled: false },
                detector: {
                    rotation: true,
                    maxDetected: 100,
                    minConfidence: 0.7,
                    return: false,
                    iouThreshold: 0.01
                },
                iris: { enabled: false },
                description: { enabled: true },
                emotion: { enabled: false },
                antispoof: { enabled: false },
                liveness: { enabled: false },
            },
            body: { enabled: false },
            hand: { enabled: false },
            object: { enabled: false, minConfidence: 0.1},
            gesture: { enabled: false },
            segmentation: { enabled: false },
        } as Config;
    }

    const human = new Human(pConfig);
    
    /**
     * Detect faces in provided image
     */
    const detectFaces = async (pImage: HTMLImageElement): Promise<Result> => {
        if(human.config.face.detector)
            human.config.face.detector.minConfidence = Dynamic.getFacialRecognitionConfidence / 10;

        return await human.detect(pImage);
    };

    /**
     * Compare the face provided to an array of known faces
     * Return the best matching face
     */
    const compareFaces = (pEmbedding: Array<number>, pEmbeddings: Array<PersonFaceData>): Array<FaceResult> => {
        const embeddings = pEmbeddings.map((record) => record.embedding_data);

        const bestMatches = findTopMatches(5, pEmbedding, embeddings);
        return bestMatches.map(match => (
            {
                similarity: match.similarity,
                distance: match.distance,
                face: pEmbeddings[match.index]
            }));
    };

    /**
     * Normalize distances
     */
    const normalizeDistance = (dist: number, order: number, min: number, max: number) => {
        if (dist === 0) return 1; // short circuit for identical inputs
        const root = order === 2 ? Math.sqrt(dist) : dist ** (1 / order); // take root of distance
        const norm = (1 - (root / 100) - min) / (max - min); // normalize to range
        const clamp = Math.max(Math.min(norm, 1), 0); // clamp to 0..1
        return clamp;
    };

    /**
     * Find the best matches
     */
    const findTopMatches = (
        pMax: number,
        descriptor: Array<number>,
        descriptors: Array<number>[],
        options: match.MatchOptions = { order: 2, multiplier: 20, threshold: 0, min: 0.2, max: 0.8 }
    ) => {
        if (!Array.isArray(descriptor) || !Array.isArray(descriptors) || descriptor.length < 64 || descriptors.length === 0) {
            return [];
        }

        const matches = descriptors.map((desc, i) => {
            const distance = desc.length === descriptor.length ? human.match.distance(descriptor, desc, options) : Number.MAX_SAFE_INTEGER;
            return { index: i, distance };
        });

        matches.sort((a, b) => a.distance - b.distance);

        const topMatches = matches.slice(0, pMax).map(match => {
            return {
                ...match,
                similarity: normalizeDistance(match.distance, options.order || 2, options.min || 0.2, options.max || 0.8)
            };
        });

        return topMatches;
    };


    return { human, detectFaces, compareFaces };
}
