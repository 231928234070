<script setup lang="ts">
import { PropType } from 'vue';
import { cItem } from '../domain/item';
import { useItemCartStore } from '../stores/itemcart';

const cart = useItemCartStore();

const props = defineProps({
    item: {
        type: Object as PropType<cItem>,
        required: true
    },
    isHovering: {
        type: Boolean,
        required: true
    }
});

</script>

<template>
    <div class="panel-media-actions item-action-icon">
        <template v-if="isHovering == true">
            <v-icon v-if="cart.hasItem(item) == false" @click.stop="cart.toggleSelectedItem(item)" size="x-large"
                class="mx-1 my-1">mdi-circle-outline</v-icon>
        </template>
        
        <v-icon v-if="cart.hasItem(item) == true" @click.stop="cart.toggleSelectedItem(item)" size="x-large"
            class="mx-1 my-1">mdi-check-circle</v-icon>
    </div>
</template>

<style scoped>
.panel-media-actions {
    float: right;
}

.item-action-icon i {
    filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5));
    opacity: 0.95;
    z-index: 1000;
}
</style>