<script setup lang="ts">
import axios from 'axios';
import { ref, PropType, onMounted, nextTick } from 'vue';
import { useAuthStore } from '../../stores/auth';
import { useCollectionStore } from '../../stores/collections';
import { useMessageStore } from '../../stores/messages';
import { CollectionData } from 'rundown-common';

const auth = useAuthStore();
const collections = useCollectionStore();
const messages = useMessageStore();

const props = defineProps({
    collection: {
        type: Object as PropType<CollectionData>,
        required: true
    }
});


const emit = defineEmits<{
    (e: 'close'): void
}>();

const dialogBrowser = ref(false);
const dialogSelectingFor = ref(0);
const browserPath = ref('');
const pathErrorMessage = ref("");
const valid = ref(false);

const form = ref<any | null>(null);

const folderFormatOptions = ["{Year}", "{Month}", "{MonthName}", "{Category}"];

const nameRules = [
    (v: string) => !!v || 'Name is required',
    (v: string) => v.length > 2 || 'Name must be more than 2 characters',
];

const pathRules = [
    (v: string) => !!v || 'Path is required',
    (v: string) => {
        const regex = /^\/[^\/]+(\/[^\/]+)*\/?$/;
        return regex.test(v) || 'Path is invalid';
    }
];

const pathVariantRules = [
    (v: string) => {
        if (!v?.length)
            return true;

        const regex = /^\/[^\/]+(\/[^\/]+)*\/?$/;
        return regex.test(v) || 'Path is invalid';
    }
];

const prefixRules = [
    (v: string) => {
        if (!props.collection.managed)
            return true;
        return !!v || 'Prefix is required';
    }
]

const folderFormatRules = [
    (v: string) => {
        if (!props.collection.managed)
            return true;

        const pattern = /^[a-zA-Z0-9_\/-]+$/;
        let openBrackets = 0;
        let cleanToken = '';
        let currentToken = '';

        for (const char of v) {
            if (char === '{') {
                if (openBrackets++)
                    return 'Overlapping open brackets';
                currentToken = '{';
            } else if (char === '}') {
                currentToken += '}';
                if (--openBrackets < 0)
                    return 'Overlapping close brackets';
                if (!folderFormatOptions.includes(currentToken)) {
                    return `Invalid token (${currentToken})`;
                }
            } else if (!openBrackets) {
                cleanToken += char;
            } else {
                currentToken += char;
            }
        }

        return openBrackets ? 'Unclosed token' :
            (cleanToken.length == 0 || pattern.test(cleanToken) == false) ? 'Invalid characters in path' : true;
    }
];


/**
 * Event: Collection Save button
 */
async function save() {
    try {
        emit('close');

        await collections.update(props.collection);
    } catch (error: any) {
        if (axios.isAxiosError(error)) {
            switch (error.response?.status) {
                case 400:
                    pathErrorMessage.value = error.response.data.message;
                    return;
                case 409:
                    pathErrorMessage.value = error.response.data.message;
                    return;
                default:
                    break;
            }
        }
        messages.add('Failed to save collection');
        return;
    }

}

function pathBrowseClick() {
    if (props.collection.id > 0)
        return;

    browserPath.value = props.collection.path;
    dialogBrowser.value = true;
    dialogSelectingFor.value = 0;
}

function pathVariantClick() {
    browserPath.value = props.collection.pathVariant;
    dialogBrowser.value = true;
    dialogSelectingFor.value = 1;
}

function pathSelect(pPath: string) {
    dialogBrowser.value = false;

    if (dialogSelectingFor.value == 0) {
        props.collection.path = pPath;
    } else {
        props.collection.pathVariant = pPath;
    }
}

function addValueToFormat(pValue: string) {
    props.collection.folderFormat += pValue;
}

function changeManaged() {
    nextTick(() => {
        form.value.validate();
    });
}

onMounted(() => {
    nextTick(() => {
        form.value.validate();
    });
});

</script>
<template>
    <v-card>
        <v-card-title>
            <span class="text-h5">Collection</span>
        </v-card-title>
        <v-card-text>
            <v-container>
                <v-form v-model="valid" ref="form">

                    <v-row>

                        <v-col cols="6" md="6">
                            <v-text-field v-model="props.collection.name" label="Name" required :rules="nameRules"
                                hint="Name of the collection" persistent-hint>
                            </v-text-field>
                        </v-col>

                        <v-col cols="3">
                            <v-switch v-model="props.collection.managed" label="Managed" color="primary"
                            hint="Name and Sort files" persistent-hint
                            :value="true" @update:model-value="changeManaged"></v-switch>
                        </v-col>

                        <v-col cols="3">
                            <v-switch v-model="props.collection.readonly" label="Read Only" color="primary"
                                hint="Prevent changes" persistent-hint
                                :value="true"></v-switch>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field v-model="props.collection.path" label="Path" required
                                :readonly="props.collection.id > 0" hint="Path to the files"
                                :error-messages="pathErrorMessage" persistent-hint :rules="pathRules">
                                <template v-slot:append-inner>
                                    <v-tooltip location="start">
                                        <template v-slot:activator="{ props }">
                                            <v-icon v-bind="props" @click="pathBrowseClick">
                                                mdi-file-multiple
                                            </v-icon>
                                        </template>
                                        <span>Browse</span>
                                    </v-tooltip>
                                </template>
                            </v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field v-model="props.collection.pathVariant" label="Path to image variants"
                                hint="If your images are extremely large, variants can be created and stored at this location"
                                persistent-hint :rules="pathVariantRules">
                                <template v-slot:append-inner>
                                    <v-tooltip location="start">
                                        <template v-slot:activator="{ props }">
                                            <v-icon v-bind="props" @click="pathVariantClick">
                                                mdi-file-multiple
                                            </v-icon>
                                        </template>
                                        <span>Browse</span>
                                    </v-tooltip>
                                </template>
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="8">
                            <v-text-field :disabled="!props.collection.managed" v-model="props.collection.folderFormat"
                                placeholder="{Year}/{Month}_{MonthName}" label="Folder Format"
                                :required="props.collection.managed"
                                hint="Folder naming format (eg. {Year}/{Month}_{MonthName})" persistent-hint
                                :rules="folderFormatRules">
                                <template v-slot:append-inner>
                                    <v-menu>
                                        <template v-slot:activator="{ props }">
                                            <v-btn v-bind="props" size="x-small" icon="mdi-dots-vertical"></v-btn>
                                        </template>
                                        <v-list>
                                            <v-list-item v-for="(item, index) in folderFormatOptions" :key="index"
                                                @click="addValueToFormat(item)">
                                                <v-list-item-title>{{ item }}</v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </template>
                            </v-text-field>
                        </v-col>

                        <v-col cols="4">
                            <v-text-field :disabled="!props.collection.managed" v-model="props.collection.fileFormat"
                                placeholder="img" label="Filename Prefix" required hint="File name prefix (eg. img)"
                                persistent-hint :rules="prefixRules">
                            </v-text-field>
                        </v-col>
                    </v-row>
                </v-form>
            </v-container>

        </v-card-text>

        <v-card-actions>

            <v-spacer></v-spacer>
            <v-btn color="blue-darken-1" @click="emit('close')">
                Close
            </v-btn>
            <v-btn v-if="auth.isGlobalAdmin == true" :disabled="!valid" color="blue-darken-1" type="submit" @click="save">
                Save
            </v-btn>
        </v-card-actions>
    </v-card>

    <v-dialog v-model="dialogBrowser" :width="450">
        <v-card>
            <vue-finder :axios="axios" url="/v1/paths" dark :start-path="browserPath"
                @path-select="pathSelect"></vue-finder>
        </v-card>
    </v-dialog>
</template>