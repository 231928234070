<script setup lang="ts">
import router from '../../router';


function create() {

    router.push({ name: 'Collections', replace: true, params: { }, query: {"create": 'true'}});
}
</script>

<template>
    <v-row class="mt-12" justify="center" align="center">
        <v-col cols="12" sm="8" md="6">
            <v-card class="px-5 py-8 rounded-lg" color="#252525" dark>
                <h2 class="mb-5">No Collections</h2>
                <p class="mb-4">You don't have any image collections yet.</p>
                <p class="mb-0">
                    An collection represents a directory containing images
                    To create your first collection, click the button below.</p>
                <v-row justify="center" align="center" class="mt-7">
                    <v-btn class="custom-btn mr-3 px-10" rounded="pill" color="green" dark @click="create">Create Collection</v-btn>
                </v-row>
            </v-card>
        </v-col>
    </v-row>
</template>