import axios from 'axios';
import { defineStore } from 'pinia'

export interface iToken {
    id: number,
    name: string,
    value: string,
    type: string,
    used: boolean,
    owner: string,
    screensaverid: number
};

export enum eTokenType {
    ScreenSaver = "ScreenSaver"
}

export const useTokenStore = defineStore("tokens", {
    state: () => ({
        tokens: [] as Array<iToken>,
    }),
    
    getters: {
        getTokens: (state) => state.tokens,
    },

    actions: {
        async create(pValue: string, pName: string, pType: eTokenType): Promise<iToken> {
            const data = await axios.post('/v1/tokens', { "value": pValue, "name": pName, "type": pType });
            this.tokens.push(data.data);
            return data.data;
        },

        async load() {
            const data = await axios.get('/v1/tokens');
            this.tokens = [];

            data.data.forEach(function (pValue : iToken) {
                this.tokens.push(pValue);
            }, this);

            return this.tokens;
        },

        async delete(pToken: iToken) {
            const data = await axios.delete('/v1/tokens/' + pToken.id);

            this.tokens = this.tokens.filter(token => token.id !== pToken.id)
        },

        async save(pToken: iToken) {
            const data = await axios.put('/v1/tokens/save/' + pToken.id, pToken);
            
            return data.data;
        }
    }
});
