<script lang="ts" setup>
import { ImageScale, ItemObjectInterface, ObjectInterface, ObjectSearchMenuItem } from 'rundown-common';
import { PropType } from 'vue';
import { cItem } from '../../domain/item';
import { ref } from 'vue';
import { useImageScaling } from '../../utility/useImageScaling';
import { useObjectSearch } from '../../utility/useObjectSearch';
import { VCombobox } from 'vuetify/lib/components/index.mjs';
import { nextTick } from 'vue';
import { cItemObject } from '../../domain/ItemObject';
import { cObject } from '../../domain/Object';
import { watch } from 'vue';
import { useObjectsStore } from '../../stores/objects';
import BoundingBox from './BoundingBox.vue';

interface BoundingBoxInterface extends ImageScale {
    object: ItemObjectInterface;
}

const props = defineProps({
    item: {
        type: cItem,
        required: true
    },
    imageElement: {
        type: HTMLElement,
        required: true
    },
    selectedObject: {
        type: Object as PropType<ItemObjectInterface | null>,
        required: false,
    }
});

const emit = defineEmits<{
    (e: 'box-selected', pObject: ItemObjectInterface | null): void;
    (e: 'box-dblclick', pEvent: MouseEvent, pObject: ItemObjectInterface): void;
    (e: 'box-update', pObject: ItemObjectInterface): void;
    (e: 'box-save-input', pValue: ItemObjectInterface | ObjectInterface | string | null): void;
}>();

const objects = useObjectsStore();

const boxes = ref<Array<BoundingBoxInterface>>([]);
const boxSelected = ref<BoundingBoxInterface | null>(null);
const objectSelected = ref<ItemObjectInterface | ObjectSearchMenuItem | null>(null);

const imgElement = ref<HTMLImageElement | null>(null);
const imageLoaded = ref(false);
const imageScaler = useImageScaling(imgElement);
const inputPosition = ref<HTMLElement | null>(null);
const inputStyle = ref('');
const inputVisible = ref(false);
const inputBox = ref<VCombobox | null>(null)

const imageTooltip = ref(false);
const imageToolTipV = ref('');
const tooltipPosition = ref<any>({});

const { objectIsMenuitem, objectSearchInput, objectGetName, objectsAvailable, objectSearch } = useObjectSearch();


const updateObjects = () => {
    boxes.value = [];
    props.item.objectSaved.forEach((pObject: ItemObjectInterface) => {
        if (pObject.object.height && pObject.object.width) {
            addObject(pObject);
        }
    });

    if(boxSelected.value != null) {
        const foundBox = boxes.value.find(box => box.object.getId() === boxSelected.value?.object.getId());
        if (foundBox) {
            boxSelected.value = foundBox;
        }
    }

    updateBoxes();
}

/**
 * Clear boxes and selections
 */
function clearAll() {
    clearSelection();
    boxes.value = [];
    updateBoxes();
}

function clearSelection() {
    boxSelected.value = null;
    inputVisible.value = false;
    objectSelected.value = null;
}

/**
 * Add an object
 */
function addObject(pObject: ItemObjectInterface, pUpdateBoxes: boolean = false) {

    boxes.value.push({ object: pObject } as BoundingBoxInterface);
    if (pUpdateBoxes)
        updateBoxes();
}

function boxSelect(pObject: ItemObjectInterface | null) {
    clearSelection();

    if (pObject === null) {
        boxSelected.value = null;
        return;
    }

    const foundBox = boxes.value.find(box => box.object === pObject);
    if (foundBox) {
        boxSelected.value = foundBox;
    }
}

/**
 * Update all boxes based on image scale
 */
function updateBoxes() {
    if (!imgElement.value) return;

    boxes.value.forEach((box: BoundingBoxInterface) => {
        Object.assign(box, imageScaler.scaleFromRealImage(box.object.getBoundingBox()));
    });

    inputUpdateStyle();
}

/**
 * Take an X/Y based on the displayed image, and calculate the offsets in the actual image
 */
function createObject(scaledX: number, scaledY: number, pSize: number): ImageScale | null {
    if (!imgElement.value) return null;

    const scale = imageScaler.calculateScale();
    const { naturalWidth, naturalHeight } = imgElement.value;
    const { clientWidth: canvasWidth, clientHeight: canvasHeight } = imgElement.value;

    let { x, y } = imageScaler.scaleToRealImage(scaledX, scaledY);

    // Check if the click is outside the image area
    const imageX = (canvasWidth - naturalWidth * scale) / 2;
    const imageY = 0;
    const imageRight = imageX + naturalWidth * scale;
    let imageBottom = imageY + naturalHeight * scale;

    if (x < 0 || y < 0 || scaledX < imageX || scaledX > imageRight || scaledY > imageBottom) {
        // Click is outside the image area
        return null;
    }

    x = Math.round(x - (pSize / 2));
    y = Math.round(y - (pSize / 2));

    const box: ImageScale = {
        x: Math.round(x),
        y: Math.round(y),
        width: Math.round(pSize),
        height: Math.round(pSize)
    };

    return box;
}

/**
 * Update the position of the input box
 */
const inputUpdateStyle = () => {
    const scale = imageScaler.calculateScale();
    if (!inputPosition.value || boxSelected.value == null)
        return;

    const box = imageScaler.scaleFromRealImage(boxSelected.value.object.getBoundingBox())
    const autocompleteWidth = 200 + (box.width * scale);
    const parentRect = inputPosition.value.getBoundingClientRect();
    const parentRightEdge = parentRect.width;

    const top = box.y + box.height;
    let left = box.x + (box.width / 2) - (autocompleteWidth / 2);

    // Check if the right edge of the autocomplete box exceeds the parent's right edge
    if (left + autocompleteWidth > parentRightEdge) {
        // Adjust left to ensure the box stays within the parent element
        left = parentRightEdge - autocompleteWidth;
    }

    // Ensure left is not negative
    left = Math.max(left, 0);

    inputStyle.value = `top: ${top}px; left: ${left}px; 
                        width: ${autocompleteWidth}px;`;
}

async function toggleInputVisibility() {
    // Make the input visible
    inputVisible.value = true;
    if (inputBox.value) {
        inputBox.value.menu = false;
    }
    // Wait for the next DOM update to ensure inputBox.value is set
    await nextTick();

    // Check if inputBox is now available
    if (!inputBox.value) {
        return;
    }

    // reset the search input, then show menu and input box
    objectSearchInput('');
    inputBox.value.menu = true;
    inputBox.value.focus();
}

/**
 * Selects a box by its index and emits an event with the selected box.
 * If the index is out of bounds, emits null.
 */
function boxClicked(pBox: BoundingBoxInterface): void {
    const wasVisible = inputVisible.value;
    if (boxSelected.value?.object.getId() == pBox.object.getId())
        return;

    clearSelection();

    boxSelected.value = pBox;
    emit('box-selected', pBox.object);
    if (wasVisible) {
        inputUpdateStyle();
        toggleInputVisibility();
    }
}

/**
 * Handle a double click on a bounding box
 */
function boxDblClick(pEvent: MouseEvent, pBox: BoundingBoxInterface) {

    boxSelected.value = pBox;

    const targetElement = pEvent.target as HTMLElement;
    inputPosition.value = targetElement.parentElement;
    
    inputUpdateStyle();
    toggleInputVisibility();
    emit('box-selected', pBox.object);
}

/**
 * Update the position or size of a box
 */
function updateDimension(box: BoundingBoxInterface, dimension: 'x' | 'y' | 'width' | 'height', newValue: number) {

    const changeInPixels = (newValue - box[dimension]);
    box.object.object[dimension] = Math.round(box.object.object[dimension] + changeInPixels);
    updateBoxes();
}

/**
 * Box has finished being dragged
 */
function boxDragEnd(box: BoundingBoxInterface) {
    emit('box-update', box.object);
}


/**
 * Trigger a save of the input box
 */
function inputSave() {

    if (objectIsMenuitem(objectSelected.value)) {
        emit('box-save-input', objectSelected.value.object as cObject)

    } else if (objectSelected.value instanceof cItemObject) {
        emit('box-save-input', objectSelected.value)

    } else if (objectSelected.value instanceof cObject) {
        emit('box-save-input', objectSelected.value)

    } else if (typeof objectSelected.value === 'string') {
        emit('box-save-input', objectSelected.value)
    } else {
        emit('box-save-input', objectSelected.value)
    }

    if (inputBox.value)
        inputBox.value.menu = false;
    inputVisible.value = false;
}


/**
 * Listen for the image to be displayed
 */
const setupImageLoadListeners = (img: HTMLImageElement) => {
    const onLoad = () => {
        imageLoaded.value = true;
    };

    const onError = () => {
        console.error('Image failed to load.');
        img.removeEventListener('load', onLoad); // Remove onLoad listener if error occurs
        imageLoaded.value = false;
    };

    img.addEventListener('load', onLoad, { once: true });
    img.addEventListener('error', onError, { once: true });
};

/**
 * Wait for the image tag to become available
 */
watch(() => imgElement.value, (img) => {
    if (img) {
        if (img.complete && img.naturalHeight !== 0) {
            imageLoaded.value = true;
        } else {
            setupImageLoadListeners(img);
        }
    }
});

/**
 * Wait for the image to load before doing detection
 */
watch(imageLoaded, (isLoaded) => {
    if (isLoaded && !objects.isLoading) {
        updateObjects();
    }
});

// object is selected
watch(() => props.selectedObject, (newVal) => {
    objectSelected.value = newVal ?? null;
});

watch(() => props.item.objectSaved, () => {
    if (!objects.isLoading) {
        updateObjects();
    }
});

const observer = new MutationObserver(() => {
    const img = props.imageElement.querySelector('img');
    if (img) {
        imgElement.value = img;
        observer.disconnect();
    }
});

observer.observe(props.imageElement, { childList: true, subtree: true });

defineExpose({ updateBoxes, addObject, createObject, clearAll, clearSelection, boxSelect });

</script>

<template>
    <template v-for="box in boxes">
        <BoundingBox v-bind="box"
            :selected="boxSelected == box" @click.stop="boxClicked(box)" @dblclick.stop="boxDblClick($event, box)"
            @update="boxDragEnd(box)" @update:left="newValue => updateDimension(box, 'x', newValue)"
            @update:top="newValue => updateDimension(box, 'y', newValue)"
            @update:width="newValue => updateDimension(box, 'width', newValue)"
            @update:height="newValue => updateDimension(box, 'height', newValue)" />
    </template>

    <v-tooltip v-model="imageTooltip" :style="tooltipPosition">
        {{ imageToolTipV }}
    </v-tooltip>

    <v-combobox v-if="inputVisible" ref="inputBox" class="input" v-model="objectSelected" :items="objectsAvailable"
        @click.stop @dblclick.stop :item-title="objectGetName" @update:search="objectSearchInput" density="compact" hide-no-data
        hide-details label="Type a name" :style="inputStyle" return-object>

        <template v-slot:item="{ item, props }">
            <v-list-item v-if="item.value?.isHeadline == true" class="input-headline" title="">
                <v-list-item-title>{{ item.value.text }}</v-list-item-title>
            </v-list-item>
            <v-list-item v-else v-bind="props" title="">
                <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
        </template>
        <template v-slot:append>
            <div class="checkmark" @click.stop="inputSave">
                <v-icon color="green-darken-4">mdi-check</v-icon>
            </div>
        </template>
    </v-combobox>
</template>

<style scoped>
.bounding-box {
    z-index: 1;
    position: absolute;
    border: 2px solid rgba(0, 255, 0, 0.8);
    /* Changed to a bright green border */
    background-color: rgba(0, 255, 0, 0.2);
    /* Changed to a light green background */
    box-shadow: 3px 3px 5px rgba(50, 50, 50, 0.5);
    border-radius: 10px;
}

.bounding-box-selected {
    border: 3px solid rgba(255, 165, 0, 0.8);
    /* Changed to a thicker, orange border */
    background-color: rgba(255, 165, 0, 0.2);
    /* Changed to a light orange background */
    box-shadow: 0px 0px 15px rgba(255, 165, 0, 0.5);
    /* Added a glowing orange shadow */
    transform: scale(1.05);
    /* Slightly enlarges the selected element */
}

.input {
    z-index: 10;
    position: absolute;
    border: 2px solid rgba(50, 150, 250, 0.8);
    background-color: rgba(50, 150, 250, 0.9);
    box-shadow: 3px 3px 5px rgba(50, 50, 50, 0.5);
    border-radius: 10px;
}

.input-headline {
    z-index: 10;
    font-weight: bold;
    color: grey;
}

.checkmark {
    z-index: 10;
    color: #ffffff;


    background-color: #007bff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    padding: 5px;
    transition: transform 0.3s ease;
}

.checkmark:hover {
    transform: scale(1.1);
}</style>
