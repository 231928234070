import axios from "axios";
import { PluginData } from "rundown-common";

type PluginElement = {
    [key: string]: { [componentName: string]: string };
};

export class Plugin {
    private elements: PluginElement;
    private data: PluginData;

    constructor(pData: PluginData) {
        this.data = pData;
        this.elements = {};
    }

    getData(): PluginData {
        return this.data;
    }

    normalizeKey(key: string): string {
        return key.toLowerCase();
    }

    addComponent(pElementName: string, pName: string, pContent: string) {
        const normalizedElementName = this.normalizeKey(pElementName);
        const normalizedName = this.normalizeKey(pName);

        if (!this.elements[normalizedElementName]) {
            this.elements[normalizedElementName] = {};
        }
        this.elements[normalizedElementName][normalizedName] = pContent;
    }

    hasComponent(pElementName: string, pName: string): boolean {
        const normalizedElementName = this.normalizeKey(pElementName);
        const normalizedName = this.normalizeKey(pName);

        return !!this.elements[normalizedElementName]?.[normalizedName];
    }

    getComponent(pElementName: string, pName: string): string | null {
        const normalizedElementName = this.normalizeKey(pElementName);
        const normalizedName = this.normalizeKey(pName);

        return this.hasComponent(normalizedElementName, normalizedName)
            ? this.elements[normalizedElementName][normalizedName]
            : null;
    }

    getFiles(pElementName: string, pName: string) {
        const normalizedElementName = this.normalizeKey(pElementName);
        const normalizedName = this.normalizeKey(pName);

        return this.elements[normalizedElementName][normalizedName];
    }

    async loadComponents() {
        this.elements = {};

        for (const element of this.data.elements) {
            for (const [name, path] of Object.entries(element.files)) {

                try {
                    const content = await this.loadFile(this.data.id, element.name, name);

                    this.addComponent(element.name, name, content);

                } catch (error) {
                    console.error(`Error loading file ${name} for plugin ${this.data.id}:`, error);
                }
            }
        }
    }
    
    async loadFile(pPluginId: string, pElement: string, pFile: string) {
        try {
            const response = await axios.get(`/v1/plugin/${pPluginId}/file/${pElement}/${pFile}`);
            return response.data;
        } catch (error) {
            console.error('Failed to load plugin file: ', error);
            throw error;
        }
    }

}