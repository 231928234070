import axios from 'axios';
import { defineStore } from 'pinia'
import { useFilterStore } from './filters';
import { cItem } from '../domain/item';
import { ItemData } from 'rundown-common';
import { FilterGroup } from '../domain/FilterParameters/Filter';

export interface iFilters {
    collectionId: number;
    startMonth: number;
    dateStart: Date | null;
    dateEnd: Date | null;
    onthisdayTz: string;
    filtergroups: FilterGroup[];
    searchInTags: string[];
    latitude: number;
    longitude: number;
    radius: number;
}

export interface iScreenSaver {
    id: number,
    name: string,
    owner: string,
    filters: iFilters,
    random: boolean,
    seconds: number
};

export const useScreenSaverStore = defineStore("screensavers", {
    state: () => ({
        savers: [] as Array<iScreenSaver>,
    }),

    getters: {
        getScreenSavers: (state) => state.savers
    },

    actions: {
        get(pId: number): iScreenSaver | undefined {
            return this.savers.find(item => item.id === pId);
        },

        async loadItem(pValue: iScreenSaver) {

            const screenSaver: iScreenSaver = {
                id: pValue.id,
                name: pValue.name,
                owner: pValue.owner,
                filters: {
                    collectionId: pValue.filters.collectionId,
                    startMonth: pValue.filters.startMonth,
                    dateStart: typeof pValue.filters.dateStart === 'number' ? new Date(pValue.filters.dateStart * 1000) : null,
                    dateEnd: typeof pValue.filters.dateEnd === 'number' ? new Date(pValue.filters.dateEnd * 1000) : null,
                    onthisdayTz: pValue.filters.onthisdayTz,
                    filtergroups: pValue.filters.filtergroups.map((fg: any) => new FilterGroup(fg)),
                    searchInTags: pValue.filters.searchInTags,
                    latitude: pValue.filters.latitude,
                    longitude: pValue.filters.longitude,
                    radius: pValue.filters.radius,
                },
                random: pValue.random,
                seconds: pValue.seconds
            };
            return screenSaver;
        },

        async load() {
            const data = await axios.get('/v1/screensaver');
            this.savers = [];
    
            for (const pValue of data.data) {
                this.savers.push(await this.loadItem(pValue));
            }            
    
            return this.savers;
        },

        async update(pData: iScreenSaver) {
            const ss = await this.loadItem(pData);
            const index = this.savers.findIndex(item => item.id === ss.id);
            if (index !== -1) {
                this.savers[index] = ss;
            }
            return ss;
        },

        async remove(pScreenSaver: iScreenSaver) {
            
            const data = await axios.delete('/v1/screensaver/' + pScreenSaver.id.toString());
            if(data.status == 204) {
                this.savers = this.savers.filter(saver => saver.id !== pScreenSaver.id)
            }
        },

        async create(pName: string, pRandom: boolean, pSeconds: number): Promise<iScreenSaver> {
            const filters = useFilterStore();
            var params = filters.getParameters;
            params += '&name=' + pName;
            params += '&random=' + pRandom;
            params += "&seconds=" + pSeconds;

            const data = await axios.post('/v1/screensaver/create' + '?' + params, {});
            const ss = await this.loadItem(data.data);
            this.savers.push(ss);
            return ss;
        },

        async updateFilters(pScreenSaver: iScreenSaver): Promise<iScreenSaver> {
            const filters = useFilterStore();
            var params = filters.getParameters;

            params += "&setfilters=true";
            params += "&random=" + pScreenSaver.random;
            
            const data = await axios.put('/v1/screensaver/save/' + pScreenSaver.id.toString() + '?' + params, {});
            return this.update(data.data);
        },

        async save(pScreenSaver: iScreenSaver, pRandom: boolean | undefined) {
            var params = "seconds=" + pScreenSaver.seconds;

            if (pRandom !== undefined) {
                params += "&random=" + pRandom;
            } else {
                params += "&random=" + pScreenSaver.random;
            }

            const data = await axios.put('/v1/screensaver/save/' + pScreenSaver.id.toString() + '?' + params, {});
            return this.update(data.data);
        },

        async next(pScreenSaver: iScreenSaver) {
            var result = [] as Array<cItem>;

            const data = await axios.get('/v1/screensaver/next/' + pScreenSaver.id.toString() );
            data.data.forEach((pItem:ItemData) => {
                result.push(new cItem(pItem));
            });
            return result;
        }
    }
});
