/**
 * ArrayMap Class
 * 
 * This data structure combines the features of both an array and a map, 
 * allowing for ordered key-based and index-based access to items. 
 * The class also supports tracking a 'current selected item', providing 
 * methods to navigate to the next or previous item in the sequence.
 * 
 * Main Features:
 * 1. Maintains the order of keys based on their insertion order.
 * 2. Allows direct index-based and key-based access.
 * 3. Supports operations to set, get, and delete items.
 * 4. Can track a 'current selected item' and provides methods (next and previous) 
 *    to navigate through items. The navigation methods have been enhanced to roll 
 *    around: when the last item is current and `next` is called, it rolls over to the 
 *    first item; when the first item is current and `previous` is called, it rolls over 
 *    to the last item.
 * 
 * Usage scenarios include situations where both key and index-based lookups 
 * are frequently required, and where ordered navigation through items is necessary.
 */
export class ArrayMap<K, V> {
    private map: Map<K, V> = new Map();
    private array: K[] = [];

    /**
     * Delete all items
     */
    clear(): void {
        this.map.clear();
        this.array = [];
    }

    /** Returns an array of { item: value, i: key } objects in insertion order. */
    entries(): Array<V> {
        return this.array.map(key => this.map.get(key)!);
    }
    
    /** 
     * Executes the provided callback function once for each key-value pair 
     * present in the ArrayMap in insertion order.
     */
    forEach(callback: (value: V, key: K, map: Map<K, V>) => void): void {
        this.array.forEach(key => {
            const value = this.map.get(key);
            if (value !== undefined) {
                callback(value, key, this.map);
            }
        });
    }
    
    /**
     * Tests whether at least one key-value pair in the ArrayMap passes 
     * the test implemented by the provided function.
     */
    some(callback: (value: V, key: K, map: Map<K, V>) => boolean): boolean {
        for (const key of this.array) {
            const value = this.map.get(key);
            if (value !== undefined && callback(value, key, this.map)) {
                return true;
            }
        }
        return false;
    }

    /** 
     * Tests whether all elements in the map pass the test implemented by the provided function. 
     * Iterates over items in the order they were inserted.
     */
    every(callback: (value: V, key: K, index: number) => boolean): boolean {
        for (let i = 0; i < this.array.length; i++) {
            const key = this.array[i];
            const value = this.map.get(key);
            if (!callback(value!, key, i)) {  // We're assuming value exists for every key in the array.
                return false;
            }
        }
        return true;
    }

    /**
     * Does this key exist in this map
     */
    hasKey(key: K) : boolean { 
        return this.map.has(key);
    }

    /** 
     * Retrieves a value from the map using its key.
     */
    getByKey(key: K): V | undefined {
        return this.map.get(key);
    }
    
    /** 
     * Retrieves a value from the map using its numerical position (index). 
     */
    getByIndex(index: number): V | undefined {
        const key = this.array[index];
        return this.map.get(key);
    }
    
    /**
     * Retrieves the index of a key in the map.
     */
    getIndexByKey(key: K): number | undefined {
        for (let index = 0; index < this.array.length; index++) {
            if (this.array[index] === key) {
                return index;
            }
        }
        return undefined; // Return undefined if the key is not found
    }

    /** 
     * Retrieves a key from the array using its numerical position (index). 
     */
    getKeyByIndex(index: number): K | undefined {
        return this.array[index];
    }

    /** 
     * Returns the number of items in the map.
     */
    size(): number {
        return this.map.size;
    }

    length(): number {
        return this.map.size;
    }

    /** 
     * Sets a value in the map and updates the key array.
     */
    set(key: K, value: V): void {
        if (!this.map.has(key)) {
            this.array.push(key);
        }
        this.map.set(key, value);
    }

    /**
     * Removes an item from the map and adjusts the current index if necessary.
     */
    delete(key: K): boolean {
        const index = this.array.indexOf(key);
        if (index !== -1) {    
            this.array.splice(index, 1);
        }
        return this.map.delete(key);
    }
        
    /**
     * Creates a sliced version of the ArrayMap from start to end (exclusive) indices.
     * 
     * @param start The zero-based index at which to start extraction.
     * @param end The index before which to end extraction. The slice extracts up to but not including end.
     * @returns A new ArrayMap containing the sliced section.
     */
    slice(start: number = 0, end: number = this.array.length): ArrayMap<K, V> {
        const slicedArrayMap = new ArrayMap<K, V>();

        for (let i = start; i < end && i < this.array.length; i++) {
            const key = this.array[i];
            const value = this.map.get(key);
            if (value !== undefined) {
                slicedArrayMap.set(key, value);
            }
        }

        return slicedArrayMap;
    }

}
