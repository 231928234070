<script setup lang="ts">
import { ref } from "vue";
import FilterOptions from "./FilterOptions.vue";
import UserLogin from "../../dialogs/user/DialogUserLogin.vue";
import UserRegister from "../../dialogs/user/DialogUserRegister.vue";
import { useAuthStore } from '../../stores/auth';
import { useItemStore } from "../../stores/items";
import { useFilterStore } from "../../stores/filters";
import { useSettingsStore } from "../../stores/settings";

import { useMessageStore } from "../../stores/messages";

const auth = useAuthStore();
const items = useItemStore();
const settings = useSettingsStore();

const handleSignUp = () => {
    auth.showRegisterDialog = true;
};

const handleLogin = () => {
    auth.showLoginDialog = true;
};

const handleLogout = () =>
    auth.logout();

async function handleDownload() {
    if(items.getTotalRecords == 0) {
        useMessageStore().add( 'No items available');
        return;
    }
    
    var response = await items.download();

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(new Blob([response]));
    link.download = useFilterStore().getKeywordsAndPeopleAsString + '.zip';
    link.click();
    window.URL.revokeObjectURL(link.href);
}

</script>

<template>
    <UserRegister></UserRegister>
    <UserLogin></UserLogin>
    <v-menu location="start">
        <template v-slot:activator="{ props }">
            <v-btn icon v-bind="props">
                <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
        </template>

        <v-list>
            <template v-if="!auth.isAuthed">
                <v-list-item prepend-icon="mdi-account-plus" @click="handleSignUp">
                    <v-list-item-title>Register</v-list-item-title>
                </v-list-item>

                <v-list-item prepend-icon="mdi-login" @click="handleLogin">
                    <v-list-item-title>Login</v-list-item-title>
                </v-list-item>
            </template>


            <template v-if="auth.isAuthed">
                <v-list-item prepend-icon="mdi-logout" @click="handleLogout">
                    <v-list-item-title>Logout</v-list-item-title>
                </v-list-item>
            </template>

            <FilterOptions :listMode="false"></FilterOptions>

            <v-divider></v-divider>

            <v-list-item prepend-icon="mdi-download" @click="handleDownload">
                <v-list-item-title>Download Current</v-list-item-title>
            </v-list-item>


        </v-list>


    </v-menu>
</template>

