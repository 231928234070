<script setup lang="ts">
import { computed, ref} from 'vue';
import { useMessageStore } from '../../stores/messages';
import { iEventLog } from '../../stores/websocket';

const messages = useMessageStore();
const displayMenu = ref(false);

var status = computed(() => {
    const statuses = messages.getStatuses;
    if (statuses.length === 0) return;

    setTimeout(() => {
        messages.getStatuses.shift();
    }, 15000);

    return statuses[statuses.length-1];
});

function formatDate(pStatus : iEventLog) {

    return new Date(pStatus.when.date).toLocaleString('en-us', {
        weekday: 'long', day: '2-digit', year: 'numeric',
        month: 'long', hour: '2-digit', minute: '2-digit', hour12: false,
        timeZoneName: undefined
    }).toString();
}
</script>

<template>
    <v-menu v-model="displayMenu" :close-on-content-click="false" location="bottom" transition="scale-transition">
        <template v-slot:activator="{ props }">
            <v-btn icon v-bind="props">
                <v-tooltip v-if="status != undefined" activator="parent" location="bottom" model-value @update:model-value="">{{status?.message}}</v-tooltip>
                <v-progress-circular v-if="status != undefined" color="primary" indeterminate>
                    <v-icon size="small">mdi-bell-outline
                    </v-icon>
                </v-progress-circular>
                <v-icon v-else>mdi-bell-outline
                </v-icon>
            </v-btn>
        </template>
        
        <v-card min-width="200">
            <v-list>
                <v-list-item prepend-icon="mdi-bell-outline" title="Notifications"></v-list-item>
            </v-list>

            <v-divider></v-divider>

            <v-list v-if="status != undefined">
                <v-list-item v-for="item in messages.getStatuses" :value="item.id" :title="item.message" :subtitle="formatDate(item)">
                </v-list-item>
            </v-list>
                
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="displayMenu = false">
                    Done
                </v-btn>
            </v-card-actions>
            </v-card>
    </v-menu>

</template>