<script setup lang="ts">

import { ref, onMounted } from 'vue';
import { useAuthStore } from '../../stores/auth';
import { useCollectionStore } from '../../stores/collections';
import Access from '../../dialogs/DialogAccess.vue';
import collection from './AdminCollection.vue';
import { useRouter } from 'vue-router'
import { CollectionData } from 'rundown-common';

const auth = useAuthStore();
const collections = useCollectionStore();

const dialog = ref(false);
const selectedCollection = ref({} as CollectionData);
const tmpCollection = ref({} as CollectionData);
const router = useRouter();

onMounted(() => {
    if(router.currentRoute.value.query?.create) {
        router.push({ name: 'Collections', replace: true});
        create();
    }
});

/**
 * Event: Create Collection button
 */
function create() {
    tmpCollection.value = {} as CollectionData;
    selectedCollection.value = tmpCollection.value;
    dialog.value = true;
}

/**
 * Event: Select Collection
 */
function select(pCollection: CollectionData) {
    tmpCollection.value = Object.assign({}, pCollection);
    selectedCollection.value = pCollection;
    dialog.value = true;
}

function close() {
    dialog.value = false;
}

/**
 * Event: Scan Collection Button
 */
function scan(pCollection: CollectionData) {
    useCollectionStore().scan(pCollection);
}

function sort(pCollection: CollectionData) {
    useCollectionStore().sort(pCollection);
}

function importmeta(pCollection: CollectionData) {
    useCollectionStore().importmeta(pCollection);
}
function exportmeta(pCollection: CollectionData) {
    useCollectionStore().exportmeta(pCollection);
}

function collectionExport(pCollection: CollectionData) {
    useCollectionStore().export(pCollection);
}

</script>

<template>
    <v-container>

        <v-row justify="center">
            <v-btn v-if="auth.isGlobalAdmin == true" size="small" color="primary" @click="create">
                Create Collection
            </v-btn>
            <v-dialog v-model="dialog" max-width="700">
                <collection :collection="tmpCollection" @close="close"></collection>
            </v-dialog>

        </v-row>
    </v-container>

    <v-container>
        <v-table>
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Path</th>
                    <th>Last Scan</th>
                    <th>Managed</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(collection, index) in collections.getCollections" :key="collection.id"
                    @dblclick="select(collection)">
                    <td>{{ collection.name }}</td>
                    <td>{{ collection.path }}</td>
                    <td>{{ collection.lastscan }}</td>
                    <td>
                        <v-icon :icon="collection.managed ? 'mdi-check-circle-outline' : 'mdi-close-circle-outline '">
                        </v-icon>
                    </td>
                    <td>
                        <v-row>
                            <Access :collection="collection"></Access>

                            <v-divider class="mx-4" vertical></v-divider>
                            <v-tooltip location="start">
                                <template v-slot:activator="{ props }">
                                    <v-icon v-bind="props" @click="scan(collection)">mdi-file-search-outline
                                    </v-icon>
                                </template>
                                <span>Scan for new items</span>
                            </v-tooltip>

                            <v-divider class="mx-4" vertical></v-divider>
                            <v-tooltip location="start">
                                <template v-slot:activator="{ props }">
                                    <v-icon v-bind="props" @click="sort(collection)">mdi-sort-ascending
                                    </v-icon>
                                </template>
                                <span>Sort items</span>
                            </v-tooltip>

                            <v-divider class="mx-4" vertical></v-divider>
                            <v-tooltip location="start">
                                <template v-slot:activator="{ props }">
                                    <v-icon v-bind="props" @click="importmeta(collection)">mdi-tPageMetaDatable
                                    </v-icon>
                                </template>
                                <span>Import item metadata</span>
                            </v-tooltip>

                            <v-divider class="mx-4" vertical></v-divider>
                            <v-tooltip location="start">
                                <template v-slot:activator="{ props }">
                                    <v-icon v-bind="props" @click="exportmeta(collection)">mdi-export
                                    </v-icon>
                                </template>
                                <span>Export item metadata</span>
                            </v-tooltip>

                            <v-divider class="mx-4" vertical></v-divider>
                            <v-tooltip location="start">
                                <template v-slot:activator="{ props }">
                                    <v-icon v-bind="props" @click="collectionExport(collection)">mdi-file-delimited
                                    </v-icon>
                                </template>
                                <span>Export items to CSV</span>
                            </v-tooltip>
                        </v-row>


                    </td>
                </tr>
            </tbody>
        </v-table>

    </v-container>
</template>
