<script setup lang="ts">

import axios from 'axios';
import { ref, watch } from 'vue';
import { cItem, ThumbnailSize } from '../domain/item';
import { useZoomImageWheel } from "@zoom-image/vue"
import { useImageScaling } from '../utility/useImageScaling';

const props = defineProps({
    item: {
        type: cItem,
        required: false,
        default: new cItem()
    },
    itemid: {
        type: Number,
        required: false
    },
    thumbnail: {
        type: String,
        default: ThumbnailSize.Small
    },
    src: {
        type: String,
        default: ""
    },
    height: {
        type: String,
        default: "auto"
    },
    cover: {
        type: Boolean,
        default: false
    },
    shared: {
        type: String,
        default: "",
        required: false
    },
    canZoom: {
        type: Boolean,
        default: false,
        required: false
    }
});

const emit = defineEmits<{
    (e: 'zoom-changed', zoomImageState: {
        currentRotation: number;
        currentZoom: number;
        enable: boolean;
        currentPositionX: number;
        currentPositionY: number;
    }): void
    (e: 'pointer-move', pEvent: PointerEvent, x: number, y: number): void
}>();

const imageCurrent = ref("");
const imageCurrentId = ref(0);
const imageSrc = ref("");
const imageUrl = ref("");
const imagemissing = ref(false);

const { createZoomImage: createZoomImageWheel, zoomImageState } = useZoomImageWheel();
const imageHoverContainerRef = ref<HTMLDivElement>({} as HTMLDivElement);
const imgElement = ref<HTMLImageElement | null>(null);
const imageScaling = useImageScaling(imgElement);

watch(zoomImageState, () => {
    emit('zoom-changed', zoomImageState);
});

/**
 * Generate url to image
 */
function getImageUrl(pItemId: number, pThumbnail: string | ThumbnailSize = ThumbnailSize.Original, pFormat: string = ''): string {

    var query = '?';
    if (pThumbnail != ThumbnailSize.Original) {
        query += 'pThumbnail=' + pThumbnail;
    }
    if (pFormat != '') {
        if (query.length > 1)
            query += '&';
        query += 'pFormat=' + pFormat;
    }

    var path = "/v1/item/" + pItemId;

    if (props.shared !== undefined && props.shared.length > 0) {
        path = "/v1/share/" + props.shared;
    }
    return axios.defaults.baseURL + path + "/image" + query;
};

/**
 * Load image
 */
async function loadImage() {
    const { src: initialSrc, item, itemid, thumbnail, shared } = props;
    imagemissing.value = false;

    const itemId = item.getId() > 0 ? item.getId() : itemid ?? 0;
    if (!initialSrc && itemId === 0) {
        imageSrc.value = '';
        return;
    }

    let src = initialSrc;
    if (!src) {
        src = itemId > 0 ? item.getImageUrl(thumbnail, '', shared) : getImageUrl(itemId, thumbnail);
        if (src === imageUrl.value) {
            return;
        }
        imageSrc.value = '';
    }

    imageUrl.value = src;
    imageCurrentId.value = itemId;

    try {
        const imageBlob = await getImageBlob(src);
        if (!imageBlob) {
            imagemissing.value = true;
            return;
        }

        imagemissing.value = false;
        imageSrc.value = URL.createObjectURL(imageBlob);
        imageCurrent.value = src;
    } catch (e) {
        console.error(e);
    }
}



/**
 * Get the image blob
 * 
 * @param pSrc 
 */
async function getImageBlob(pSrc: string) {
    try {
        const resp = await axios.get(pSrc, { responseType: "blob" });
        return resp.data;
    } catch (error) {
        //messages.add(error);
    }
    return false;
}

/**
 * Pointer movement. Scale to real image X/Y coordinates
 */
function pointermove(pEvent: PointerEvent) {
    const { x, y } = imageScaling.scaleToRealImage(pEvent.offsetX, pEvent.offsetY);
    if (x < 0 || y < 0)
        return;

    emit('pointer-move', pEvent, x, y);
}

/**
 * Watch for changes to Image properties
 */
watch(props, async function () {
    await loadImage();

    const setupImageObserver = (innerDiv: Element, callback: (div: Element) => void): void => {
        const imageObserver = new MutationObserver(() => {
            if (innerDiv.querySelector('img')) {
                imageObserver.disconnect(); // Stop observing once the IMG is found
                callback(innerDiv);
            }
        });

        // Start observing innerDiv for childList changes
        imageObserver.observe(innerDiv, { childList: true, subtree: true });
    };

    if (imageHoverContainerRef.value) {
        const innerDiv = imageHoverContainerRef.value.querySelector('div.v-responsive.v-img.v-img--booting');
        if (innerDiv) {
            setupImageObserver(innerDiv, div => {
                imgElement.value = imageHoverContainerRef.value.querySelector('img');
                if (props.canZoom) {
                    createZoomImageWheel(div as HTMLDivElement);
                }
            });
        }
    }
}, { immediate: true });

</script>
<template>
    <div ref="imageHoverContainerRef" :min-height="height" :height="height">
        <v-img v-if="imageSrc != ''" :aspect-ratio="1" :src="imageSrc" :cover="cover" :height="height"
            @pointermove="pointermove">
            <slot name="overlay"></slot>
            <slot name="header"></slot>

            <template v-slot:placeholder>
                <v-row v-if="imagemissing" class="fill-height ma-0" align="center" justify="center">
                    <v-icon>mdi-image-broken</v-icon>
                </v-row>
                <v-row v-else class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular indeterminate></v-progress-circular>
                </v-row>
            </template>



            <div class="" style="position: relative">
                <slot name="footer"></slot>
            </div>
        </v-img>

        <template v-else>
            <v-card :height="height">
                <slot name="header"></slot>
                <v-row v-if="imagemissing" class="fill-height ma-0" align="center" justify="center">
                    <v-icon>mdi-image-broken</v-icon>
                </v-row>
                <v-row v-else class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular indeterminate></v-progress-circular>
                </v-row>
            </v-card>
        </template>
    </div>
</template>