<script setup lang="ts">
import { onMounted, onUnmounted, ref, computed } from 'vue'

import { useItemStore } from '../stores/items';
import { useItemPagingStore } from '../stores/itemPaging';
import { useSettingsStore } from '../stores/settings';
import { useItemCartStore } from '../stores/itemcart';
import DialogItem from '../dialogs/DialogItem.vue';
import { cItem } from '../domain/item';
import DialogItemCart from '../dialogs/DialogItemCart.vue';
import ItemPanelRow from './itemPanelRow.vue';

const settings = useSettingsStore();
const itempage = useItemPagingStore();
const items = useItemStore();
const itemcart = useItemCartStore();

const showDialogItem = ref(false);
const showDialogItemCart = ref(false);
const selectedItem = ref(new cItem());


const numberedPages = computed(() => {
  return Array.from({ length: itempage.totalPages }, (_, i) => `${i + 1}`);
});

/**
 * Event: Page Change
 */
function pageSelect(pPage: number | string | null): void {
  if (pPage == null)
    pPage = 1;

  itempage.goToPage(Number(pPage));
}

function dialogItemShow(pItem: cItem) {
  selectedItem.value = pItem;
  showDialogItem.value = true;
  items.selectItem(pItem);
}

function dialogItemClosed() {
  showDialogItem.value = false;
}

function dialogItemCartShow() {

  showDialogItemCart.value = true;
}

function dialogItemCartClosed() {
  showDialogItemCart.value = false;
}

/**
 * Manage key presses
 */
function keypress(event: KeyboardEvent) {

  if (event.defaultPrevented) {
    return;
  }

  switch (event.key) {
    default:
      return;

    case "Left":
    case "ArrowLeft":
      itempage.goToPreviousPage();
      break;

    case "Right":
    case "ArrowRight":
      itempage.goToNextPage();
      break;
  }

  event.preventDefault();
}

onMounted(() => {
  showDialogItemCart.value = false;
  document.addEventListener("keydown", keypress, { capture: false });
});

onUnmounted(() => {
  document.removeEventListener("keydown", keypress, { capture: false })
});

</script>

<template>
  <v-container>
    <v-item-group :style="'min-height:' + `${settings.getAvailableHeight}px`">
      <ItemPanelRow :items="itempage.items" @item-click="dialogItemShow"></ItemPanelRow>
    </v-item-group>

    <v-row justify="center">

      <v-pagination class="mt-5 mt-sm-12 mt-md-8 w-75" :total-visible="9" :model-value="itempage.currentPage"
        :length="itempage.totalPages" @update:modelValue="pageSelect">
        <template v-slot:next="test">
          <v-row no-gutters>
            <v-select v-if="itempage.totalPages > 9" :items="numberedPages" density="comfortable"
              :value="itempage.currentPage" variant="solo" @update:modelValue="pageSelect"></v-select>
            <v-btn _as="VPaginationBtn" v-bind="test"></v-btn>
          </v-row>
        </template>
      </v-pagination>

    </v-row>

    <DialogItem v-if="showDialogItem == true" :item="items.getSelectedItem" :show="showDialogItem"
      @closed="dialogItemClosed"></DialogItem>

    <div v-if="itemcart.count > 0" class="itemcart-panel">

      <v-menu>
        <template v-slot:activator="{ props }">
          <v-btn icon="" v-bind="props">{{ itemcart.count }}</v-btn>
        </template>

        <v-tooltip text="Clear selected items">
          <template v-slot:activator="{ props }">
            <v-btn v-bind="props" icon="mdi-close-thick" class="mb-2" @click="itemcart.clear()"></v-btn>
          </template>
        </v-tooltip>

        <v-tooltip text="Build Slideshow">
          <template v-slot:activator="{ props }">
            <v-btn v-bind="props" icon="mdi-download" class="mb-2" @click="dialogItemCartShow()"></v-btn>
          </template>
        </v-tooltip>

      </v-menu>

      <DialogItemCart :visible="showDialogItemCart" @update:visible="dialogItemCartClosed()"></DialogItemCart>
    </div>

  </v-container>
</template>

<style scoped>
.itemcart-panel {
  position: absolute;
  right: 10px;
  bottom: 10px;
}
</style>