<script setup lang="ts">
import { ref, watch } from 'vue';
import { useDisplay } from 'vuetify/lib/framework.mjs';
import { ItemLocationData } from 'rundown-common';
import { cItem } from '../../../domain/item';
import { onMounted } from 'vue';

const props = defineProps({
    item: {
        type: cItem,
        required: true
    },
    canZoomImage: {
        type: Boolean,
        default: false,
        required: false
    }
});

const mapcenter = ref([0, 0] as mapboxgl.LngLatLike);
const mapshow = ref(true);
const placeName = ref('');
const visible = ref(false);

var mapBox = null as mapboxgl.Map | null;

onMounted(() => {
    mapCenterSet();
    visible.value = true;
})
watch(props, () => {
    itemLoad();
    mapCenterSet();
});

/**
 * Load the item
 */
async function itemLoad() {
    if (!props.item.isValid()) {
        return;
    }

    placeName.value = props.item.locations[0]?.placeName ?? '';
}

/**
 * Event: Update the location placename
 */
async function placeNameUpdate() {
    if (!props.item.isValid())
        return;

    props.item.placeNameUpdate(placeName.value);
}

/**
 * Event: Location updated
 */
async function locationUpdate(pNew: ItemLocationData) {
    if (!props.item.isValid())
        return;

    props.item.locationUpdate(pNew);

    if (pNew.longitude !== undefined) {
        mapcenter.value = [pNew.longitude, pNew.latitude];

        if (mapBox != null)
            mapBox.setCenter(mapcenter.value);
    }

}

/**
 * Event: Location selected from search
 */
async function locationSelect(pLocationEvent: object | null) {
    // pLocationEvent.place_name
    // pLocationEvent.geometry.coordinates
    // pLocationEvent.geometry.coordinates[0]: Long
    // pLocationEvent.geometry.coordinates[1]: Lat

    if (!props.item.isValid())
        return;

    var location = {} as ItemLocationData | any;

    if (pLocationEvent != null) {
        //debugger;
        location.placeName = pLocationEvent.place_name;
        location.longitude = pLocationEvent.geometry.coordinates[0];
        location.latitude = pLocationEvent.geometry.coordinates[1];
    } else {
        location.placeName = '';
    }

    locationUpdate(location);
}

/**
 * Event: Map Left Click
 */
function mapClick(pMapEvent: object) {
    //alert(a.lngLat);
    //debugger;
}

/**
 * Event: Map Right Click
 */
function mapContext(pMapEvent: object) {
}

/**
 * Event: Map Loaded
 */
async function mapLoaded(pMapBox: mapboxgl.Map) {
    if (!props.item.isValid())
        return;
    mapBox = pMapBox;

    mapCenterSet();
}

function mapCenterSet() {
    if (!props.item.isValid())
        return;

    if (props.item.locations.length) {
        if (props.item.locations[0].longitude !== undefined && props.item.locations[0].latitude !== undefined) {
            mapcenter.value = [props.item.locations[0].longitude, props.item.locations[0].latitude];

            if (mapBox != null) {
                setTimeout(() => {
                    mapBox.setCenter(mapcenter.value);
                }, 100);

            }
        }
    }
}
/**
 * Event: Map Marker coordinate update
 */
async function markerUpdateCoords(pLatLng: Array<number>) {
    if (!props.item.isValid())
        return;

    if (props.item.locations.length == 0) {
        props.item.locations.push({ 'id': 0, 'placeName': '' } as ItemLocationData);
    }

    props.item.locations[0].longitude = pLatLng[0];
    props.item.locations[0].latitude = pLatLng[1];

    locationUpdate(props.item.locations[0]);
}
</script>

<template>
    <v-row>
        <v-col>

            <mapbox-geocoder-control :accessToken="mapboxToken" :marker="false">
                <template v-slot:input="{ result, results }">
                    <v-text-field label="Address" class="mt-1 mb-n1" clearable v-model="placeName" v-on:blur="placeNameUpdate"
                        @click:clear="locationSelect(null)" variant="underlined" density="compact">

                        <template v-slot:prepend-inner>
                            <v-tooltip location="start" activator="parent">
                                <template #activator="{ isActive }">
                                    <v-icon @click="mapshow = !mapshow">mdi-map</v-icon>
                                </template>Open Map
                            </v-tooltip>
                        </template>

                        <v-menu activator="parent">
                            <v-list>
                                <v-list-item v-for="(location, index) in results.features" :key="index" :value="index"
                                    @click="locationSelect(location)">
                                    <v-list-item-title>{{ location.place_name }}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>

                    </v-text-field>
                </template>
            </mapbox-geocoder-control>
        </v-col>
    </v-row>

    <v-col  style="height: 90%">

        <mapbox-map v-if="visible" :accessToken="mapboxToken" mapStyle="dark-v10" :center="mapcenter" :zoom="16"
            @click="mapClick" @loaded="mapLoaded" @contextmenu="mapContext">
            <template v-for="result in (item.locations ?? [])">
                <template v-if="result.longitude !== undefined">
                    <mapbox-marker :lngLat="[result.longitude, result.latitude]" :draggable="true"
                        @update:lngLat="markerUpdateCoords">
                        <mapbox-popup>{{ result.placeName }}</mapbox-popup>
                    </mapbox-marker>
                </template>
            </template>
        </mapbox-map>

    </v-col>
</template>
    
<style scoped>
.mapboxgl-popup-content {
    background: #232124 !important;
}

.mapboxgl-popup-tip {
    border-top-color: #232124 !important;
}
</style>
