import { defineStore } from 'pinia'
import axios from "axios"
import { useMessageStore } from './messages';

export const useKeywordStore = defineStore("keywords", {
    state: () => ({
        all: [] as string[],
        keywords: [] as string[],
        categories: [] as string[]
    }),

    getters: {
        getAll(state) {
            return state.all;
        },
        getKeywords(state) {
            return state.keywords;
        },
        getCategories(state) {
            return state.categories;
        }
    },

    actions: {

        async loadAll() {
            this.all = await this.load('');
            this.keywords = await this.load('keywords');
            this.categories = await this.load('category');
        },

        async load(pType: string) {
            const messages = useMessageStore();
            try {
                let data = await axios.get('/v1/keystrings' + ((pType.length  == 0) ? '' : '/' + pType));
                return data.data;
            }
            catch (error) {
                messages.handleAxiosError(error);
            }
        },
    }
});
