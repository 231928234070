import { watch } from 'vue';
import { defineStore } from 'pinia';
import { useAuthStore } from './auth';
import { useItemStore } from './items';
import { collectionAll, useCollectionStore } from './collections';
import { useKeywordStore } from './keywords';

import { useFilterStore } from './filters';
import { usePeopleStore } from './people';
import { usePluginStore } from './plugins';
import { useSettingsStore } from './settings';
import { useObjectsStore } from './objects';
import { ActionMessageInterface, CollectionData } from 'rundown-common';
import { PublicationContext } from 'centrifuge';
import { useWebSocketStore } from './websocket';
import { useMessageStore } from './messages';

export const useLoaderStore = defineStore('loader', () => {

    function initialize() {
        load();
    }

    // Watch for auth changes
    watch(() => useAuthStore().isAuthed, async function () {
        load();
    });

    // Watch for filter changes
    watch(useFilterStore().$state, async function () {
        // Wait until height is set
        if (useSettingsStore().getAvailableHeight == 0)
            return;

        useItemStore().load();
    });

    /**
     * Attach to each object channel on the websocket
     */
    const websocketAttach = async (pCollection: CollectionData) => {
        const websocket = useWebSocketStore();
        await websocket.joinChannel('collection-' + pCollection.id, useCollectionStore().websocketMessage);
        await websocket.joinChannel('object-' + pCollection.id, useObjectsStore().websocketMessage);
        await websocket.joinChannel('person-' + pCollection.id, usePeopleStore().websocketMessage);
        await websocket.joinChannel('plugins', usePluginStore().websocketMessage);
    }

    async function load() {
        const pluginStore = usePluginStore();
        const collectionStore = useCollectionStore();
        const peopleStore = usePeopleStore();
        const keywordStore = useKeywordStore();
        const objectsStore = useObjectsStore();
        const itemStore = useItemStore();

        collectionStore.setSelected(collectionAll);

        await Promise.all([
            pluginStore.load(),
            collectionStore.load(),
            peopleStore.load(),
            keywordStore.loadAll(),
            objectsStore.loadAll(),
            itemStore.load()
        ]);

        // Process collections after all asynchronous operations are complete
        collectionStore.collections.forEach((pCollection: CollectionData) => {
            websocketAttach(pCollection);
        });
    }

    initialize();

    return { load, websocketAttach }
});